import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import _ from 'lodash';
import GrdnApi from 'lib/grdn';
import { VisitorsPayload, ResponseEnvelope, isSuccessResponse } from 'types/grdn';
import { WorkStatusEnum } from 'types/db/members';
import { Visitor } from 'types/db/visitors';
import { DownloadCloud } from 'components/design-system/Icons';
import { BodySmall } from 'components/design-system/Text';
import textStyles from 'components/design-system/Text.module.css';
import { CoreColors } from 'types/colors';
import { captureException } from 'lib/sentry';
import { formatTime } from 'lib/time';
import { rowCompare } from 'lib/sort';

const headers = [
  'F Name',
  'L Name',
  'Visitor Email',
  'Onsite Status',
  'Update Time',
  'Contact',
  'Contact Email',
];

interface CsvRow {
  'F Name': string;
  'L Name': string;
  'Visitor Email': string;
  'Onsite Status': WorkStatusEnum;
  'Update Time': string;
  Contact: string;
  'Contact Email': string;
}

const toCsvRow = (row: Visitor): CsvRow => ({
  'F Name': row.firstName,
  'L Name': row.lastName,
  'Visitor Email': row.email,
  'Onsite Status': row.triageStatus,
  'Update Time': formatTime(row.lastTriageAt),
  Contact: row.contactName,
  'Contact Email': row.contactEmail,
});

interface VisitorsCsvLinkProps {
  status: string;
  day: string;
}

interface CSVParams {
  'triage-status'?: string;
  day: string;
}

export const VisitorsCsvLink: React.FC<VisitorsCsvLinkProps> = ({ day, status }) => {
  const [csvData, setCsvData] = useState<CsvRow[]>([]);
  const csvInstance = useRef<any | null>(null);
  const params: CSVParams = { day };
  if (status) {
    params['triage-status'] = status;
  }

  const fetchData = async () => {
    GrdnApi.getVisitors(params)
      .then((response) => {
        parseResponse(response);
        return;
      })
      .catch(captureException);
  };

  useEffect(() => {
    if (csvData && csvInstance?.current?.link) {
      // XXX: this timeout appears redundant, but removing it will break CSV downloads: instead of
      // a csv, the download contains a react html page. ¯\_(ツ)_/¯
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData([]);
      });
    }
  }, [csvData]);

  const parseResponse = async (
    response: ResponseEnvelope<VisitorsPayload | null>,
  ): Promise<void> => {
    if (isSuccessResponse(response)) {
      const csvData = response.data;
      if (csvData) {
        setCsvData(csvData.sort(rowCompare).map(toCsvRow));
      }
    }
  };

  return (
    <>
      <div
        onClick={fetchData}
        style={{
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          whiteSpace: 'nowrap',
        }}
      >
        <DownloadCloud color={CoreColors.Eden} width={20} height={20} />
        <BodySmall
          className={textStyles.semibold}
          style={{
            color: CoreColors.Eden,
            marginLeft: 8,
            float: 'right',
          }}
        >
          Export as CSV
        </BodySmall>
      </div>
      {csvData.length > 0 ? (
        <CSVLink
          data={csvData}
          headers={_.uniq([...headers, ..._.flatMap(csvData, (row) => _.keys(row))])}
          filename={`workstatus-${status || 'all'}-${day}.csv`}
          ref={csvInstance}
        />
      ) : undefined}
    </>
  );
};
