/* istanbul ignore file */

import * as Sentry from '@sentry/browser';
import { Event, EventHint } from '@sentry/types';
import _ from 'lodash';
import { Middleware } from 'redux';
import { loadEnv } from 'lib/env';
import { isAPIError } from 'lib/errors';
import { getRelease } from 'lib/version';

let sentryInitialized = false;

export type SentryEnv = 'Production' | 'Staging' | 'Development';

export const getSentryEnvironment = (): SentryEnv | null => {
  if (loadEnv('REACT_APP_SENTRY_ENVIRONMENT')) {
    return loadEnv('REACT_APP_SENTRY_ENVIRONMENT') as SentryEnv;
  }

  return null;
};

/* TODO: Implement me with auth */
export const scopeUser = (profile: Record<string, any>): void => {
  Sentry.configureScope((scope) => {
    const { email, name, username } = profile;
    scope.setUser({ email, name, username });
  });
};

export const setupSentry = (sentryEnv: SentryEnv): typeof Sentry => {
  const sentryConf = {
    dsn: loadEnv('REACT_APP_SENTRY_DSN'),
    release: getRelease(),
    normalizeDepth: 10,
  };

  Sentry.init(sentryConf);
  sentryInitialized = true;
  Sentry.configureScope((scope) => {
    scope.setTag('environment', sentryEnv);
    scope.addEventProcessor(downgradeNetworkFailures);
    scope.addEventProcessor(fingerprintGRDN);
  });
  return Sentry;
};

const fingerprintGRDN = (event: Event, hint: EventHint | undefined) => {
  if (hint?.originalException && isAPIError(hint.originalException)) {
    event.fingerprint = hint.originalException.fingerprint;
    event.extra = { ...event.extra, ...hint.originalException.errorContext };
  }
  return event;
};

const downgradeNetworkFailures = (event: Event): Event => {
  const exceptions = event?.exception?.values;
  if (_.isEmpty(exceptions)) {
    return event;
  }

  // Downgrade "Network request failed" errors to warn.
  const isNetworkError = _.some(exceptions, (e) => {
    const errorMessage = e.value;
    return errorMessage && errorMessage.match(/^TypeError: Network request failed/);
  });
  if (isNetworkError) {
    event.level = Sentry.Severity.Warning;
  }

  return event;
};

export const captureException = (error: Error, extra?: Record<string, any>): void => {
  // wrapper for Sentry.CaptureException to add extra context to the error
  if (!sentryInitialized && process.env.NODE_ENV !== 'test') {
    return;
  }

  if (extra) {
    Sentry.withScope((scope) => {
      scope.setExtras(extra);
      Sentry.captureException(error);
    });
  } else {
    Sentry.captureException(error);
  }
};

export const sentryMiddleware: Middleware = () => (next) => (action) => {
  // custom Redux Middleware to log set breadcrumbs for actions and capture errors in an action
  const breadcrumb: {
    message: string;
    category: string;
    data?: Record<string, any>;
  } = {
    message: action.type,
    category: 'redux-action',
  };
  const { payload } = action;
  if (payload) {
    breadcrumb.data = { payload };
  }
  Sentry.addBreadcrumb(breadcrumb);
  try {
    return next(action);
  } catch (e) {
    captureException(e, { extra: action });
  }
};
