import camelize from 'camelize';
import { FormState } from '../types/screener';
import Api, { HttpMethod } from 'lib/api';
import {
  DocumentResponsePayload,
  DocumentType,
  EmployeesPayload,
  MemberPayload,
  MembersPayload,
  ResponseEnvelope,
  SuccessResponse,
  TestingResultsPayload,
  UserPayload,
  ValidateEmailsPayload,
  VisitorsPayload,
  VisitorsScreenerPayload,
  VisitorsScreenerResponsePayload,
} from 'types/grdn';
import { clientError, serverError } from 'lib/errors';
import { loadEnv } from 'lib/env';
import { Member } from 'types/db/members';
import { User } from 'types/db/user';

const api = new Api(loadEnv('REACT_APP_SERVER_URL'));

export const parseResponse = async <T>(
  response: Response,
  [endpoint, method]: [string, HttpMethod],
): Promise<ResponseEnvelope<T>> => {
  // 204/304 responses have no body
  if ([204, 304].includes(response.status)) {
    return { status: response.status, data: Object() };
  } else if (response.status >= 400 && response.status < 500) {
    throw await clientError(response, method, endpoint);
  } else if (response.status >= 500) {
    throw await serverError(response, method, endpoint);
  } else {
    const body = await response.json();
    return { ...(camelize(body) as SuccessResponse<T>), status: response.status };
  }
};

export default class GrdnApi {
  static async getMembers(
    params: Record<string, any>,
  ): Promise<ResponseEnvelope<MembersPayload | null>> {
    const response = await api.get(`v2/member?${api.objectToQuery(params)}`);
    return parseResponse<MembersPayload | null>(response, ['v2/members', HttpMethod.GET]);
  }

  static async getVisitors(
    params: Record<string, any>,
  ): Promise<ResponseEnvelope<VisitorsPayload | null>> {
    const response = await api.get(`v2/visitor?${api.objectToQuery(params)}`);
    return parseResponse<VisitorsPayload | null>(response, ['v2/visitor', HttpMethod.GET]);
  }

  static async getMember(
    id: string,
    params: Record<string, any>,
  ): Promise<ResponseEnvelope<Omit<Member, 'testingResults'> | null>> {
    const response = await api.get(`v2/member/${id}?${api.objectToQuery(params)}`);
    return parseResponse<MemberPayload | null>(response, ['v2/members/:id', HttpMethod.GET]);
  }

  static async getUser(): Promise<ResponseEnvelope<User | null>> {
    const response = await api.get(`v2/sponsor-user`);
    return parseResponse<UserPayload | null>(response, ['v2/sponsor-user', HttpMethod.GET]);
  }

  static async getUnconsented(): Promise<ResponseEnvelope<EmployeesPayload | null>> {
    const response = await api.get(`v2/unconsented`);
    return parseResponse<EmployeesPayload | null>(response, ['v2/unconsented', HttpMethod.GET]);
  }

  static async postVisitorScreenerResponse(
    linkId: string,
    formState: FormState,
  ): Promise<ResponseEnvelope<any | null>> {
    const response = await api.post(`v2/visitor/screener-response`, { linkId, ...formState });
    return parseResponse<VisitorsScreenerResponsePayload | null>(response, [
      'v2/visitor/screener-response',
      HttpMethod.POST,
    ]);
  }

  static async getVisitorScreener(
    employerCode: string,
  ): Promise<ResponseEnvelope<VisitorsScreenerPayload | null>> {
    const response = await api.get(`v2/visitor/screener?link-id=${employerCode}`);
    return parseResponse<VisitorsScreenerPayload | null>(response, [
      'v2/visitor/screener',
      HttpMethod.GET,
    ]);
  }

  static async getTestingResults(
    id: string,
  ): Promise<ResponseEnvelope<TestingResultsPayload | null>> {
    const response = await api.get(`v2/member/${id}/covid-testing`);
    return parseResponse<TestingResultsPayload | null>(response, [
      'v2/members/:id/covid-testing',
      HttpMethod.GET,
    ]);
  }

  static async validateEmails(emails: string[]): Promise<ResponseEnvelope<ValidateEmailsPayload>> {
    const response = await api.post('v2/validate-emails', { emails });
    return parseResponse<ValidateEmailsPayload>(response, ['v2/validate-emails', HttpMethod.POST]);
  }

  static async resendWelcomeEmail(email: string): Promise<ResponseEnvelope<null>> {
    const response = await api.post(`v2/resend-welcome-email`, { email });
    return parseResponse(response, [`v2/resend-welcome-email`, HttpMethod.POST]);
  }

  static async downloadDocument(
    memberId: string,
    documentType: DocumentType,
    documentId: string | number,
  ): Promise<ResponseEnvelope<DocumentResponsePayload>> {
    const response = await api.get(`v2/member/${memberId}/document/${documentType}/${documentId}`);

    return await parseResponse(response, [
      'v2/member/:id/document/vaccine/:vaccine-id',
      HttpMethod.GET,
    ]);
  }
}
