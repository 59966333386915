import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  IdType,
  Row,
  TableInstance,
  TableOptions,
  useAsyncDebounce,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  UseGlobalFiltersInstanceProps,
  UseGlobalFiltersState,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import cx from 'classnames';
import css from './Table.module.css';
import { KickerHeader, ListBodySmall } from './Text';
import { ChevronLeft, ChevronRight, Magnifier, SortDown2, SortUp2 } from './Icons';
import { Field, Option, Select, SelectProps } from './Form';
import textStyles from 'components/design-system/Text.module.css';
import { CoreColors } from 'types/colors';

interface EdenTableProps<T extends Record<string, any>> {
  tableInstance: TableInstance<T>;
  className?: string;
  dataName?: string;
  paginate?:
    | {
        pageSizes?: number[];
      }
    | false;
  // Scrollable will create a scrollable bar if the table is in a fixed height parent
  scrollable?: boolean;
  onRowClick?: (row: Row<T>) => void;
}

const validNumRegex = /^[1-9]+[0-9]*$/;
const validatePageIndexText = (text: string) => !!text.match(validNumRegex);

export function EdenTable<T extends Record<string, any>>({
  tableInstance,
  paginate = {},
  className,
  dataName,
  scrollable,
  onRowClick,
}: EdenTableProps<T>): ReturnType<React.FC<EdenTableProps<T>>> {
  const {
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    pageOptions,
    state: { pageSize, sortBy, pageIndex },
    rows,
    setPageSize,
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
  } = tableInstance;

  const isFilterDesc = sortBy[0]?.desc;
  const filterName = sortBy[0]?.id;
  const [{ pageIndexText }, setPageIndexObj] = useState<{
    pageIndexText: string;
    originalIndexText: string;
  }>({ pageIndexText: '1', originalIndexText: '1' });

  useEffect(() => {
    if (validatePageIndexText(pageIndexText)) {
      gotoPage(parseInt(pageIndexText) - 1);
    }
  }, [gotoPage, pageIndexText]);

  useEffect(() => {
    setPageIndexObj(() => ({
      pageIndexText: '1',
      originalIndexText: '1',
    }));
  }, [pageOptions]);

  // Total number of records
  const dataMax = rows.length;
  // Lower end of current records being displayed
  const dataLowRange = rows.length > 0 ? pageIndex * pageSize + 1 : 0;
  // Higher end of current records being displayed
  const dataHighRange =
    pageIndex + 1 === pageOptions.length || rows.length < pageSize
      ? rows.length
      : (pageIndex + 1) * pageSize;

  return (
    <div className={cx(scrollable && css.scrollableTableContainer, css.tableOuter)}>
      <div className={cx(scrollable && css.scrollableTable, css.table, className)}>
        <div className={cx(css.thead, css.headers)}>
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} key="headers">
              {headerGroup.headers.map((column) => {
                const isFilter = filterName === column.id;
                return (
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                    key={'header-' + column.id}
                    className={cx(
                      css[column.id],
                      scrollable && css.scrollableHeader,
                      'edenTableCell',
                    )}
                  >
                    <div className={cx(css.headerCell, isFilter && css.activeFilter)}>
                      <KickerHeader className={textStyles.bold} style={{ paddingRight: 6 }}>
                        {column.render('Header')}
                      </KickerHeader>
                      {isFilter && !isFilterDesc ? (
                        <SortUp2
                          color={isFilter ? CoreColors.Eden : CoreColors.Slate45}
                          height={12}
                          width={12}
                        />
                      ) : (
                        !column.disableSortBy && (
                          <SortDown2
                            color={isFilter ? CoreColors.Eden : CoreColors.Slate45}
                            height={12}
                            width={12}
                          />
                        )
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div {...getTableProps()}>
          <div className={cx(scrollable && css.scrollableTableBody, css.tbody)}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <div
                  {...row.getRowProps()}
                  key={`row-${row.index}`}
                  className={cx(
                    css.row,
                    css.tr,
                    {
                      [css.trClickable]: !!onRowClick,
                    },
                    'edenTableHoverable',
                  )}
                  onClick={() => onRowClick && onRowClick(row)}
                >
                  {row.cells.map((cell) => {
                    return (
                      <div
                        {...cell.getCellProps([
                          {
                            className: cx(css.td, css[cell.column.id], 'edenTableCell'),
                          },
                        ])}
                        key={`cell-${i}-${cell.column.id}`}
                      >
                        <ListBodySmall>{cell.render('Cell')}</ListBodySmall>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {paginate && (
        <div className={cx(scrollable && css.scrollableFooter, css.footer)}>
          <div className={css.leftFooter}>
            {paginate.pageSizes && (
              <div className={css.innerLeftFooter}>
                <Select
                  value={pageSize.toString()}
                  change={(value: string) => {
                    setPageSize(parseInt(value));
                    setPageIndexObj(() => ({
                      pageIndexText: '1',
                      originalIndexText: '1',
                    }));
                  }}
                  openUp
                  style={{ width: 110 }}
                  values={paginate.pageSizes.map((i) => (
                    <Option style={{ width: 160 }} key={`option-${i}`} value={`${i}`}>
                      Show {i}
                    </Option>
                  ))}
                />
              </div>
            )}
            <div className={css.nowrap}>
              {dataLowRange} - {dataHighRange} of {dataMax} {dataName}
            </div>
          </div>
          {rows.length !== 0 && (
            <div className={css.rightFooter}>
              <div style={{ paddingRight: 16 }} className={css.innerRightFooter}>
                <div className={css.nowrap}>Go to page</div>
                <div style={{ marginLeft: 8, marginRight: 8 }}>
                  <Field
                    className={css.pageField}
                    value={pageIndexText}
                    onBlur={() => {
                      setPageIndexObj(({ pageIndexText, originalIndexText }) => {
                        const validPageText = validatePageIndexText(pageIndexText);
                        const underMax =
                          validPageText && parseInt(pageIndexText) - 1 < pageOptions.length;
                        let newValue;
                        if (validPageText && underMax) {
                          newValue = pageIndexText;
                        } else if (validPageText && !underMax) {
                          newValue = pageOptions.length.toString();
                        } else {
                          newValue = originalIndexText;
                        }
                        return {
                          pageIndexText: newValue,
                          originalIndexText: newValue,
                        };
                      });
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const newPage = e.target.value;
                      if (!!newPage && !newPage.match(validNumRegex)) return;
                      setPageIndexObj((state) => ({
                        ...state,
                        pageIndexText: newPage,
                      }));
                    }}
                  />
                </div>
                <div className={css.nowrap}>of {pageOptions.length}</div>
              </div>
              <div
                style={{ marginRight: 8 }}
                className={cx(!canPreviousPage && css.disabled, css.simpleButton)}
                onClick={() => {
                  if (canPreviousPage) {
                    previousPage();
                    setPageIndexObj((state) => ({
                      ...state,
                      pageIndexText: pageIndex.toString(),
                    }));
                  }
                }}
              >
                <ChevronLeft
                  color={canPreviousPage ? CoreColors.SlateDarken20 : CoreColors.Slate55}
                  width={16}
                  height={16}
                />
              </div>
              <div
                className={cx(!canNextPage && css.disabled, css.simpleButton)}
                onClick={() => {
                  if (canNextPage) {
                    nextPage();
                    setPageIndexObj((state) => ({
                      ...state,
                      pageIndexText: (pageIndex + 2).toString(),
                    }));
                  }
                }}
              >
                <ChevronRight
                  color={canNextPage ? CoreColors.SlateDarken20 : CoreColors.Slate55}
                  width={16}
                  height={16}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export function useEdenTable<D extends Record<string, any>>(
  options: TableOptions<D>,
): TableInstance<D> {
  const [filter, setFilter] = useState<{ id: string | undefined; desc: boolean | undefined }>({
    id: options?.initialState?.sortBy && options.initialState.sortBy[0].id,
    desc: true,
  });
  const [pageSize, setPageSize] = useState(options?.initialState?.pageSize || 0);
  const [pageIndex, setPageIndex] = useState(options?.initialState?.pageSize || 25);
  const tableInstance = useTable<D>(
    {
      ...options,
      initialState: {
        ...options.initialState,
        pageSize,
        sortBy: filter.id ? [{ id: filter.id, desc: filter.desc }] : [],
        pageIndex,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useFlexLayout,
  );
  const { id, desc } = tableInstance.state.sortBy[0] || { id: undefined, desc: undefined };
  const currentPageIndex = tableInstance.state.pageIndex;
  const currentPageSize = tableInstance.state.pageSize;

  // These useEffects are used to make sure we remember our state
  // even when the data to the table changes. For some reason, only
  // manual filters seem to be remembered
  useEffect(() => {
    setFilter({ id, desc });
  }, [id, desc]);

  useEffect(() => {
    setPageIndex(currentPageIndex);
  }, [currentPageIndex]);

  useEffect(() => {
    setPageSize(currentPageSize);
  }, [currentPageSize]);

  return tableInstance;
}

interface FilterOnlyProps<T extends Record<string, any>> {
  tableInstance: TableInstance<T>;
  filterName: IdType<T>;
}

export type FilterProps<T extends Record<string, any>> = SelectProps & FilterOnlyProps<T>;

export function Filter<T extends Record<string, any>>({
  // This filter component behaves just like a regular Select component,
  // however it also takes a tableInstance, and makes sure to sync any
  // changes to the value of the selected component to the table filter
  // This needed because the table function resets itself on change
  tableInstance,
  filterName,
  value,
  ...props
}: FilterProps<T>): ReturnType<React.FC<FilterProps<T>>> {
  const tableFilterState = tableInstance.state.filters.find(
    (filter) => filter.id === filterName,
  )?.value;
  useEffect(() => {
    // react-table forces a filter reset, when we change dates, even
    // if we call a setStatus after setting the date. This effect ensures
    // that the table state accurately reflects our application state
    if ((value && value !== tableFilterState) || (!value && tableFilterState)) {
      tableInstance.setFilter(filterName, value);
    }
  }, [value, tableFilterState, filterName, tableInstance]);
  return <Select {...props} value={value} />;
}

interface GlobalFilterProps<T extends Record<string, any>> {
  tableInstance: UseGlobalFiltersInstanceProps<T> & { state: UseGlobalFiltersState<T> };
}

export function NameFilter<T extends Record<string, any>>({
  tableInstance,
}: GlobalFilterProps<T>): ReturnType<React.FC<GlobalFilterProps<T>>> {
  const { setGlobalFilter } = tableInstance;

  const onChange = useAsyncDebounce((value: string) => {
    if (value.length >= 2) {
      setGlobalFilter(value);
    } else {
      setGlobalFilter(undefined);
    }
  }, 150);

  return (
    <Field
      icon={Magnifier}
      placeholder="Search by name"
      onChange={(e) => {
        onChange(e.currentTarget.value);
      }}
    />
  );
}
