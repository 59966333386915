export enum CoreColors {
  Apple = '#6ed69a',
  Apple10 = '#f0fbf5',
  Apple20 = '#e2f7eb',
  Apple40 = '#c5efd7',
  Apple60 = '#a8e6c2',
  Apple80 = '#8bdeae',
  Eden = '#14B87C',
  EdenDarken10 = '#12A670',
  EdenDarken20 = '#008051',
  Slate = '#3A545B',
  Slate05 = '#f5f6f7',
  Slate15 = '#e1e5e6',
  Slate25 = '#ced4d6',
  Slate35 = '#bac3c6',
  Slate45 = '#a6b2b5',
  Slate55 = '#93A1A5',
  Slate85 = '#586E74',
  SlateDarken20 = '#2E4349',
  White = '#FFFFFF',
}

export enum AccentColors {
  Lemon = '#C08A00',
  Lemon10 = '#FFFAE9',
  Lemon60 = '#FFE07C',
  Lemon80 = '#FFD651',
  Ocean = '#0391f4',
  Ocean60 = '#68bdf8',
  OceanDarken20 = '#0753C7',
  Razz = '#F8506F',
}

export type Colors = CoreColors | AccentColors;

export interface ColorProps {
  color?: Colors;
}
