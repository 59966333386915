import { toast } from 'react-toastify';
import cx from 'classnames';
import React, { ReactNode } from 'react';
import { AccentColors, CoreColors } from '../../types/colors';
import css from './Toast.module.css';
import { AlertCircle, Check } from './Icons';

export const success = (message: string | ReactNode): void => {
  const WrappedMessage = (
    <div className={css.iconWrapper}>
      <Check color={CoreColors.Apple} width={16} height={16} />
      {message}
    </div>
  );

  toast(WrappedMessage, {
    className: cx(css.generic, css.success),
    closeButton: false,
  });
};

export const failure = (message: string | ReactNode): void => {
  const WrappedMessage = (
    <div className={css.iconWrapper}>
      <AlertCircle color={AccentColors.Razz} width={16} height={16} />
      {message}
    </div>
  );
  toast(WrappedMessage, { className: cx(css.generic, css.failure) });
};
