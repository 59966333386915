/// <reference path="./types/index.d.ts"/>
import React from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';
import ReactModal from 'react-modal';

import RootNode from './rootNode';
import styles from 'styles.module.css';
import css from 'index.module.css';

ReactDOM.render(
  <React.StrictMode>
    <div className={cx(css.root, styles.root)}>
      <RootNode />
    </div>
  </React.StrictMode>,
  document.getElementById('root'),
);

ReactModal.setAppElement('#root');
