import React from 'react';
import { Redirect } from 'react-router-dom';
import { useQuery } from 'lib/hooks';
import { NewPassword, NewPasswordReason } from 'components/features/ResetPassword/NewPassword';
import { EdenHorizontalLogo } from 'components/design-system/Icons';

export const ResetForgottenPassword: React.FC = () => {
  const query = useQuery();
  const email = query.get('email');
  const code = query.get('code');
  if (!email || !code) return <Redirect to={{ pathname: '/' }} />;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{ marginTop: 56, marginBottom: 32 }}>
        <EdenHorizontalLogo />
      </div>
      <div>
        <NewPassword reason={NewPasswordReason.Forgot} code={code} email={email} />
      </div>
    </div>
  );
};
