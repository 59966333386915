import React, { Dispatch, HTMLProps, SetStateAction, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Column } from 'react-table';
import css from './VisitorList.module.css';
import { VisitorDashboardState } from './VisitorDashboard';
import { VisitorsCsvLink } from './VisitorsCsvLink';
import { useAppDispatch } from 'store';
import { EdenTable, Filter, useEdenTable } from 'components/design-system/Table';
import { WorkStatusEnum } from 'types/db/members';
import { BodySmall, HeaderMedium, HeaderSmall } from 'components/design-system/Text';
import { EdenDayPicker, Field, Option } from 'components/design-system/Form';
import { StatusCell, StatusIndicator, TimeCell } from 'components/design-system/Cells';
import { Calendar, Flag } from 'components/design-system/Icons';
import { isoDate, calendarDate, timezoneAbbr, isDateToday } from 'lib/time';
import { Spinner } from 'components/design-system/Spinner';
import textStyles from 'components/design-system/Text.module.css';
import { Visitor } from 'types/db/visitors';
import { fetchVisitors, visitorSelectors } from 'redux/visitor';
import { ButtonDropdown, DropdownDivider } from 'components/design-system/Dropdown';
import { selectVisitorCode } from 'redux/user';
import { AccentColors } from 'types/colors';
import * as toast from 'components/design-system/Toast';
import { captureException } from 'lib/sentry';

interface VisitorListProps {
  state: VisitorDashboardState;
  setState: Dispatch<SetStateAction<VisitorDashboardState>>;
}

export const VisitorList: React.FC<VisitorListProps> = ({
  state: { filters, loading },
  setState,
}) => {
  const data = useSelector(visitorSelectors.selectAll);
  const visitorCode = useSelector(selectVisitorCode);

  const screenerLink = `${window.location.origin}/${visitorCode}/screeners`;
  const columns = useMemo<Column<Visitor>[]>(
    () => [
      {
        Header: 'f name',
        accessor: 'firstName',
        minWidth: 120,
        width: 120,
        maxWidth: 1000,
      },
      { Header: 'l name', accessor: 'lastName', minWidth: 120, width: 120, maxWidth: 1000 },
      { Header: 'visitor email', accessor: 'email', minWidth: 160, width: 260, maxWidth: 1000 },
      {
        Header: 'onsite status',
        accessor: 'triageStatus',
        Cell: StatusCell,
        minWidth: 130,
        width: 130,
        maxWidth: 1000,
      },
      {
        Header: `submitted at (${timezoneAbbr()})`,
        accessor: 'lastTriageAt',
        Cell: TimeCell,
        minWidth: 160,
        width: 160,
        maxWidth: 1000,
      },
      { Header: 'contact', accessor: 'contactName', minWidth: 120, width: 160, maxWidth: 1000 },
      {
        Header: 'contact email',
        accessor: 'contactEmail',
        minWidth: 160,
        width: 260,
        maxWidth: 1000,
      },
    ],
    [],
  );

  const options = {
    data,
    columns,
    defaultColumn: {},
    initialState: {
      manualFilters: {
        triageStatus: '',
      },
      pageIndex: 0,
      pageSize: 25,
      sortBy: [
        {
          id: 'lastTriageAt',
          desc: true,
        },
      ],
    },
  };
  const tableInstance = useEdenTable<Visitor>(options);

  const dispatch = useAppDispatch();
  const handleDayChange = (day: Date | undefined) => {
    const action = async () => {
      const newDay: Date = day ?? new Date();
      const formattedDay: string = isoDate(newDay);
      setState((state) => {
        return { ...state, filters: { ...state.filters, day: newDay } };
      });
      const fetchVisitorsResponse = await dispatch(fetchVisitors({ day: formattedDay }));
      if (fetchVisitors.rejected.match(fetchVisitorsResponse))
        throw fetchVisitorsResponse.payload ?? 'Unknown fetchVisitors error';
    };
    action().catch(captureException);
  };

  const TriageFilter = ({ ...props }: HTMLProps<HTMLDivElement>) => (
    <Filter
      {...props}
      tableInstance={tableInstance}
      filterName="triageStatus"
      value={filters.status}
      values={[
        <Option value={WorkStatusEnum.Isolate} key={WorkStatusEnum.Isolate}>
          <StatusIndicator value={WorkStatusEnum.Isolate} />
        </Option>,
        <Option value={WorkStatusEnum.Cleared} key={WorkStatusEnum.Cleared}>
          <StatusIndicator value={WorkStatusEnum.Cleared} />
        </Option>,
        <Option key="" value="">
          Any Work Status
        </Option>,
      ]}
      change={(value: string) => {
        setState((state) => {
          return {
            ...state,
            filters: { ...filters, status: value },
          };
        });
      }}
    />
  );

  const copyToClipboard = (text: string) => {
    /*
     * dummy div element solution form:
     * https://stackoverflow.com/questions/52923771/react-copy-component-state-value-to-clipboard-without-dummy-element
     */
    const dummyInput = document.createElement('input');
    dummyInput.setAttribute('value', text);
    document.body.appendChild(dummyInput);
    dummyInput.select();
    document.execCommand('copy');
    document.body.removeChild(dummyInput);
  };

  const ShareScreenerDropdown = () => {
    return (
      <ButtonDropdown label="Share Screener">
        <div className={css.screenerLinkModalContainer}>
          <HeaderSmall style={{ marginBottom: 8 }}>
            Send the COVID-19 Screener to Visitors
          </HeaderSmall>
          <BodySmall style={{ marginBottom: 24 }}>
            Copy your company screener link and send it to expected visitors.
          </BodySmall>
          <div className={css.screenerLinkSection}>
            <Field value={screenerLink} disabled />
            <button
              className={css.screenerLinkCopyButton}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                copyToClipboard(screenerLink);
                e.stopPropagation();
                toast.success('Copied to Clipboard');
              }}
            >
              Copy
            </button>
          </div>
          <DropdownDivider />
          <div className={css.screenerLinkInfo}>
            <div className={css.screenerLinkInfoIcon}>
              <Flag width={24} height={24} color={AccentColors.Ocean} />
            </div>
            <BodySmall style={{ marginLeft: 8 }}>
              Once a visitor completes the screener, their results will appear in the current day’s
              Visitor Screening table.
            </BodySmall>
          </div>
        </div>
      </ButtonDropdown>
    );
  };

  return loading ? (
    <div className={css.loader} data-test-id="member-list-loading">
      <HeaderMedium style={{ paddingBottom: 32 }}>Loading Dashboard</HeaderMedium>
      <Spinner />
    </div>
  ) : (
    <div className={css.container}>
      <div
        className={css.header}
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <HeaderSmall className={textStyles.semibold} style={{ flex: 1 }}>
          Visitors
        </HeaderSmall>
        <ShareScreenerDropdown />
      </div>
      <div className={css.subheader}>
        <div className={css.leftSubHeader}>
          <Calendar color="ocean" date={filters.day} style={{ marginRight: 24 }} />
          <div className={css.leftSubHeader} style={{ marginRight: 12 }}>
            <HeaderMedium>Daily Visitor Screening</HeaderMedium>
          </div>
        </div>
        <div className={css.pickers}>
          <div style={{ paddingTop: 10, marginRight: 26 }}>
            <VisitorsCsvLink status={filters.status} day={isoDate(filters.day)} />
          </div>
          <EdenDayPicker
            style={{ marginRight: 8, width: isDateToday(filters.day) ? 142 : 90 }}
            onDayChange={handleDayChange}
            formatDate={(date) => {
              const formatted = calendarDate(date.toISOString());
              return isDateToday(date) ? `Today • ${formatted}` : formatted;
            }}
            value={filters.day}
          />
          <TriageFilter style={{ width: 152 }} />
        </div>
      </div>
      <div className={css.tableWrapper}>
        <EdenTable<Visitor>
          tableInstance={tableInstance}
          className={css.table}
          paginate={{
            pageSizes: [25, 50, 100],
          }}
          dataName="employees"
          scrollable
        />
      </div>
    </div>
  );
};
