import React, { Component } from 'react';
import { captureException } from 'lib/sentry';

interface SentryBoundaryProps {
  children: React.ReactNode;
}

class SentryBoundary extends Component<SentryBoundaryProps> {
  componentDidCatch(error: Error, errorInfo: Record<string, any>): void {
    captureException(error, errorInfo);
  }
  render(): React.ReactNode {
    // Always just pass through the `children` prop; in the future, we should
    // branch on whether an error has been thrown and display an alert, or even
    // prompt the user for feedback with `Sentry.showReportDialog()}`.
    return this.props.children;
  }
}

export default SentryBoundary;
