import Tippy, { TippyProps } from '@tippyjs/react';
import React, { MouseEvent, useState } from 'react';
import 'tippy.js/dist/tippy.css';
import css from './Tooltip.module.css';

const Tooltip = (props: TippyProps): ReturnType<React.FC> => {
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  const handleIconClick = (e: MouseEvent) => {
    isOpen ? close() : open();
    e.stopPropagation();
  };

  return (
    <Tippy interactive visible={isOpen} onClickOutside={close} placement="right" {...props}>
      <button className={css.tooltipButton} onClick={handleIconClick}>
        {props.children}
      </button>
    </Tippy>
  );
};

export default Tooltip;
