import React, { useState } from 'react';
import _ from 'lodash';
import css from './OptionsModal.module.css';
import { LinkWithIcon, ListItem, PrimaryButton } from 'components/design-system/Form';
import { QuestionOption } from 'types/screener';
import { Modal } from 'components/design-system/Modal';
import { BodyNormal, MobileBody } from 'components/design-system/Text';
import { XIcon } from 'components/design-system/Icons';
import { CoreColors } from 'types/colors';

interface OptionsModalProps {
  possibleAnswers: QuestionOption[];
  value?: number[];
  onChange: (answers: number[]) => void;
}

export const OptionsModal: React.FC<OptionsModalProps> = ({ possibleAnswers, value, onChange }) => {
  const resetAnswersMap = () =>
    possibleAnswers.reduce(
      (map, answer, index) => ({
        ...map,
        [answer.name]: { selected: !!value?.find((i) => i === answer.value), index: index },
      }),
      {},
    );

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [answersMap, setAnswers] =
    useState<Record<string, { selected: boolean; index: number }>>(resetAnswersMap);

  const currentlySelected = Object.keys(answersMap).reduce((selectedAnswers: number[], key) => {
    if (answersMap[key].selected) {
      return [...selectedAnswers, answersMap[key].index];
    } else {
      return selectedAnswers;
    }
  }, []);

  return (
    <>
      {value && (
        <div className={css.answerContainer}>
          {value.map((index) => {
            const valueName = possibleAnswers[index].name;
            return (
              <div key={`answer-bubble-${index}`} className={css.answerBubble}>
                <MobileBody style={{ marginRight: 16, display: 'inline' }}>{valueName}</MobileBody>
                <span
                  className={css.bubbleX}
                  onClick={() => {
                    onChange(_.without<number>(value, index));
                    setAnswers((answers) => {
                      const possibleAnswer = possibleAnswers[index];
                      return {
                        ...answers,
                        [possibleAnswer.name]: {
                          ...answers[possibleAnswer.name],
                          selected: !answers[possibleAnswer.name].selected,
                        },
                      };
                    });
                  }}
                >
                  <XIcon height={16} width={16} color={CoreColors.EdenDarken10} />
                </span>
              </div>
            );
          })}
        </div>
      )}
      <div className={css.modalContainer}>
        <LinkWithIcon text="Add States" onClick={() => setIsOpen(true)} />
      </div>
      <Modal isOpen={isOpen} className={css.modalContent} overlayClassName={css.modalOverlay}>
        <div className={css.xicon} onClick={() => setIsOpen(false)}>
          <XIcon height={24} width={24} color={CoreColors.Slate85} />
        </div>
        <BodyNormal style={{ paddingBottom: 28 }}>
          Select all the states you’ve traveled to outside of your state of residence and/or
          employment.
        </BodyNormal>
        <div className={css.form}>
          <div className={css.options}>
            {possibleAnswers.map((possibleAnswer) => (
              <ListItem
                key={possibleAnswer.name}
                selected={answersMap[possibleAnswer.name].selected}
                onClick={() =>
                  setAnswers((answers) => ({
                    ...answers,
                    [possibleAnswer.name]: {
                      ...answers[possibleAnswer.name],
                      selected: !answers[possibleAnswer.name].selected,
                    },
                  }))
                }
              >
                {possibleAnswer.name}
              </ListItem>
            ))}
          </div>
          <PrimaryButton
            onClick={() => {
              onChange(currentlySelected);
              setIsOpen(false);
            }}
            value={`Submit${
              currentlySelected.length > 0 ? ` • ${currentlySelected.length} States` : ''
            }`}
          />
        </div>
      </Modal>
    </>
  );
};
