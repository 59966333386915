import React, { useCallback, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { BackButton } from './BackButton';
import { Options } from './Options';
import css from './VaccinePage.module.css';
import { LocationState, Page } from './VisitorForm';
import { PrimaryButton } from 'components/design-system/Form';
import { Calendar, Info } from 'components/design-system/Icons';
import {
  BodyNormal,
  CaptionError,
  CaptionNormal,
  HeaderLarge,
  LinkCaption,
} from 'components/design-system/Text';
import { AccentColors } from 'types/colors';
import { FormState, MultipleChoiceQuestion } from 'types/screener';

export const VaccinePage: React.FC<{
  post: () => void;
  formState: FormState;
  vaccineRequired: boolean | undefined;
  setVaccineStatus: (vaccine_status: boolean | undefined) => void;
  errorMessage?: string;
}> = ({ post, formState, vaccineRequired, setVaccineStatus, errorMessage }) => {
  const question: MultipleChoiceQuestion = {
    key: 'VACCINE',
    questionId: 1,
    questionType: 'MultipleChoice',
    possibleAnswers: ['Yes', 'No'],
    question: 'Have you been fully vaccinated?',
    preamble:
      'Vaccines accepted by the US must be approved for emergency use by the World ' +
      'Health Organization or the US Food and Drug Administration. You are considered ' +
      'fully vaccinated 14 days after receipt of the 1 or 2 dose vaccination series. ' +
      'At the present time, these include:',
  };

  const isMobile = window.innerWidth <= 500;
  const textAlign = isMobile ? 'left' : 'center';
  const calAlign = isMobile ? 'flex-start' : 'center';

  const { url } = useRouteMatch();
  const history = useHistory();
  const nextLocationState: LocationState = useMemo(
    () => ({
      page: Page.ScreenerPage,
    }),
    [],
  );

  const nextClicked = useCallback(
    () => history.replace({ pathname: url, search: '', hash: '' }, nextLocationState),
    [history, url, nextLocationState],
  );

  return (
    <div className={css.container}>
      <BackButton isMobile={isMobile} />
      <Calendar style={{ marginBottom: 24, alignSelf: calAlign }} date={new Date()} color="ocean" />
      <HeaderLarge style={{ marginBottom: 48, width: '100%', textAlign: textAlign }}>
        COVID-19 Daily Screener
      </HeaderLarge>
      <div className={css.innerContainer}>
        <BodyNormal style={{ paddingBottom: 32, textAlign: textAlign }}>
          Please complete before arriving on-site.
        </BodyNormal>
        <div className={css.questionContainer} key={question.key}>
          <BodyNormal style={{ paddingBottom: 16 }} className={css.question}>
            <span style={{ fontWeight: 'bold' }}>{1}. </span> {question.question}
          </BodyNormal>
          <BodyNormal style={{ paddingBottom: 16 }} className={css.question}>
            {question.preamble}
            <ul>
              <li>Pfizer/BioNtech (2 doses)</li>
              <li>Moderna (2 doses)</li>
              <li>Janssen (Johnson &#38; Johnson) (1 dose)</li>
              <li>AstraZeneca/Covishield (1 dose)</li>
              <li>Sinopharm (1 dose)</li>
              <li>Sinovac/Coronavac (1 dose)</li>
            </ul>
          </BodyNormal>
          <Options
            selected={
              formState.isVaccinated === undefined
                ? undefined
                : formState.isVaccinated === true
                ? 0
                : 1
            }
            possibleAnswers={question.possibleAnswers}
            onClick={(i) => {
              const vaccineBool = i === 0 ? true : i === 1 ? false : undefined;
              return setVaccineStatus(vaccineBool);
            }}
          />
        </div>
        {errorMessage && (
          <div className={css.error}>
            <div style={{ marginRight: 12.67, marginTop: 4 }}>
              <Info color={AccentColors.Razz} height={14.67} width={14.67} />
            </div>
            <CaptionError data-test-id="login-error">
              There was an error with your submission, please try again or contact{' '}
              <LinkCaption
                href="mailto:support@edenhealth.com"
                target="_blank"
                rel="noreferrer noopener"
              >
                support@edenhealth.com
              </LinkCaption>
              {'. '}
              {errorMessage}
            </CaptionError>
          </div>
        )}
        <PrimaryButton
          style={{ marginTop: 12, marginBottom: 16 }}
          onClick={formState.isVaccinated || !vaccineRequired ? nextClicked : post}
          value="Next"
          disabled={formState.isVaccinated === undefined}
        />
        <CaptionNormal style={{ paddingBottom: 16, textAlign: 'center' }}>
          By clicking the “Next” button, I certify that the answers provided are true &#38; correct
          at the time of submission.
        </CaptionNormal>
      </div>
    </div>
  );
};
