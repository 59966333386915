import Auth from '@aws-amplify/auth';
import Amplify from 'aws-amplify';
import React, { createContext, useState, useEffect } from 'react';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { loadEnv } from 'lib/env';
import { resetRootAction, AppDispatch } from 'store';

const config = {
  Auth: {
    region: loadEnv('REACT_APP_COGNITO_REGION') as string,
    userPoolId: loadEnv('REACT_APP_COGNITO_USER_POOL_ID') as string,
    userPoolWebClientId: loadEnv('REACT_APP_COGNITO_CLIENT_ID') as string,
  },
};

Amplify.configure(config);

export enum MFA_KIND {
  TOTP = 'TOTP',
  SMS = 'SMS',
  NOMFA = 'NOMFA',
}

export enum ChallengeKind {
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
  MFA_SETUP = 'MFA_SETUP',
  SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA',
  MFA_SETUP_COMPLETE = 'MFA_SETUP_COMPLETE',
  SOFTWARE_TOKEN_VALIDATED = 'SOFTWARE_TOKEN_VALIDATED',
}

interface AuthState {
  user?: CognitoUser;
  username?: string;
  secret?: string;
  isAuthenticated: boolean;
  challenge?: ChallengeKind;
}

interface AuthInterface {
  signIn: (email: string, password: string) => Promise<CognitoUserSession>;
  signOut: (dispatch: AppDispatch) => Promise<void>;
  completeNewPassword: (newPassword: string) => Promise<CognitoUser | any>;
  generateOTPAuth: () => Promise<string | any>;
  verifyMFA: (code: string) => Promise<CognitoUser | any>;
  verifySoftwareToken: (code: string) => Promise<void>;
  completeChallenge: () => void;
  forgotPassword: (email: string) => Promise<void>;
  completeForgotPassword: (email: string, code: string, newPassword: string) => Promise<void>;
  changePassword: (currentPassword: string, newPassword: string) => Promise<void>;
  startMFASetup: () => void;
  cancelMFASetup: () => void;
  preferredMFA: () => Promise<string>;
}

export const AuthContext = createContext<AuthState & AuthInterface>({
  signIn: async () => {
    throw Error('Sign in function not initialized');
  },
  signOut: async () => {
    throw Error('Sign out function not initialized');
  },
  completeNewPassword: async () => {
    return;
  },
  generateOTPAuth: async () => {
    return;
  },
  verifyMFA: async () => {
    return;
  },
  verifySoftwareToken: async () => {
    return;
  },
  completeChallenge: () => {
    return;
  },
  forgotPassword: async () => {
    return;
  },
  completeForgotPassword: async () => {
    return;
  },
  changePassword: async () => {
    return;
  },
  startMFASetup: () => {
    return;
  },
  cancelMFASetup: () => {
    return;
  },
  preferredMFA: async () => {
    throw Error('preferredMFA function not initialized');
  },
  user: undefined,
  username: undefined,
  isAuthenticated: false,
  secret: undefined,
  challenge: undefined,
});

const sessionDefault = (): Promise<CognitoUserSession> => {
  throw Error('Session getter not initialized');
};

export let session = sessionDefault;

export const AuthProvider: React.FC = ({ children }) => {
  const [{ isAuthenticated, user, username, secret, challenge }, setState] = useState<AuthState>({
    user: undefined,
    username: undefined,
    isAuthenticated: false,
    secret: undefined,
    challenge: undefined,
  });
  // Use authed user from local storage if enabled
  const useSavedUser = (loadEnv('REACT_APP_USE_SAVED_USER') as string) === 'true';
  useEffect(() => {
    if (useSavedUser) {
      Auth.currentAuthenticatedUser({ bypassCache: false })
        .then((user) => {
          setState((state) => ({
            ...state,
            isAuthenticated: true,
            user,
            username: user.username,
          }));
          session = () => Auth.userSession(user);
          return;
        })
        .catch((e) => {
          if (e !== 'not authenticated') throw e;
        });
    }
  }, [useSavedUser]);
  const contextValue: AuthState & AuthInterface = {
    user,
    username,
    isAuthenticated,
    secret,
    challenge,
    signIn: async (email: string, password: string) => {
      const user = await Auth.signIn(email.toLowerCase(), password);
      // Challenge should exist - either NEW_PASSWORD_REQUIRED for first login or SOFTWARE_TOKEN_MFA on subsequent logins
      // Otherwise we need to set up MFA. DO NOT DELETE THE COMMENTED OUT CODE FOR NOW PLEASE.
      const challenge = user.challengeName; // ?? ChallengeKind.MFA_SETUP;
      setState((state) => ({
        ...state,
        isAuthenticated: !challenge,
        user,
        challenge,
      }));
      session = () => Auth.userSession(user);
      return user;
    },
    signOut: async (dispatch) => {
      await Auth.signOut();
      session = sessionDefault;
      setState((state) => ({
        ...state,
        isAuthenticated: false,
        user: undefined,
      }));
      dispatch({ type: resetRootAction.type });
    },
    completeNewPassword: async (newPassword: string) => {
      const authedUser = await Auth.completeNewPassword(user, newPassword, null);
      // Force MFA setup flow if not already selected & set up
      const challenge = authedUser.challengeName ?? ChallengeKind.MFA_SETUP;
      setState((state) => ({
        ...state,
        isAuthenticated: false,
        user: authedUser,
        challenge,
      }));
      session = () => Auth.userSession(authedUser);
      return user;
    },
    generateOTPAuth: async () => {
      if (user && !secret) {
        const secret = await Auth.setupTOTP(user);
        const uri = `otpauth://totp/${encodeURIComponent(
          user.getUsername(),
        )}?secret=${secret}&issuer=${encodeURIComponent('Eden Health')}`;
        setState((state) => ({
          ...state,
          secret: uri,
        }));
      }
    },
    verifySoftwareToken: async (code: string): Promise<void> => {
      await Auth.verifyTotpToken(user, code);
      await Auth.setPreferredMFA(user, MFA_KIND.TOTP);
      setState((state) => ({
        ...state,
        challenge: ChallengeKind.MFA_SETUP_COMPLETE,
        user,
      }));
    },
    verifyMFA: async (code: string) => {
      const authedUser = await Auth.confirmSignIn(user, code, ChallengeKind.SOFTWARE_TOKEN_MFA);
      setState((state) => ({
        ...state,
        user: authedUser,
        challenge: ChallengeKind.SOFTWARE_TOKEN_VALIDATED,
      }));
      session = () => Auth.userSession(authedUser);
    },
    completeChallenge: () => {
      setState((state) => ({
        ...state,
        isAuthenticated: true,
        challenge: undefined,
      }));
    },
    forgotPassword: async (email: string) => {
      await Auth.forgotPassword(email.toLowerCase());
    },
    completeForgotPassword: async (email: string, code: string, newPassword: string) => {
      await Auth.forgotPasswordSubmit(email.toLowerCase(), code, newPassword);
    },
    changePassword: async (currentPassword: string, newPassword: string) => {
      await Auth.changePassword(user, currentPassword, newPassword);
    },
    startMFASetup: () => {
      setState((state) => ({
        ...state,
        challenge: ChallengeKind.MFA_SETUP,
      }));
    },
    cancelMFASetup: () => {
      setState((state) => ({
        ...state,
        challenge: undefined,
      }));
    },
    preferredMFA: async () => {
      return await Auth.getPreferredMFA(user);
    },
  };
  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
