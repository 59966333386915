import React, { useState, useCallback, useMemo } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import css from './OnsiteDetailsPage.module.css';
import { LocationState, Page } from './VisitorForm';
import { BackButton } from './BackButton';
import { Field, Label, PrimaryButton, ErrorText } from 'components/design-system/Form';
import { Calendar } from 'components/design-system/Icons';
import {
  BodySmall,
  HeaderLarge,
  HeaderMedium,
  Link as TextLink,
} from 'components/design-system/Text';
import { FormState } from 'types/screener';
import grdn from 'lib/grdn';
import { isSuccessResponse, ValidateEmailsPayload } from 'types/grdn';
import { Spinner } from 'components/design-system/Spinner';

const basicEmailRegexp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/;

const validEmail = (email: string, validationPayload: ValidateEmailsPayload) => {
  const result = validationPayload.find((i) => i.email === email);
  return result?.valid || false;
};

interface OnsiteDetailsPageProps {
  setFormState: (fn: (state: FormState) => FormState) => void;
  formState: FormState;
}

export const OnsiteDetailsPage: React.FC<OnsiteDetailsPageProps> = ({
  setFormState,
  formState,
}) => {
  const { url } = useRouteMatch();
  const formFirstPartComplete =
    formState.lastName &&
    formState.firstName &&
    formState.email &&
    formState.email.match(basicEmailRegexp);
  const formComplete =
    formFirstPartComplete &&
    formState.contactName &&
    formState.contactEmail &&
    formState.contactEmail.match(basicEmailRegexp);
  const nextLocationState: LocationState = useMemo(
    () => ({
      page: Page.VaccinePage,
    }),
    [],
  );
  const isMobile = window.innerWidth <= 500;
  const textAlign = isMobile ? 'left' : 'center';
  const calAlign = isMobile ? 'flex-start' : 'center';
  const [{ emailValid, contactEmailValid, validating }, setState] = useState({
    emailValid: true,
    contactEmailValid: true,
    validating: false,
  });

  const history = useHistory();

  const nextClicked = useCallback(async () => {
    setState((state) => ({
      ...state,
      validating: true,
    }));
    const emails = formState.hostUnknown
      ? [formState.email]
      : [formState.email, formState.contactEmail];
    const response = await grdn.validateEmails(emails);
    if (isSuccessResponse<ValidateEmailsPayload>(response)) {
      const { data } = response;
      const emailValid = validEmail(formState.email, data);
      const contactEmailValid = validEmail(formState.contactEmail, data);
      if (formState.hostUnknown) {
        if (!emailValid) {
          setState((state) => ({
            ...state,
            emailValid,
            validating: false,
          }));
          return;
        }
      } else {
        if (!emailValid || !contactEmailValid) {
          setState((state) => ({
            ...state,
            emailValid,
            contactEmailValid,
            validating: false,
          }));
          return;
        }
      }
      history.replace(
        {
          pathname: url,
          search: '',
          hash: '',
        },
        nextLocationState,
      );
    }
  }, [
    history,
    url,
    formState.email,
    formState.contactEmail,
    formState.hostUnknown,
    nextLocationState,
  ]);

  return (
    <div className={css.container}>
      <BackButton isMobile={isMobile} />
      <Calendar style={{ marginBottom: 24, alignSelf: calAlign }} date={new Date()} color="ocean" />
      <HeaderLarge style={{ marginBottom: 48, textAlign: textAlign }}>
        First, tell us some details about your on-site visit today
      </HeaderLarge>
      <div className={css.innerContainer}>
        <Label text="Your First Name">
          <Field
            value={formState.firstName}
            onChange={(event) => {
              const firstName = event.currentTarget.value;
              setFormState((state) => ({
                ...state,
                firstName,
              }));
            }}
          />
        </Label>
        <Label text="Your Last Name">
          <Field
            value={formState.lastName}
            onChange={(event) => {
              const lastName = event.currentTarget.value;
              setFormState((state) => ({
                ...state,
                lastName,
              }));
            }}
          />
        </Label>
        <Label text="Your Email Address" style={{ marginBottom: 24 }}>
          <Field
            value={formState.email}
            onChange={(event) => {
              const email = event.currentTarget.value.toLowerCase();
              setFormState((state) => ({
                ...state,
                email,
              }));
            }}
            onBlur={async (event) => {
              const email = event.currentTarget.value.toLowerCase();
              setState((state) => ({
                ...state,
                emailValid: !!email.match(basicEmailRegexp),
              }));
            }}
          />
          {emailValid || (
            <ErrorText data-test-id="invalid-email">Please enter a valid email address</ErrorText>
          )}
        </Label>
        {formState.hostUnknown ? (
          <BodySmall style={{ marginTop: 24, marginBottom: 28 }}>
            <TextLink
              onClick={() => {
                setFormState((state) => ({
                  ...state,
                  hostUnknown: false,
                }));
              }}
              style={{ cursor: 'pointer', paddingRight: 24, fontSize: 12 }}
            >
              Add my host information.
            </TextLink>
          </BodySmall>
        ) : (
          <>
            <HeaderMedium style={{ marginBottom: 16, marginTop: 24 }}>
              Who are you visiting?
            </HeaderMedium>
            <BodySmall style={{ marginBottom: 32 }}>
              Your host will also receive an email notification about your screener results.
              <br />
              <TextLink
                onClick={() => {
                  setFormState((state) => ({
                    ...state,
                    hostUnknown: true,
                  }));
                }}
                style={{ cursor: 'pointer', paddingRight: 24, paddingBottom: 16, fontSize: 12 }}
              >
                I don&apos;t know my host information.
              </TextLink>
            </BodySmall>
            <Label text="Host Full Name">
              <Field
                value={formState.contactName}
                onChange={(event) => {
                  const contactFullName = event.currentTarget.value;
                  setFormState((state) => ({
                    ...state,
                    contactName: contactFullName,
                  }));
                }}
              />
            </Label>
            <Label text="Host Email Address">
              <Field
                value={formState.contactEmail}
                onChange={(event) => {
                  const contactEmail = event.currentTarget.value.toLowerCase();
                  setFormState((state) => ({
                    ...state,
                    contactEmail,
                  }));
                }}
                onBlur={(event) => {
                  const contactEmail = event.currentTarget.value.toLowerCase();
                  setState((state) => ({
                    ...state,
                    contactEmailValid: !!contactEmail.match(basicEmailRegexp),
                  }));
                }}
              />
              {contactEmailValid || (
                <ErrorText data-test-id="invalid-email">
                  Please enter a valid email address
                </ErrorText>
              )}
            </Label>
          </>
        )}
        {validating ? (
          <div className={css.spinner}>
            <Spinner />
          </div>
        ) : (
          <PrimaryButton
            disabled={!(formComplete || (formState.hostUnknown && formFirstPartComplete))}
            style={{ marginTop: 12 }}
            value="Next"
            onClick={nextClicked}
          />
        )}
      </div>
    </div>
  );
};
