import { Question } from 'types/screener';

// There does not seem to be a common place to split question strings returned from the backend,
// so this function splits on the two places it seems to split, after the first question mark
// when there is not a parentheses after the question mark
// and after a colon
export const splitQuestion = (question: Question): string[] =>
  question.question
    .replace(':', ':;')
    .replace('\n', '')
    .replace('? (', '_')
    .replace('?', '?;')
    .split(';')
    .map((s) => s.replace('_', '? ('));
