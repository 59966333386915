import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { Member, VaccineDetails, WorkStatusEnum } from 'types/db/members';
import GrdnApi from 'lib/grdn';
import {
  ErrorResponse,
  MemberPayload,
  MembersPayload,
  SuccessResponse,
  TestingResultsPayload,
} from 'types/grdn';
import { Store } from 'store';

const transformPayload = (data: MemberPayload): Member => {
  // Convert empty triageStatus to 'Screening Incomplete'
  return {
    ...data,
    triageStatus: data.triageStatus || WorkStatusEnum.Incomplete,
    testingResults: [],
  };
};

export const memberAdapter = createEntityAdapter<Member>({
  selectId: (member) => member.id,
});

export const memberInitState = memberAdapter.getInitialState();

export const fetchMembers = createAsyncThunk<
  Member[],
  Record<string, any>,
  {
    rejectValue: ErrorResponse;
  }
>('member/fetchMembers', async (params, thunkAPI) => {
  try {
    const response = await GrdnApi.getMembers(params);
    return (response as SuccessResponse<MembersPayload>).data.map(transformPayload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const fetchMember = createAsyncThunk<Member, { id: string }, { rejectValue: ErrorResponse }>(
  'member/fetchMember',
  async ({ id, ...params }, thunkAPI) => {
    try {
      const response = await GrdnApi.getMember(id, params);
      const data = (response as SuccessResponse<MemberPayload>).data;
      return { testingResults: [], ...data, id };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchTestingResults = createAsyncThunk<
  Pick<Member, 'testingResults' | 'id'>,
  { id: string },
  { rejectValue: ErrorResponse }
>('member/fetchTestingResults', async ({ id }, thunkAPI) => {
  try {
    const response = await GrdnApi.getTestingResults(id);
    const data = (response as SuccessResponse<TestingResultsPayload>).data;
    return { testingResults: data, id };
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const memberSlice = createSlice({
  name: 'member',
  initialState: memberInitState,
  reducers: {},
  extraReducers: {
    [fetchMembers.fulfilled.toString()]: (state, action) => {
      memberAdapter.setAll(state, action.payload);
    },
    [fetchMember.fulfilled.toString()]: (state, action) => {
      memberAdapter.upsertOne(state, action.payload);
    },
    [fetchTestingResults.fulfilled.toString()]: (state, action) => {
      memberAdapter.upsertOne(state, action.payload);
    },
  },
});

export const memberSelectors = memberAdapter.getSelectors<Store>((state) => state.member);

export const getMemberRegistry = createSelector(
  [
    (state: Store, props: { id: string | undefined }) =>
      props.id && memberSelectors.selectById(state, props.id),
  ],
  (member) => {
    return member && member?.covidRegistry
      ? member.covidRegistry
          .filter((record) => {
            return (
              record.createdAt !== undefined &&
              record.triageStatus !== undefined &&
              (record.createdAt || record.triageStatus)
            );
          })
          .map((record) => {
            return {
              dateTime: record.createdAt,
              status: record.triageStatus,
              isolateReasons: record.isolateReasons,
            };
          })
      : [];
  },
);

export const getCustomFields = createSelector<Store, Member[], (keyof Member['data'])[]>(
  [(state: Store) => memberSelectors.selectAll(state)],
  (members) => {
    return members.reduce((acc: (keyof Member['data'])[], member: Member) => {
      return acc.concat(Object.keys(member.data)).filter((v, i, a) => a.indexOf(v) === i);
    }, []);
  },
);

export const getTestingResults = createSelector(
  [
    (state: Store, props: { id: string }) =>
      props.id && memberSelectors.selectById(state, props.id),
  ],
  (member) => {
    return member ? member.testingResults : [];
  },
);

export const getVaccineDetails = (state: Store, { id }: { id: string }): VaccineDetails[] =>
  memberSelectors.selectById(state, id)?.vaccineDetails || [];

export default memberSlice.reducer;
