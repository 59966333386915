import cx from 'classnames';
import React, { ReactElement } from 'react';
import { TestResult, VaccinationStatusEnum, WorkStatusEnum } from '../../types/db/members';
import css from './Orb.module.css';

/**
 * Set of colors the orbs can be.
 * These values correspond to class names used to effectively style the orb.
 */
export type OrbColor = 'red' | 'yellow' | 'green' | 'gray' | 'blue' | 'hidden';

type OrbFactory = {
  ({
    value,
  }: {
    /**
     * @deprecated Use `color` instead.
     */
    value: string;
  }): ReactElement;
  ({ color }: { color: OrbColor }): ReactElement;
};

export const Orb: OrbFactory = ({
  value,
  color,
}: {
  value?: string;
  color?: OrbColor;
}): ReactElement => {
  if (color) return <div className={cx(css.orb, css[color])} />;

  return <div className={cx(css.orb, value && css[value.split(' ')[0].toLowerCase()])} />;
};

export const OrbContainer: React.FC<{
  color: OrbColor;
  text: string;
  defaultColor?: OrbColor;
}> = ({ color, text, defaultColor = 'hidden' }) => (
  <div className={css.orbContainer} style={{ wordBreak: 'keep-all' }}>
    <Orb color={color || defaultColor} />
    <div style={{ textTransform: 'capitalize' }}>{text}</div>
  </div>
);

export const vaccinationStatusColors: Record<string, OrbColor> = {
  [VaccinationStatusEnum.NotVaccinated]: 'gray',
  [VaccinationStatusEnum.UnknownVaccine]: 'hidden',
  [VaccinationStatusEnum.FirstDoseComplete]: 'yellow',
  [VaccinationStatusEnum.SecondDoseComplete]: 'yellow',
  [VaccinationStatusEnum.PrimarySeriesComplete]: 'blue',
  [VaccinationStatusEnum.PrimarySeriesPlus]: 'green',
  // Deprecated
  [VaccinationStatusEnum.FullyVaccinated]: 'green',
};

export const testResultColors: Record<TestResult, OrbColor> = {
  negative: 'green',
  positive: 'red',
  pending: 'hidden',
  inconclusive: 'yellow',
  none: 'hidden',
};

export const workStatusColors: Record<WorkStatusEnum, OrbColor> = {
  [WorkStatusEnum.Incomplete]: 'gray',
  [WorkStatusEnum.Isolate]: 'yellow',
  [WorkStatusEnum.Cleared]: 'green',
};
