import React, { ReactNode } from 'react';
import { DocumentDownloader } from './DocumentDownloader';
import { Member } from 'types/db/members';

export interface VaccineDownloaderProps {
  children: {
    memberId: Member['id'];
    documentId: number | null;
    button: ReactNode;
  };
}

const ErrorMessage = () => (
  <span>
    There was a problem downloading the vaccine card. Please try again or contact{' '}
    <a href="mailto:support@edenhealth.com">support@edenhealth.com</a>
  </span>
);

export const VaccineDownloader: React.FC<VaccineDownloaderProps> = ({
  children,
}: VaccineDownloaderProps) => {
  const { memberId, documentId, button } = children;

  return documentId ? (
    <DocumentDownloader>
      {{
        documentType: 'vaccine',
        memberId,
        documentId,
        button,
        messages: {
          error: <ErrorMessage />,
        },
      }}
    </DocumentDownloader>
  ) : null;
};
