import React, { HTMLProps, ReactElement, useRef, useState } from 'react';
import cx from 'classnames';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DayPickerInputProps } from 'react-day-picker/types/Props';
import { DateTime } from 'luxon';
import { CoreColors } from '../../types/colors';
import css from './Form.module.css';
import { SpinnerMedium, Square, SquareChecked, SVGProps } from './Icons';
import 'react-day-picker/lib/style.css';
import { BodySmall, Link } from './Text';

interface Errorable {
  error?: boolean;
}

interface WithIcon {
  icon?: React.FC<SVGProps>;
}

interface Loadable {
  loading?: boolean;
}

export const ErrorText: React.FC<HTMLProps<HTMLDivElement>> = (props) => (
  <div className={css.errorText} {...props} />
);

export const Field: React.FC<HTMLProps<HTMLInputElement> & Errorable & WithIcon> = ({
  error = false,
  icon,
  className,
  ...props
}) => {
  const field = <input className={cx(error && css.error, css.field, className)} {...props} />;

  if (icon) {
    const Icon = icon;

    return (
      <div className={css.fieldWithIcon}>
        <Icon color={CoreColors.Slate85} width={16} height={16} />
        {field}
      </div>
    );
  }

  return field;
};

interface OptionProps {
  value: string | undefined;
}

export const Option: React.FC<OptionProps & HTMLProps<HTMLDivElement>> = ({ value, ...props }) => (
  <div key={value} {...props} />
);

interface SelectOnlyProps {
  arrow?: boolean;
  values: ReactElement<{ value: string }>[];
  change: (value: string) => void;
  openUp?: boolean;
  styles?: {
    modalOuter: string;
  };
}

export type SelectProps = HTMLProps<HTMLDivElement> & SelectOnlyProps;

export const Select: React.FC<SelectProps> = ({
  className,
  arrow = true,
  values,
  value,
  change,
  openUp,
  style,
  styles,
  ...props
}) => {
  const [isOpen, changeOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement | null>(null);
  const modalRef = useRef<HTMLDivElement | null>(null);

  const select = (
    <div
      style={{
        borderRadius: 4,
        backgroundColor: !isOpen ? CoreColors.White : CoreColors.Slate15,
      }}
      ref={selectRef}
    >
      <div
        className={cx(css.select, !arrow && css.arrowless)}
        onClick={() => changeOpen((state) => !state)}
        {...props}
      >
        {values.find((node) => node.props.value === value)}
      </div>
    </div>
  );

  const modal = (
    <div className={styles?.modalOuter}>
      <div
        style={{
          visibility: isOpen ? 'visible' : 'hidden',
          bottom:
            selectRef.current && modalRef.current && openUp
              ? // Account for margins that push this down
                selectRef.current.clientHeight + modalRef.current.clientHeight + 24
              : 'unset',
        }}
        className={css.modalOuter}
      >
        <div
          ref={modalRef}
          style={{
            // Account for padding with width setting
            minWidth: selectRef.current ? selectRef.current.clientWidth - 16 : 0,
          }}
          className={cx(css.selectModal)}
        >
          {values.map((v) => (
            <BodySmall
              className={css.selectValue}
              key={v.props.value}
              onClick={() => {
                change(v.props.value);
                changeOpen(false);
              }}
            >
              {v}
            </BodySmall>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <>
      {isOpen && <div onClick={() => changeOpen(false)} className={css.selectCover} />}
      <div
        className={className}
        style={{
          ...style,
        }}
      >
        {select}
        {modal}
      </div>
    </>
  );
};

export const EdenDayPicker: React.FC<DayPickerInputProps> = ({
  classNames,
  onDayChange,
  ...props
}) => {
  const [isOpen, changeOpen] = useState(false);
  const [selected, changeSelected] = useState(new Date());

  return (
    <DayPickerInput
      classNames={{
        container: cx(css.fieldContainer, classNames?.container, isOpen && css.fieldContainerOpen),
        overlay: cx(css.dayPickerOverlay, classNames?.overlay),
        overlayWrapper: cx(css.dayPickerOverlayWrapper, classNames?.overlayWrapper),
      }}
      dayPickerProps={{
        disabledDays: { after: new Date() },
        selectedDays: (date) =>
          DateTime.fromJSDate(date).hasSame(DateTime.fromJSDate(selected), 'day'),
      }}
      onDayChange={(day, ...args) => {
        changeSelected(day);
        onDayChange && onDayChange(day, ...args);
      }}
      onDayPickerShow={() => changeOpen(true)}
      onDayPickerHide={() => changeOpen(false)}
      {...props}
    />
  );
};

export const PrimaryButton: React.FC<HTMLProps<HTMLInputElement> & Loadable> = ({
  className,
  loading = false,
  style,
  ...props
}) =>
  loading ? (
    <div style={style} className={cx(css.primaryButtonLoading, className)}>
      <div className={css.inner} style={{ width: 24, height: 24 }}>
        <SpinnerMedium height={24} width={24} color={CoreColors.White} />
      </div>
    </div>
  ) : (
    <input className={cx(css.primaryButton, className)} style={style} type="button" {...props} />
  );

export interface LabelProps extends HTMLProps<HTMLLabelElement> {
  text: string;
}

export const Label: React.FC<LabelProps> = ({ text, className, children, ...props }) => (
  <label {...props} className={css.label}>
    <div className={cx(className, css.labelText)}>{text}</div>
    {children}
  </label>
);

export interface LinkWithIconProps extends HTMLProps<HTMLDivElement> {
  text: string;
}

export const LinkWithIcon: React.FC<LinkWithIconProps> = ({ text, className, ...props }) => (
  <div className={cx(css.linkWithIcon, className)} {...props}>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0002 2.49992C5.85803 2.49992 2.50016 5.85778 2.50016 9.99992C2.50016 14.1421 5.85803 17.4999 10.0002 17.4999C14.1423 17.4999 17.5002 14.1421 17.5002 9.99992C17.5002 5.85778 14.1423 2.49992 10.0002 2.49992ZM0.833496 9.99992C0.833496 4.93731 4.93755 0.833252 10.0002 0.833252C15.0628 0.833252 19.1668 4.93731 19.1668 9.99992C19.1668 15.0625 15.0628 19.1666 10.0002 19.1666C4.93755 19.1666 0.833496 15.0625 0.833496 9.99992Z"
        fill="#14B87C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99984 5.83325C10.4601 5.83325 10.8332 6.20635 10.8332 6.66659V13.3333C10.8332 13.7935 10.4601 14.1666 9.99984 14.1666C9.5396 14.1666 9.1665 13.7935 9.1665 13.3333V6.66659C9.1665 6.20635 9.5396 5.83325 9.99984 5.83325Z"
        fill="#14B87C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.8335 10.0001C5.8335 9.53984 6.20659 9.16675 6.66683 9.16675H13.3335C13.7937 9.16675 14.1668 9.53984 14.1668 10.0001C14.1668 10.4603 13.7937 10.8334 13.3335 10.8334H6.66683C6.20659 10.8334 5.8335 10.4603 5.8335 10.0001Z"
        fill="#14B87C"
      />
    </svg>
    <Link style={{ paddingLeft: 14 }}>{text}</Link>
  </div>
);

export interface ListItemProps extends HTMLProps<HTMLDivElement> {
  selected: boolean;
}

export const ListItem: React.FC<ListItemProps> = ({ selected, children, className, ...props }) => (
  <div {...props} className={cx(css.listItemOuter, className)}>
    {selected ? (
      <SquareChecked height={24} width={24} />
    ) : (
      <Square height={24} width={24} color={CoreColors.SlateDarken20} />
    )}
    <div className={css.listItemInner}>{children}</div>
  </div>
);

type CheckboxExtendedType = HTMLProps<HTMLDivElement> & SVGProps;

export interface CheckboxProps extends CheckboxExtendedType {
  checked: boolean;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  height = 20,
  width = 20,
  color = CoreColors.Slate85,
  style,
  ...props
}) => (
  <div
    {...props}
    style={{ ...style, maxHeight: height, maxWidth: width, minHeight: height, minWidth: width }}
  >
    {checked ? (
      <SquareChecked height={height} width={width} />
    ) : (
      <Square color={color} height={height} width={width} />
    )}
  </div>
);
