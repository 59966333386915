import React, { useEffect, useMemo, useState } from 'react';
import { Column } from 'react-table';
import { CSVLink } from 'react-csv';
import { TimeCell } from '../../design-system/Cells';
import css from './UnconsentedModal.module.css';
import { EdenTable, useEdenTable } from 'components/design-system/Table';
import { DownloadCloud, XIcon } from 'components/design-system/Icons';
import { Employee } from 'types/db/members';
import { timezoneAbbr } from 'lib/time';
import { Modal } from 'components/design-system/Modal';
import { CoreColors } from 'types/colors';
import { BodySmall, HeaderMedium } from 'components/design-system/Text';
import textStyles from 'components/design-system/Text.module.css';
import { formatTime } from 'lib/time';

const headers = ['F Name', 'L Name', 'Update Time', 'Employee ID', 'Work Email', 'Office Location'];

interface CsvRow {
  'F Name': string;
  'L Name': string;
  'Update Time': string;
  'Employee ID': string;
  'Work Email': string;
  'Office Location': string;
}

const toCsvRow = (row: Employee): CsvRow => {
  const csvRow = {
    'F Name': row.firstName,
    'L Name': row.lastName,
    'Update Time': formatTime(row.updatedAt),
    'Employee ID': row.employeeId,
    'Work Email': row.email,
    'Office Location': row.worksite,
  };
  return csvRow;
};

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  data: Employee[];
}

export const UnconsentedModal: React.FC<ModalProps> = ({ isOpen, closeModal, data }) => {
  const defaultColumns = useMemo<Column<Employee>[]>(
    () => [
      {
        Header: 'f name',
        accessor: 'firstName',

        minWidth: 120,
        width: 120,
        maxWidth: 1000,
      },
      { Header: 'l name', accessor: 'lastName', minWidth: 120, width: 120, maxWidth: 1000 },
      {
        Header: `update time (${timezoneAbbr()})`,
        accessor: 'updatedAt',
        Cell: TimeCell,
        minWidth: 160,
        width: 160,
        maxWidth: 1000,
      },
      { Header: 'employee id', accessor: 'employeeId', minWidth: 120, width: 160, maxWidth: 1000 },
      { Header: 'work email', accessor: 'email', minWidth: 160, width: 260, maxWidth: 1000 },
      {
        Header: 'office location',
        accessor: 'worksite',
        minWidth: 140,
        width: 200,
        maxWidth: 1000,
      },
    ],
    [],
  );
  const [columns, setColumns] = useState(defaultColumns);
  useEffect(() => {
    setColumns(() => defaultColumns);
  }, [defaultColumns]);
  const options = {
    data,
    columns,
    defaultColumn: {},
    initialState: {
      pageIndex: 0,
      pageSize: 25,
      sortBy: [
        {
          id: 'updatedAt',
          desc: true,
        },
      ],
    },
  };
  const tableInstance = useEdenTable<Employee>(options);

  return (
    <Modal
      isOpen={isOpen}
      className={css.unconsentedModalContent}
      overlayClassName={css.unconsentedModalOverlay}
      shouldCloseOnOverlayClick
      onRequestClose={closeModal}
    >
      <div
        className={css.leftSubHeader}
        style={{
          marginRight: 12,
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <HeaderMedium>Unconsented Employees</HeaderMedium>
        <div
          style={{
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            marginLeft: 46,
          }}
        >
          <CSVLink data={data.map(toCsvRow)} headers={headers} filename="unconsented_employees.csv">
            <DownloadCloud color={CoreColors.Eden} width={20} height={20} />
            <BodySmall
              className={textStyles.semibold}
              style={{
                color: CoreColors.Eden,
                marginLeft: 8,
                float: 'right',
              }}
            >
              Export as CSV
            </BodySmall>
          </CSVLink>
        </div>
      </div>
      <div
        onClick={closeModal}
        style={{
          position: 'absolute',
          top: '29px',
          right: '29px',
          cursor: 'pointer',
        }}
      >
        <XIcon width={24} height={24} color={CoreColors.Slate85} />
      </div>
      <div style={{ marginTop: 33 }}>
        <EdenTable<Employee>
          tableInstance={tableInstance}
          className={css.table}
          paginate={{
            pageSizes: [25, 50, 100],
          }}
          dataName="unconsented"
          scrollable
        />
      </div>
    </Modal>
  );
};
