import React, { ReactNode, useState } from 'react';
import cx from 'classnames';
import css from './Tabs.module.css';
import { KickerHeader } from './Text';

type TabChildType = false | React.FunctionComponentElement<TabProps>;

interface TabsProps {
  children: TabChildType[];
  onChange?: (value: string) => void;
  className?: string;
}

export const Tabs: React.FC<TabsProps> = ({ className, children }) => {
  const [active, setActive] = useState<number>(0);

  const tabs = children.map(
    (child, index) =>
      child && (
        <TabWrapper
          key={index}
          setActive={() => setActive(index)}
          active={active === index}
          label={child.props.label}
        />
      ),
  );

  return (
    <div className={cx(css.tabsContainer, className)}>
      <div className={css.tabs}>{tabs}</div>
      <div>{children[active]}</div>
    </div>
  );
};

interface TabProps {
  label: string;
  children: ReactNode;
}

export const Tab: React.FC<TabProps> = ({ children }) => <div>{children}</div>;

interface TabWrapperProps {
  active: boolean;
  label: string;
  setActive: () => void;
}

export const TabWrapper: React.FC<TabWrapperProps> = ({ label, active, setActive }) => (
  <button className={cx(css.tab, active && css.active)} onClick={setActive}>
    <KickerHeader className={cx(css.text, active && css.active)}>{label}</KickerHeader>
  </button>
);
