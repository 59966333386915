import React from 'react';
import cx from 'classnames';
import { MobileBody } from '../../design-system/Text';
import css from './Options.module.css';

interface YesNoProps {
  selected: number | undefined;
  possibleAnswers: string[];
  onClick: (newVal: number | undefined) => void;
}

export const Options: React.FC<YesNoProps> = ({ selected, onClick, possibleAnswers }) => {
  return (
    <div className={css.container}>
      {possibleAnswers.map((possibleAnswer, i) => {
        return (
          <MobileBody
            key={possibleAnswer}
            className={cx(
              css.cell,
              i === 0 && css.leftCell,
              i === possibleAnswers.length - 1 && css.rightCell,
              selected === i && css.selected,
              selected === i - 1 && css.leftOfSelected,
            )}
            onClick={() => onClick(selected === i ? undefined : i)}
          >
            {possibleAnswer}
          </MobileBody>
        );
      })}
    </div>
  );
};
