import { DateTime, Zone } from 'luxon';

export const guessTz = (): string => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getShortenedTz = (date: Date): string => DateTime.fromJSDate(date).toFormat('z');

export const timezoneAbbr = (): string => getShortenedTz(new Date());

export const isoDate = (value?: Date): string =>
  DateTime.fromJSDate(value ?? new Date()).toISODate();

// Options for formatting a date to calendar format.
type CalendarDateOptions = {
  // Whether or not to include time in the formatted date.
  displayTime?: boolean;

  // Whether or not to include the year in the formatted date.
  displayYear?: boolean;

  // The time zone to set on the passed date.
  timeZone?: string | Zone;
};

/**
 * Transforms a valid ISO 8601 date string to a user-friendly format for display.
 *
 * @param isoDateString The ISO 8601 date string to format.
 * @param options Options to alter the end result. See {@link CalendarDateOptions}.
 * @returns {string} The formatted date. Returns an empty string for an empty
 *  date string.
 *
 * @example
 * // Returns `May 28, 2022`
 * calendarDate(`2022-05-28`);
 * @example
 * // Returns `Jun. 14, 2021 12:00 AM`
 * calendarDate(`2021-06-14`, { displayYear: true, displayTime: true });
 */
export const calendarDate = (
  isoDateString: string,
  options: CalendarDateOptions = { displayTime: false, displayYear: false, timeZone: undefined },
): string => {
  if (!isoDateString) return '';

  const date = DateTime.fromISO(isoDateString, { zone: options.timeZone });

  let formattedDate =
    date.toFormat('MMMM').length <= 3 ? date.toFormat('MMM d') : date.toFormat('MMM. d');

  if (options.displayTime || options.displayYear) formattedDate += `, `;
  if (options.displayYear) formattedDate += date.toFormat(`yyyy`);
  if (options.displayYear && options.displayTime) formattedDate += ` `;
  if (options.displayTime) formattedDate += getFormattedTime(date);

  return formattedDate;
};

export const formatTime = (value?: string): string => {
  return typeof value !== undefined && value
    ? getFormattedTime(DateTime.fromJSDate(new Date(value)))
    : '';
};

export const isDateToday = (value?: Date): boolean => {
  return typeof value !== undefined && isoDate(value) === isoDate(new Date());
};

const getFormattedTime = (date: DateTime): string => {
  return date.toFormat(`h:mm a`);
};
