import React, { HTMLProps } from 'react';
import { DateTime } from 'luxon';
import css from './Icons.module.css';
import { ListKicker } from './Text';
import { ColorProps, Colors, CoreColors } from 'types/colors';

export type SVGProps = React.SVGProps<SVGSVGElement> & ColorProps;

export const EdenFullLogo: React.FC<SVGProps> = (props) => (
  <svg
    width="114"
    height="77"
    viewBox="0 0 114 77"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.3374 26.327C27.3374 25.4191 26.9479 24.6329 26.1989 23.9974C25.4504 23.3321 24.5812 22.9995 23.5328 22.9995C22.4243 22.9995 21.4364 23.3321 20.6273 23.9671C19.8187 24.6026 19.3696 25.3887 19.2795 26.327H27.3374ZM15.625 27.96C15.625 25.5404 16.374 23.6046 17.8411 22.1223C19.3391 20.64 21.2266 19.9141 23.5328 19.9141C25.7197 19.9141 27.5167 20.64 28.895 22.1223C30.3029 23.6046 30.9914 25.4191 30.9914 27.597C30.9914 28.0505 30.9618 28.4741 30.9313 28.8674H19.1894C19.309 31.287 21.0764 32.8598 23.7421 32.8598C25.3002 32.8598 26.6485 32.285 27.8166 31.1055L29.973 33.2228C28.2657 35.0377 26.1684 35.9447 23.6229 35.9447C21.2566 35.9447 19.309 35.2188 17.8411 33.7971C16.374 32.3755 15.625 30.4701 15.625 28.0808V27.96Z"
      fill="#6ED69A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.9074 31.287C44.8062 30.4098 45.2553 29.2905 45.2553 27.9599C45.2553 26.6288 44.8062 25.51 43.9074 24.6328C43.0388 23.7552 41.9307 23.3022 40.6425 23.3022C39.3543 23.3022 38.3058 23.7552 37.4372 24.6328C36.5985 25.51 36.179 26.6288 36.179 27.9599C36.179 29.2905 36.5985 30.4098 37.4372 31.287C38.3058 32.1636 39.3543 32.6176 40.6425 32.6176C41.9307 32.6176 43.0388 32.1636 43.9074 31.287ZM44.9856 22.5459V14.5H48.5805V30.9842C48.5805 32.285 48.8799 32.7688 49.689 32.7688C49.8983 32.7688 50.1681 32.7389 50.438 32.7086V35.6423C50.0484 35.7935 49.4491 35.8845 48.6706 35.8845C47.8019 35.8845 47.1125 35.7333 46.5733 35.4007C45.9143 34.9467 45.4953 34.3416 45.3451 33.5554C44.2971 35.1585 42.5598 35.9447 40.163 35.9447C38.0361 35.9447 36.2392 35.1888 34.7712 33.6459C33.3337 32.1035 32.6147 30.2283 32.6147 27.9599C32.6147 25.6915 33.3337 23.8163 34.7712 22.3037C36.2392 20.7613 38.0361 20.005 40.163 20.005C42.5893 20.005 44.4163 21.0939 44.9856 22.5459Z"
      fill="#6ED69A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.7593 26.327C62.7593 25.4191 62.3698 24.6329 61.6208 23.9974C60.8722 23.3321 60.0031 22.9995 58.9547 22.9995C57.8466 22.9995 56.8583 23.3321 56.0492 23.9671C55.2406 24.6026 54.7915 25.3887 54.7014 26.327H62.7593ZM51.0469 27.96C51.0469 25.5404 51.7959 23.6046 53.2634 22.1223C54.7609 20.64 56.6485 19.9141 58.9546 19.9141C61.1416 19.9141 62.939 20.64 64.3169 22.1223C65.7248 23.6046 66.4138 25.4191 66.4138 27.597C66.4138 28.0505 66.3837 28.4741 66.3537 28.8674H54.6117C54.7314 31.287 56.4983 32.8598 59.1644 32.8598C60.722 32.8598 62.0703 32.285 63.2384 31.1055L65.3949 33.2228C63.688 35.0377 61.5907 35.9447 59.0447 35.9447C56.6785 35.9447 54.7314 35.2188 53.2634 33.7971C51.7959 32.3755 51.0469 30.4701 51.0469 28.0808V27.96Z"
      fill="#6ED69A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.9606 32.5574C83.1515 32.5574 82.9417 32.1337 82.9417 30.9543V26.1449C82.9417 22.3644 80.8749 20.0353 77.3106 20.0353C75.1878 20.0353 73.5133 21.0295 72.7916 22.5067C72.6631 21.7163 72.3369 21.0319 71.5528 20.5303C71.0186 20.1888 70.3237 20.0465 69.4555 20.0465C68.6764 20.0465 67.7472 20.2569 67.3582 20.4081V23.3418C67.6275 23.3115 67.8974 23.2816 68.1072 23.2816C68.9158 23.2816 69.2226 23.7655 69.2226 25.0662V35.5821H72.787V26.8107C72.787 24.7238 74.1949 23.2714 76.2021 23.2714C78.2088 23.2714 79.3774 24.5721 79.3774 26.6591V31.7404C79.3774 34.4022 80.545 35.7333 82.9122 35.7333C83.5406 35.7333 84.0502 35.6726 84.4693 35.5513V32.4967C84.3196 32.5271 84.1399 32.5574 83.9606 32.5574Z"
      fill="#6ED69A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.5595 59.0979V50.1749C26.5595 48.0879 25.4515 46.7872 23.5339 46.7872C21.5873 46.7872 20.2094 48.2391 20.2094 50.3261V59.0979H16.645V38.0162H20.2094V45.9707C20.8983 44.5187 22.546 43.5511 24.6124 43.5511C28.0876 43.5511 30.1244 45.8802 30.1244 49.6612V59.0979H26.5595Z"
      fill="#2E4349"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M93.8102 59.0979V50.1749C93.8102 48.0879 92.7022 46.7872 90.7851 46.7872C88.838 46.7872 87.4601 48.2391 87.4601 50.3261V59.0979H83.8953V38.0162H87.4601V45.9707C88.149 44.5187 89.7968 43.5511 91.8631 43.5511C95.3383 43.5511 97.3751 45.8802 97.3751 49.6612V59.0979H93.8102Z"
      fill="#2E4349"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.9368 49.8422C43.9368 48.9348 43.5478 48.1486 42.7988 47.5132C42.0498 46.8478 41.1811 46.5152 40.1327 46.5152C39.0246 46.5152 38.0358 46.8478 37.2272 47.4833C36.4186 48.1183 35.969 48.9045 35.8789 49.8422H43.9368ZM32.2249 51.4753C32.2249 49.0561 32.9734 47.1204 34.4414 45.6376C35.9389 44.1558 37.8265 43.4303 40.1326 43.4303C42.3196 43.4303 44.1165 44.1558 45.4949 45.6376C46.9028 47.1204 47.5913 48.9348 47.5913 51.1127C47.5913 51.5662 47.5612 51.9899 47.5317 52.3827H35.7892C35.9094 54.8023 37.6763 56.3756 40.3419 56.3756C41.9 56.3756 43.2479 55.8012 44.4164 54.6208L46.5733 56.7386C44.8651 58.553 42.7682 59.4605 40.2227 59.4605C37.856 59.4605 35.9094 58.735 34.4414 57.3134C32.9734 55.8918 32.2249 53.9858 32.2249 51.5966V51.4753Z"
      fill="#2E4349"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.6945 54.8023C61.5936 53.9256 62.0423 52.8063 62.0423 51.4752C62.0423 50.145 61.5936 49.0258 60.6945 48.1486C59.8258 47.271 58.7173 46.818 57.4295 46.818C56.1413 46.818 55.0929 47.271 54.2242 48.1486C53.3855 49.0258 52.9664 50.145 52.9664 51.4752C52.9664 52.8063 53.3855 53.9256 54.2242 54.8023C55.0929 55.6799 56.1413 56.1334 57.4295 56.1334C58.7173 56.1334 59.8258 55.6799 60.6945 54.8023ZM62.1324 59.0979V57.0707C61.084 58.6743 59.3466 59.4604 56.9504 59.4604C54.8235 59.4604 53.026 58.7041 51.5585 57.1617C50.1201 55.6192 49.4016 53.7436 49.4016 51.4752C49.4016 49.2068 50.1201 47.3316 51.5585 45.8195C53.026 44.2771 54.8235 43.5208 56.9504 43.5208C59.3767 43.5208 61.2037 44.6092 61.7725 46.0617V43.8534H65.3673V59.0979H62.1324Z"
      fill="#2E4349"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.8088 59.0984H71.4032V38.0162H67.8088V59.0984Z"
      fill="#2E4349"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M75.1304 47.1343H73.2466V43.8432H75.1304V38.0162H78.7188V43.8432H82.0383V47.1343H78.7188V53.9279C78.7188 55.5889 79.2872 56.1926 80.6026 56.1926C81.0513 56.1926 81.4699 56.1623 81.8585 56.0723V59.3027C81.3498 59.4231 80.7223 59.4838 80.0047 59.4838C76.7453 59.4838 75.1304 57.7626 75.1304 54.3208V47.1343Z"
      fill="#2E4349"
    />
  </svg>
);

export const EdenLogo: React.FC = () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.5009 25.4253C34.5009 23.9664 33.875 22.7031 32.6714 21.6819C31.4685 20.6128 30.0719 20.0783 28.3871 20.0783C26.6059 20.0783 25.0184 20.6128 23.7183 21.6332C22.4189 22.6543 21.6972 23.9176 21.5524 25.4253H34.5009ZM15.68 28.0492C15.68 24.1611 16.8836 21.0505 19.241 18.6686C21.6482 16.2867 24.6813 15.1201 28.3871 15.1201C31.9014 15.1201 34.789 16.2867 37.0039 18.6686C39.2663 21.0505 40.3726 23.9662 40.3726 27.4659C40.3726 28.1947 40.3251 28.8754 40.2761 29.5075H21.4076C21.6 33.3956 24.44 35.9229 28.7235 35.9229C31.2272 35.9229 33.3938 34.9992 35.2709 33.1039L38.7361 36.5062C35.9926 39.4226 32.6224 40.8801 28.5319 40.8801C24.7296 40.8801 21.6 39.7135 19.241 37.4291C16.8836 35.1447 15.68 32.0828 15.68 28.2434V28.0492Z"
      fill="#6ED69A"
    />
  </svg>
);

export const EdenMobileLogo: React.FC = () => (
  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6437 8.16097C13.6437 7.11884 13.1966 6.21649 12.3369 5.48711C11.4778 4.72345 10.4801 4.34163 9.27677 4.34163C8.00444 4.34163 6.87053 4.72345 5.94188 5.4523C5.01375 6.18168 4.49824 7.08403 4.39482 8.16097H13.6437ZM0.200195 10.0351C0.200195 7.25791 1.0599 5.03603 2.74379 3.33467C4.46321 1.63332 6.62972 0.800049 9.27674 0.800049C11.7869 0.800049 13.8495 1.63332 15.4315 3.33467C17.0475 5.03603 17.8378 7.11867 17.8378 9.61849C17.8378 10.139 17.8038 10.6253 17.7688 11.0767H4.29137C4.42874 13.8539 6.45735 15.6592 9.51699 15.6592C11.3054 15.6592 12.8529 14.9994 14.1937 13.6456L16.6689 16.0758C14.7092 18.159 12.3019 19.2 9.38016 19.2C6.66419 19.2 4.42874 18.3668 2.74379 16.735C1.0599 15.1033 0.200195 12.9162 0.200195 10.1738V10.0351Z"
      fill="#6ED69A"
    />
  </svg>
);

export const EdenHorizontalLogo: React.FC = () => (
  <svg width="192" height="56" viewBox="0 0 192 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.9985 29.1247C28.9985 28.1866 28.5963 27.3744 27.823 26.7178C27.0501 26.0305 26.1528 25.6868 25.0703 25.6868C23.9258 25.6868 22.9058 26.0305 22.0705 26.6865C21.2356 27.343 20.7719 28.1553 20.6788 29.1247H28.9985ZM16.9058 30.8118C16.9058 28.3119 17.6791 26.3119 19.1938 24.7805C20.7405 23.2491 22.6893 22.499 25.0704 22.499C27.3284 22.499 29.1837 23.2491 30.6068 24.7805C32.0605 26.3119 32.7713 28.1866 32.7713 30.4367C32.7713 30.9053 32.7408 31.343 32.7093 31.7493H20.5859C20.7095 34.2492 22.5343 35.8741 25.2865 35.8741C26.8952 35.8741 28.2873 35.2802 29.4933 34.0617L31.7198 36.2491C29.957 38.1243 27.7916 39.0614 25.1634 39.0614C22.7203 39.0614 20.7095 38.3113 19.1938 36.8425C17.6791 35.3738 16.9058 33.4051 16.9058 30.9366V30.8118Z"
      fill="#6ED69A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.1068 34.2491C47.0347 33.3429 47.4984 32.1865 47.4984 30.8117C47.4984 29.4365 47.0347 28.2805 46.1068 27.3743C45.2099 26.4676 44.0659 25.9995 42.7358 25.9995C41.4057 25.9995 40.3232 26.4676 39.4263 27.3743C38.5604 28.2805 38.1272 29.4365 38.1272 30.8117C38.1272 32.1865 38.5604 33.3429 39.4263 34.2491C40.3232 35.1549 41.4057 35.6239 42.7358 35.6239C44.0659 35.6239 45.2099 35.1549 46.1068 34.2491ZM47.2199 25.2183V16.9055H50.9316V33.9364C50.9316 35.2803 51.2407 35.7802 52.0761 35.7802C52.2922 35.7802 52.5708 35.7493 52.8494 35.718V38.7491C52.4472 38.9052 51.8285 38.9992 51.0246 38.9992C50.1277 38.9992 49.4159 38.8431 48.8592 38.4994C48.1788 38.0303 47.7461 37.4051 47.5911 36.5929C46.5091 38.2492 44.7153 39.0614 42.2407 39.0614C40.0448 39.0614 38.1894 38.2805 36.6738 36.6864C35.1896 35.0928 34.4473 33.1555 34.4473 30.8118C34.4473 28.4682 35.1896 26.5308 36.6738 24.9681C38.1894 23.3745 40.0448 22.5931 42.2407 22.5931C44.7458 22.5931 46.6322 23.7182 47.2199 25.2183Z"
      fill="#6ED69A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M65.5715 29.1248C65.5715 28.1867 65.1693 27.3745 64.396 26.718C63.6232 26.0306 62.7258 25.6869 61.6433 25.6869C60.4993 25.6869 59.4788 26.0306 58.6435 26.6866C57.8086 27.3432 57.3449 28.1554 57.2519 29.1248H65.5715ZM53.4785 30.8118C53.4785 28.3119 54.2519 26.3119 55.767 24.7805C57.3132 23.2491 59.2621 22.499 61.6431 22.499C63.9011 22.499 65.7569 23.2491 67.1796 24.7805C68.6332 26.3119 69.3445 28.1866 69.3445 30.4367C69.3445 30.9053 69.3135 31.343 69.2825 31.7493H57.1591C57.2827 34.2492 59.107 35.8741 61.8597 35.8741C63.4679 35.8741 64.86 35.2802 66.0661 34.0617L68.2926 36.2491C66.5303 38.1243 64.3648 39.0614 61.7362 39.0614C59.2931 39.0614 57.2827 38.3113 55.767 36.8425C54.2519 35.3738 53.4785 33.4051 53.4785 30.9366V30.8118Z"
      fill="#6ED69A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M87.461 35.5617C86.6257 35.5617 86.4091 35.124 86.4091 33.9054V28.9366C86.4091 25.0306 84.2751 22.6243 80.595 22.6243C78.4033 22.6243 76.6744 23.6515 75.9292 25.1776C75.7966 24.361 75.4598 23.6539 74.6502 23.1357C74.0987 22.7829 73.3812 22.6358 72.4848 22.6358C71.6804 22.6358 70.721 22.8532 70.3193 23.0094V26.0405C70.5975 26.0091 70.8761 25.9783 71.0927 25.9783C71.9275 25.9783 72.2443 26.4782 72.2443 27.8221V38.6867H75.9245V29.6244C75.9245 27.4683 77.3781 25.9677 79.4505 25.9677C81.5224 25.9677 82.7289 27.3116 82.7289 29.4678V34.7176C82.7289 37.4677 83.9345 38.8429 86.3786 38.8429C87.0274 38.8429 87.5536 38.7802 87.9863 38.6549V35.499C87.8317 35.5303 87.6461 35.5617 87.461 35.5617Z"
      fill="#6ED69A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M101.224 38.6957V29.4767C101.224 27.3205 100.08 25.9766 98.1 25.9766C96.0901 25.9766 94.6674 27.4767 94.6674 29.6329V38.6957H90.9873V16.9148H94.6674V25.1331C95.3788 23.633 97.08 22.6332 99.2135 22.6332C102.802 22.6332 104.904 25.0395 104.904 28.946V38.6957H101.224Z"
      fill="#2E4349"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M170.659 38.6957V29.4767C170.659 27.3205 169.515 25.9766 167.536 25.9766C165.525 25.9766 164.102 27.4767 164.102 29.6329V38.6957H160.422V16.9148H164.102V25.1331C164.814 23.633 166.515 22.6332 168.649 22.6332C172.237 22.6332 174.34 25.0395 174.34 28.946V38.6957H170.659Z"
      fill="#2E4349"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M119.166 29.1331C119.166 28.1955 118.764 27.3833 117.991 26.7267C117.217 26.0393 116.32 25.6956 115.238 25.6956C114.094 25.6956 113.073 26.0393 112.238 26.6959C111.403 27.3519 110.939 28.1642 110.846 29.1331H119.166ZM107.073 30.8201C107.073 28.3207 107.846 26.3207 109.362 24.7888C110.908 23.2579 112.857 22.5083 115.238 22.5083C117.496 22.5083 119.351 23.2579 120.774 24.7888C122.228 26.3207 122.939 28.1954 122.939 30.4455C122.939 30.9141 122.908 31.3518 122.877 31.7576H110.753C110.877 34.2575 112.702 35.8829 115.454 35.8829C117.063 35.8829 118.454 35.2895 119.661 34.07L121.888 36.2579C120.124 38.1326 117.959 39.0702 115.331 39.0702C112.887 39.0702 110.877 38.3206 109.362 36.8518C107.846 35.383 107.073 33.4139 107.073 30.9454V30.8201Z"
      fill="#2E4349"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M136.468 34.2573C137.396 33.3515 137.859 32.1951 137.859 30.8199C137.859 29.4456 137.396 28.2892 136.468 27.3829C135.571 26.4762 134.426 26.0082 133.097 26.0082C131.767 26.0082 130.684 26.4762 129.787 27.3829C128.922 28.2892 128.489 29.4456 128.489 30.8199C128.489 32.1951 128.922 33.3515 129.787 34.2573C130.684 35.164 131.767 35.6325 133.097 35.6325C134.426 35.6325 135.571 35.164 136.468 34.2573ZM137.952 38.6956V36.6011C136.87 38.2579 135.076 39.0701 132.602 39.0701C130.406 39.0701 128.55 38.2888 127.035 36.6951C125.55 35.1015 124.808 33.1637 124.808 30.8201C124.808 28.4764 125.55 26.5391 127.035 24.9768C128.55 23.3832 130.406 22.6018 132.602 22.6018C135.107 22.6018 136.993 23.7264 137.581 25.227V22.9455H141.292V38.6956H137.952Z"
      fill="#2E4349"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M143.813 38.6962H147.525V16.9148H143.813V38.6962Z"
      fill="#2E4349"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M151.373 26.3353H149.428V22.935H151.373V16.9148H155.078V22.935H158.505V26.3353H155.078V33.3542C155.078 35.0703 155.664 35.694 157.023 35.694C157.486 35.694 157.918 35.6627 158.319 35.5697V38.9073C157.794 39.0316 157.146 39.0943 156.405 39.0943C153.04 39.0943 151.373 37.3161 151.373 33.7601V26.3353Z"
      fill="#2E4349"
    />
  </svg>
);

export const Info: React.FC<SVGProps> = ({ color, height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 11C12.5523 11 13 11.4477 13 12V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V12C11 11.4477 11.4477 11 12 11Z"
      fill={color}
    />
    <path
      d="M13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8Z"
      fill={color}
    />
  </svg>
);

export const SpinnerMedium: React.FC<SVGProps> = ({ color, height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const Users: React.FC<SVGProps> = ({ color, height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.46447 15.4645C2.40215 14.5268 3.67392 14 5 14H13C14.3261 14 15.5979 14.5268 16.5355 15.4645C17.4732 16.4021 18 17.6739 18 19V21C18 21.5523 17.5523 22 17 22C16.4477 22 16 21.5523 16 21V19C16 18.2044 15.6839 17.4413 15.1213 16.8787C14.5587 16.3161 13.7956 16 13 16H5C4.20435 16 3.44129 16.3161 2.87868 16.8787C2.31607 17.4413 2 18.2044 2 19V21C2 21.5523 1.55228 22 1 22C0.447715 22 0 21.5523 0 21V19C0 17.6739 0.526784 16.4021 1.46447 15.4645Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 4C7.34315 4 6 5.34315 6 7C6 8.65685 7.34315 10 9 10C10.6569 10 12 8.65685 12 7C12 5.34315 10.6569 4 9 4ZM4 7C4 4.23858 6.23858 2 9 2C11.7614 2 14 4.23858 14 7C14 9.76142 11.7614 12 9 12C6.23858 12 4 9.76142 4 7Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0318 14.8799C19.1698 14.3451 19.7153 14.0236 20.25 14.1616C21.3227 14.4386 22.273 15.064 22.9517 15.9395C23.6304 16.8151 23.9992 17.8913 24 18.9991L24 20.9999C24 21.5522 23.5523 21.9999 23 21.9999C22.4477 21.9999 22 21.5522 22 20.9999L22 19.0006C22 19.0005 22 19.0007 22 19.0006C21.9994 18.336 21.7782 17.6901 21.371 17.1649C20.9638 16.6395 20.3936 16.2643 19.75 16.0981C19.2153 15.9601 18.8937 15.4146 19.0318 14.8799Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0312 2.88184C15.1682 2.34681 15.713 2.02414 16.248 2.16113C17.3236 2.43651 18.2768 3.06201 18.9576 3.93902C19.6383 4.81603 20.0078 5.89467 20.0078 7.00488C20.0078 8.11509 19.6383 9.19373 18.9576 10.0707C18.2768 10.9478 17.3236 11.5733 16.248 11.8486C15.713 11.9856 15.1682 11.6629 15.0312 11.1279C14.8943 10.5929 15.2169 10.0481 15.752 9.91113C16.3973 9.7459 16.9692 9.3706 17.3777 8.8444C17.7861 8.31819 18.0078 7.67101 18.0078 7.00488C18.0078 6.33875 17.7861 5.69157 17.3777 5.16536C16.9692 4.63915 16.3973 4.26385 15.752 4.09863C15.2169 3.96164 14.8943 3.41687 15.0312 2.88184Z"
      fill={color}
    />
  </svg>
);

export const SortUp2: React.FC<SVGProps> = ({ color, height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8983 16.4394C19.7305 16.7824 19.3819 17 19 17L4.99999 17C4.61806 17 4.26948 16.7824 4.10168 16.4394C3.93387 16.0963 3.97615 15.6875 4.21064 15.3861L11.2106 6.38606C11.4001 6.14247 11.6914 6 12 6C12.3086 6 12.5999 6.14247 12.7893 6.38606L19.7893 15.3861C20.0238 15.6875 20.0661 16.0963 19.8983 16.4394ZM12 8.62882L7.04463 15L16.9554 15L12 8.62882Z"
      fill={color}
    />
    <path d="M7.04463 15L12 8.62882L16.9554 15L7.04463 15Z" fill={color} />
  </svg>
);

export const SortDown2: React.FC<SVGProps> = ({ color, height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.10169 7.56065C4.2695 7.21755 4.61807 7 5.00001 7L19 7C19.3819 7 19.7305 7.21755 19.8983 7.56065C20.0661 7.90374 20.0238 8.31246 19.7894 8.61394L12.7894 17.6139C12.5999 17.8575 12.3086 18 12 18C11.6914 18 11.4001 17.8575 11.2107 17.6139L4.21066 8.61394C3.97617 8.31246 3.93389 7.90375 4.10169 7.56065ZM12 15.3712L16.9554 9L7.04465 9L12 15.3712Z"
      fill={color}
    />
    <path d="M16.9554 9L12 15.3712L7.04465 9L16.9554 9Z" fill={color} />
  </svg>
);

export const XIcon: React.FC<SVGProps> = ({ color, height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
      fill={color}
    />
  </svg>
);

export const ChevronRight: React.FC<SVGProps> = ({ color, height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.29289 5.29289C9.68342 4.90237 10.3166 4.90237 10.7071 5.29289L16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L10.7071 18.7071C10.3166 19.0976 9.68342 19.0976 9.29289 18.7071C8.90237 18.3166 8.90237 17.6834 9.29289 17.2929L14.5858 12L9.29289 6.70711C8.90237 6.31658 8.90237 5.68342 9.29289 5.29289Z"
      fill={color}
    />
  </svg>
);

export const ChevronLeft: React.FC<SVGProps> = ({ color, height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7071 5.29289C15.0976 5.68342 15.0976 6.31658 14.7071 6.70711L9.41421 12L14.7071 17.2929C15.0976 17.6834 15.0976 18.3166 14.7071 18.7071C14.3166 19.0976 13.6834 19.0976 13.2929 18.7071L7.29289 12.7071C6.90237 12.3166 6.90237 11.6834 7.29289 11.2929L13.2929 5.29289C13.6834 4.90237 14.3166 4.90237 14.7071 5.29289Z"
      fill={color}
    />
  </svg>
);

export const ChevronDown: React.FC<SVGProps> = ({ color, height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.29289 9.29289C5.68342 8.90237 6.31658 8.90237 6.70711 9.29289L12 14.5858L17.2929 9.29289C17.6834 8.90237 18.3166 8.90237 18.7071 9.29289C19.0976 9.68342 19.0976 10.3166 18.7071 10.7071L12.7071 16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071L5.29289 10.7071C4.90237 10.3166 4.90237 9.68342 5.29289 9.29289Z"
      fill={color}
    />
  </svg>
);

export const Flag: React.FC<SVGProps> = ({ width, height, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4.22975V12.1465C5.5829 12.0549 6.24675 12 7 12C8.88675 12 10.4411 12.6221 11.643 13.1031C11.6578 13.109 11.6725 13.1149 11.6872 13.1208L11.7428 13.143C13.0305 13.6582 13.9393 14 15 14C15.9292 14 16.5766 13.8875 17 13.7702V5.85354C16.4171 5.94506 15.7533 6 15 6C13.1132 6 11.5589 5.3779 10.357 4.89688C10.3422 4.89096 10.3275 4.88507 10.3128 4.8792L10.2572 4.85695C8.96943 4.34184 8.06075 4 7 4C6.07082 4 5.42339 4.11249 5 4.22975ZM3.875 2.5C4.49998 2.25001 5.49997 2 7 2C8.5 2 9.75054 2.50022 10.9984 2.99936L11.0558 3.02233C12.2859 3.51452 13.5231 4 15 4C16.202 4 17.0826 3.83942 17.7056 3.6475C18.009 3.55407 18.3386 3.61019 18.594 3.79872C18.8493 3.98725 19 4.2858 19 4.6032V14.4619C19 14.8419 18.7846 15.1891 18.4442 15.3579C18.3508 15.4042 18.2447 15.4521 18.125 15.5C17.5 15.75 16.5 16 15 16C13.5 16 12.2495 15.4998 11.0016 15.0006L10.9442 14.9777C9.71415 14.4855 8.47695 14 7 14C5.79796 14 4.91744 14.1606 4.29437 14.3525C3.99103 14.4459 3.66135 14.3898 3.40602 14.2013C3.15068 14.0128 3 13.7142 3 13.3968V3.53805C3 3.15805 3.21538 2.81089 3.55583 2.64211C3.64918 2.59583 3.75528 2.54789 3.875 2.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 10C4.55228 10 5 10.4477 5 11V22C5 22.5523 4.55228 23 4 23C3.44772 23 3 22.5523 3 22V11C3 10.4477 3.44772 10 4 10Z"
        fill={color}
      />
    </svg>
  );
};

type CalendarProps = { date: Date; color?: 'ocean' | 'eden' | 'lemon' } & HTMLProps<HTMLDivElement>;

export const Calendar: React.FC<CalendarProps> = ({ date, color, ...props }) => {
  return (
    <div className={css.calendarOuter} {...props}>
      <div className={css[`${color || 'eden'}CalendarHeader`]}>
        <ListKicker style={{ color: CoreColors.White }}>
          {DateTime.fromJSDate(date).toFormat('MMM')}
        </ListKicker>
      </div>
      <div className={css.calendarFooter}>{DateTime.fromJSDate(date).toFormat('dd')}</div>
    </div>
  );
};

export const DownloadCloud: React.FC<SVGProps> = ({ color, height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29289 16.2929C7.68342 15.9024 8.31658 15.9024 8.70711 16.2929L12 19.5858L15.2929 16.2929C15.6834 15.9024 16.3166 15.9024 16.7071 16.2929C17.0976 16.6834 17.0976 17.3166 16.7071 17.7071L12.7071 21.7071C12.3166 22.0976 11.6834 22.0976 11.2929 21.7071L7.29289 17.7071C6.90237 17.3166 6.90237 16.6834 7.29289 16.2929Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 11C12.5523 11 13 11.4477 13 12V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V12C11 11.4477 11.4477 11 12 11Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.66758 1.9941C10.0281 1.94517 11.3819 2.20552 12.6272 2.75554C13.8725 3.30556 14.9768 4.13092 15.857 5.16948C16.5675 6.00784 17.117 6.96749 17.4804 7.99993L17.9992 7.99993C19.2743 7.9991 20.5174 8.40453 21.5465 9.15741C22.5759 9.91044 23.3385 10.9719 23.7237 12.1877C24.1089 13.4036 24.0967 14.7105 23.6888 15.9189C23.2808 17.1273 22.4985 18.1743 21.4552 18.9079C21.0034 19.2256 20.3797 19.1169 20.062 18.6652C19.7443 18.2134 19.853 17.5896 20.3048 17.2719C21.0003 16.7828 21.5219 16.0849 21.7938 15.2793C22.0657 14.4736 22.0739 13.6023 21.8171 12.7918C21.5603 11.9812 21.0519 11.2736 20.3656 10.7716C19.6794 10.2696 18.851 9.99927 18.0008 9.99993H16.74C16.2834 9.99993 15.8848 9.69071 15.7714 9.24848C15.5082 8.22287 15.0158 7.27032 14.3312 6.46255C13.6467 5.65478 12.7878 5.01283 11.8192 4.58504C10.8506 4.15724 9.79761 3.95475 8.73946 3.9928C7.68131 4.03086 6.64557 4.30846 5.71023 4.80472C4.77489 5.30099 3.96432 6.00297 3.33954 6.85783C2.71476 7.71269 2.29204 8.69814 2.10323 9.74001C1.91441 10.7819 1.96441 11.853 2.24946 12.8727C2.5345 13.8925 3.04718 14.8343 3.74887 15.6272C4.11488 16.0408 4.07629 16.6728 3.6627 17.0388C3.24911 17.4048 2.61712 17.3662 2.25111 16.9526C1.34893 15.9331 0.689783 14.7223 0.323292 13.4112C-0.0431988 12.1001 -0.107481 10.7229 0.135283 9.38336C0.378048 8.04382 0.921534 6.7768 1.72483 5.6777C2.52812 4.57859 3.57028 3.67604 4.77286 3.03799C5.97544 2.39994 7.3071 2.04302 8.66758 1.9941Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width={width} height={height} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const RotateCW: React.FC<SVGProps> = ({ color, height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 3C23.5523 3 24 3.44772 24 4V10C24 10.5523 23.5523 11 23 11H17C16.4477 11 16 10.5523 16 10C16 9.44772 16.4477 9 17 9H22V4C22 3.44772 22.4477 3 23 3Z"
        fill="#2E4349"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1275 4.08125C11.4102 3.83765 9.66016 4.15882 8.14121 4.99636C6.62226 5.83389 5.41667 7.14241 4.70611 8.72474C3.99554 10.3071 3.8185 12.0775 4.20166 13.7692C4.58483 15.4609 5.50743 16.9822 6.83045 18.1039C8.15348 19.2257 9.80523 19.887 11.5368 19.9883C13.2684 20.0896 14.986 19.6254 16.4308 18.6656C17.8756 17.7058 18.9693 16.3024 19.5471 14.6669C19.7311 14.1462 20.3024 13.8732 20.8231 14.0571C21.3439 14.2411 21.6169 14.8124 21.4329 15.3331C20.7106 17.3775 19.3435 19.1317 17.5375 20.3315C15.7315 21.5312 13.5845 22.1115 11.42 21.9849C9.25554 21.8583 7.19084 21.0316 5.53706 19.6294C3.88328 18.2273 2.73003 16.3256 2.25107 14.211C1.77212 12.0963 1.99342 9.88334 2.88162 7.90543C3.76983 5.92752 5.27682 4.29187 7.1755 3.24495C9.07419 2.19804 11.2617 1.79658 13.4084 2.10107C15.55 2.40483 17.5352 3.39476 19.0663 4.92215L23.6856 9.272C24.0876 9.65063 24.1066 10.2835 23.728 10.6856C23.3494 11.0876 22.7165 11.1067 22.3144 10.728L17.6844 6.36803C17.6771 6.3611 17.6698 6.35405 17.6627 6.3469C16.4366 5.12 14.8449 4.32484 13.1275 4.08125Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const Check: React.FC<SVGProps> = ({ color, height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 16 16`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8.00008" cy="8" r="7.33333" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3861 5.78056C11.538 5.93243 11.538 6.17866 11.3861 6.33053L7.10832 10.6083C6.95645 10.7602 6.71022 10.7602 6.55835 10.6083L4.6139 8.66387C4.46203 8.51199 4.46203 8.26576 4.6139 8.11389C4.76577 7.96202 5.012 7.96202 5.16387 8.11389L6.83333 9.78335L10.8361 5.78056C10.988 5.62869 11.2342 5.62869 11.3861 5.78056Z"
      fill="white"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Briefcase: React.FC<SVGProps> = ({ color, height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fillRule="evenodd"
    clipRule="evenodd"
    d="M4 8C3.44772 8 3 8.44772 3 9V19C3 19.5523 3.44772 20 4 20H20C20.5523 20 21 19.5523 21 19V9C21 8.44772 20.5523 8 20 8H4ZM1 9C1 7.34315 2.34315 6 4 6H20C21.6569 6 23 7.34315 23 9V19C23 20.6569 21.6569 22 20 22H4C2.34315 22 1 20.6569 1 19V9Z"
    fill={color}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 8C3.44772 8 3 8.44772 3 9V19C3 19.5523 3.44772 20 4 20H20C20.5523 20 21 19.5523 21 19V9C21 8.44772 20.5523 8 20 8H4ZM1 9C1 7.34315 2.34315 6 4 6H20C21.6569 6 23 7.34315 23 9V19C23 20.6569 21.6569 22 20 22H4C2.34315 22 1 20.6569 1 19V9Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.87868 2.87868C8.44129 2.31607 9.20435 2 10 2H14C14.7956 2 15.5587 2.31607 16.1213 2.87868C16.6839 3.44129 17 4.20435 17 5V21C17 21.5523 16.5523 22 16 22C15.4477 22 15 21.5523 15 21V5C15 4.73478 14.8946 4.48043 14.7071 4.29289C14.5196 4.10536 14.2652 4 14 4H10C9.73478 4 9.48043 4.10536 9.29289 4.29289C9.10536 4.48043 9 4.73478 9 5V21C9 21.5523 8.55228 22 8 22C7.44772 22 7 21.5523 7 21V5C7 4.20435 7.31607 3.44129 7.87868 2.87868Z"
      fill={color}
    />
  </svg>
);

export interface HourglassProps extends SVGProps {
  glassColor: Colors;
}

export const Hourglass: React.FC<HourglassProps> = ({ color, glassColor, height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 120 120 `}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M62.8452 70.8763C74.6365 81.4542 81.5841 91.6288 82.4279 106.493H26.9563C27.8001 91.629 34.7478 81.4542 46.539 70.8763L51.4047 66.5114L46.539 62.1564C34.7478 51.5785 27.8001 41.4039 26.9563 26.5398H82.4279C81.5841 41.4039 74.6365 51.5787 62.8452 62.1564L57.9797 66.5114L62.8452 70.8763Z"
      fill={glassColor}
    />
    <path
      d="M26.4238 29.6496H82.9623C84.8648 29.6496 86.4069 28.266 86.4069 26.5595C86.4069 24.8529 84.8648 23.4695 82.9623 23.4695H26.4238C24.5213 23.4695 22.9792 24.8531 22.9792 26.5596C22.9792 28.2662 24.5213 29.6496 26.4238 29.6496Z"
      fill={color}
    />
    <path
      d="M43.2463 88.6667C36.1577 92.3401 31.7915 99.1262 31.7915 106.47H77.5939C77.5939 99.126 73.2274 92.3401 66.1389 88.6667L56.6418 83.7453C56.6418 71.8169 56.6418 68.8313 56.6418 56.9029L63.3556 53.4239C67.9486 51.0438 71.0312 46.937 71.8232 42.3168C72.0357 41.0766 70.9823 39.9502 69.5835 39.9502H39.8021C38.4033 39.9502 37.3497 41.0764 37.5624 42.3168C38.3544 46.9368 41.4374 51.0438 46.0302 53.4239L53.2097 57.1443C53.2097 68.8582 53.2097 71.7898 53.2095 83.5039L43.2463 88.6667Z"
      fill={color}
    />
    <path
      d="M82.9618 103.38H26.4234C24.5208 103.38 22.9788 104.763 22.9788 106.47C22.9788 108.176 24.5208 109.56 26.4234 109.56H82.9618C84.8643 109.56 86.4064 108.176 86.4064 106.47C86.4064 104.763 84.8643 103.38 82.9618 103.38Z"
      fill={color}
    />
    <path
      d="M37.0693 93.9764C38.5107 90.4062 40.5909 87.0515 43.5442 83.7327"
      stroke="white"
      strokeWidth="3.97341"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M50.4912 76.4482C49.8219 77.0132 49.1619 77.6 48.5098 78.2085"
      stroke="white"
      strokeWidth="3.97341"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M92.2051 18.6921L96.4217 15.4847"
      stroke={color}
      strokeWidth="5.29788"
      strokeLinecap="round"
    />
    <path
      d="M93.7134 27.0037L97.5885 27.8821"
      stroke={color}
      strokeWidth="5.29788"
      strokeLinecap="round"
    />
    <path
      d="M83.8623 15.4465L83.5019 11.4895"
      stroke={color}
      strokeWidth="5.29788"
      strokeLinecap="round"
    />
  </svg>
);

export const Mail: React.FC<SVGProps> = ({ color, height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 5C3.45228 5 3 5.45228 3 6V18C3 18.5477 3.45228 19 4 19H20C20.5477 19 21 18.5477 21 18V6C21 5.45228 20.5477 5 20 5H4ZM1 6C1 4.34772 2.34772 3 4 3H20C21.6523 3 23 4.34772 23 6V18C23 19.6523 21.6523 21 20 21H4C2.34772 21 1 19.6523 1 18V6Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.18079 5.42662C1.49751 4.97417 2.12104 4.86414 2.57349 5.18085L12 11.7794L21.4266 5.18085C21.879 4.86414 22.5025 4.97417 22.8193 5.42662C23.136 5.87907 23.0259 6.5026 22.5735 6.81932L12.5735 13.8193C12.2292 14.0603 11.7709 14.0603 11.4266 13.8193L1.42656 6.81932C0.974113 6.5026 0.864078 5.87907 1.18079 5.42662Z"
      fill={color}
    />
  </svg>
);

export const AlertCircle: React.FC<SVGProps> = ({ color, height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 23C5.9 23 1 18.1 1 12C1 5.9 5.9 1 12 1C18.1 1 23 5.9 23 12C23 18.1 18.1 23 12 23ZM12 3C7 3 3 7 3 12C3 17 7 21 12 21C17 21 21 17 21 12C21 7 17 3 12 3Z"
      fill={color}
    />
    <path
      d="M12 13C11.4 13 11 12.6 11 12V8C11 7.4 11.4 7 12 7C12.6 7 13 7.4 13 8V12C13 12.6 12.6 13 12 13Z"
      fill={color}
    />
    <path
      d="M12 17C11.7 17 11.5 16.9 11.3 16.7C11.1 16.5 11 16.3 11 16C11 15.9 11 15.7 11.1 15.6C11.2 15.5 11.2 15.4 11.3 15.3C11.6 15 12 14.9 12.4 15.1C12.5 15.1 12.5 15.1 12.6 15.2C12.6 15.2 12.7 15.3 12.8 15.3C12.9 15.4 13 15.5 13 15.6C13 15.7 13 15.9 13 16C13 16.1 13 16.3 12.9 16.4C12.8 16.5 12.8 16.6 12.7 16.7C12.5 16.9 12.3 17 12 17Z"
      fill={color}
    />
  </svg>
);

export const HealthCare: React.FC<SVGProps> = ({ color, height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.11111 2.22222C7.11111 1.54721 7.65832 1 8.33333 1H15.6667C16.3417 1 16.8889 1.54721 16.8889 2.22222V7.11111L21.7778 7.11111C22.4528 7.11111 23 7.65832 23 8.33333V15.6667C23 16.3417 22.4528 16.8889 21.7778 16.8889H16.8889V21.7778C16.8889 22.4528 16.3417 23 15.6667 23H8.33333C7.65832 23 7.11111 22.4528 7.11111 21.7778V16.8889H2.22222C1.54721 16.8889 1 16.3417 1 15.6667V8.33333C1 7.65832 1.54721 7.11111 2.22222 7.11111L7.11111 7.11111V2.22222ZM15 3V9H21V15L15 15L14.9444 21L9 21L9.05556 15H3V9H9V3L15 3Z"
      fill={color}
    />
  </svg>
);

export const Bookmark: React.FC<SVGProps> = ({ color, height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 4C6.73478 4 6.48043 4.10536 6.29289 4.29289C6.10536 4.48043 6 4.73478 6 5V19.0568L11.4188 15.1863C11.7665 14.9379 12.2335 14.9379 12.5812 15.1863L18 19.0568V5C18 4.73478 17.8946 4.48043 17.7071 4.29289C17.5196 4.10536 17.2652 4 17 4H7ZM4.87868 2.87868C5.44129 2.31607 6.20435 2 7 2H17C17.7956 2 18.5587 2.31607 19.1213 2.87868C19.6839 3.44129 20 4.20435 20 5V21C20 21.3746 19.7907 21.7178 19.4576 21.8892C19.1245 22.0606 18.7236 22.0315 18.4188 21.8137L12 17.2289L5.58124 21.8137C5.27642 22.0315 4.87549 22.0606 4.54242 21.8892C4.20935 21.7178 4 21.3746 4 21V5C4 4.20435 4.31607 3.44129 4.87868 2.87868Z"
      fill={color}
    />
  </svg>
);

export const Square: React.FC<SVGProps> = ({ color, height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 4C4.44772 4 4 4.44772 4 5V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V5C20 4.44772 19.5523 4 19 4H5ZM2 5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V5Z"
      fill={color}
    />
  </svg>
);

export const SquareChecked: React.FC<SVGProps> = ({ color = CoreColors.Eden, height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 4C4.44772 4 4 4.44772 4 5V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V5C20 4.44772 19.5523 4 19 4H5ZM2 5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V5Z"
      fill={color}
    />
    <path
      d="M4 5C4 4.44772 4.44772 4 5 4H19C19.5523 4 20 4.44772 20 5V19C20 19.5523 19.5523 20 19 20H5C4.44772 20 4 19.5523 4 19V5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7071 7.29289C18.0976 7.68342 18.0976 8.31658 17.7071 8.70711L10.7071 15.7071C10.3166 16.0976 9.68342 16.0976 9.29289 15.7071L6.29289 12.7071C5.90237 12.3166 5.90237 11.6834 6.29289 11.2929C6.68342 10.9024 7.31658 10.9024 7.70711 11.2929L10 13.5858L16.2929 7.29289C16.6834 6.90237 17.3166 6.90237 17.7071 7.29289Z"
      fill="white"
    />
  </svg>
);

export const Magnifier: React.FC<SVGProps> = ({ color, height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.33337 2.66667C4.75605 2.66667 2.66671 4.756 2.66671 7.33333C2.66671 9.91066 4.75605 12 7.33337 12C9.9107 12 12 9.91066 12 7.33333C12 4.756 9.9107 2.66667 7.33337 2.66667ZM1.33337 7.33333C1.33337 4.01962 4.01967 1.33333 7.33337 1.33333C10.6471 1.33333 13.3334 4.01962 13.3334 7.33333C13.3334 10.647 10.6471 13.3333 7.33337 13.3333C4.01967 13.3333 1.33337 10.647 1.33337 7.33333Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6286 10.6286C10.889 10.3682 11.3111 10.3682 11.5714 10.6286L14.4714 13.5286C14.7318 13.7889 14.7318 14.2111 14.4714 14.4714C14.2111 14.7318 13.789 14.7318 13.5286 14.4714L10.6286 11.5714C10.3683 11.3111 10.3683 10.8889 10.6286 10.6286Z"
      fill={color}
    />
  </svg>
);

export const Download: React.FC<SVGProps> = ({
  color = CoreColors.Slate85,
  width = 16,
  height = 16,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0003 9.33334C14.3685 9.33334 14.667 9.63182 14.667 10L14.667 12.6667C14.667 13.1971 14.4563 13.7058 14.0812 14.0809C13.7061 14.456 13.1974 14.6667 12.667 14.6667L3.33366 14.6667C2.80322 14.6667 2.29452 14.456 1.91944 14.0809C1.54437 13.7058 1.33366 13.1971 1.33366 12.6667L1.33366 10C1.33366 9.63182 1.63214 9.33334 2.00033 9.33334C2.36852 9.33334 2.66699 9.63182 2.66699 10L2.66699 12.6667C2.66699 12.8435 2.73723 13.0131 2.86225 13.1381C2.98728 13.2631 3.15685 13.3333 3.33366 13.3333L12.667 13.3333C12.8438 13.3333 13.0134 13.2631 13.1384 13.1381C13.2634 13.0131 13.3337 12.8435 13.3337 12.6667L13.3337 10C13.3337 9.63182 13.6321 9.33334 14.0003 9.33334Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8047 6.19526C12.0651 6.45561 12.0651 6.87772 11.8047 7.13807L8.4714 10.4714C8.21105 10.7318 7.78894 10.7318 7.5286 10.4714L4.19526 7.13807C3.93491 6.87772 3.93491 6.45561 4.19526 6.19526C4.45561 5.93491 4.87772 5.93491 5.13807 6.19526L8 9.05719L10.8619 6.19526C11.1223 5.93491 11.5444 5.93491 11.8047 6.19526Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00033 1.33334C8.36852 1.33334 8.66699 1.63182 8.66699 2.00001L8.66699 10C8.66699 10.3682 8.36851 10.6667 8.00033 10.6667C7.63214 10.6667 7.33366 10.3682 7.33366 10L7.33366 2.00001C7.33366 1.63182 7.63214 1.33334 8.00033 1.33334Z"
      fill={color}
    />
  </svg>
);
