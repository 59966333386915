import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import css from './BackButton.module.css';
import { ChevronLeft } from 'components/design-system/Icons';
import { BodyNormal } from 'components/design-system/Text';
import { CoreColors } from 'types/colors';

export const BackButton: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
  const left = isMobile ? 16 : 102;
  const top = isMobile ? 56 : 92;
  const { url } = useRouteMatch();
  return (
    <Link to={url} replace>
      <div className={css.backButton} style={{ left: left, top: top }}>
        <ChevronLeft color={CoreColors.SlateDarken20} height={24} width={24} />
        <BodyNormal style={{ color: CoreColors.SlateDarken20 }}>Back</BodyNormal>
      </div>
    </Link>
  );
};
