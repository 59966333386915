import React from 'react';
import { Modal, ModalProps } from 'components/design-system/Modal';
import css from 'components/features/ResetPassword/TimedOut.module.css';
import { BodyNormal, HeaderMedium } from 'components/design-system/Text';
import { PrimaryButton } from 'components/design-system/Form';
import { Hourglass } from 'components/design-system/Icons';
import { CoreColors } from 'types/colors';

export interface TimedOutProps extends ModalProps {}

export const TimedOut: React.FC<TimedOutProps> = (props) => {
  const newProps = {
    ...props,
    className: css.content,
    overlayClassName: css.overlay,
  };
  return (
    <Modal {...newProps}>
      <div className={css.container}>
        <div style={{ marginBottom: 24 }}>
          <Hourglass
            height={56}
            width={56}
            color={CoreColors.Eden}
            glassColor={CoreColors.Apple40}
            style={{ marginBottom: 24 }}
          />
        </div>
        <HeaderMedium style={{ marginBottom: 16 }}>Your session has expired</HeaderMedium>
        <BodyNormal style={{ marginBottom: 40 }}>
          For your security, you session was expired due to inactivity. Please log in again to
          continue.
        </BodyNormal>
        <PrimaryButton
          type="button"
          value="Log in"
          onClick={() => {
            location.reload();
          }}
        />
      </div>
    </Modal>
  );
};
