import React, { useEffect, useState, useContext } from 'react';
import cx from 'classnames';
import { Sidebar } from '../Sidebar/Sidebar';
import { MemberList } from './MemberList';
import css from './MemberDashboard.module.css';
import { Flyout } from './Flyout';
import { WorkStatusEnum } from 'types/db/members';
import { useAppDispatch } from 'store';
import { fetchMembers } from 'redux/member';
import { fetchUnconsented } from 'redux/employee';
import { fetchUser } from 'redux/user';
import { isoDate } from 'lib/time';
import { captureException } from 'lib/sentry';
import { AuthContext } from 'lib/auth';

export interface CsvSettings {
  day: string;
  status: WorkStatusEnum | undefined;
  worksite: string;
  vaccinationStatus: string | undefined;
}

export interface DashboardState {
  selectedDate: Date;
  lastSelectedDate: Date | undefined;
  selectedDateLoadedAt: Date | undefined;
  csvSettings: CsvSettings;
  triageFilterStatus: WorkStatusEnum | undefined;
  vaccinationFilterStatus: string | undefined;
  loading: boolean;
  selectedMemberId: string;
  worksiteFilter: string;
  showUnconsented: boolean;
}

export const MemberDashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const { signOut } = useContext(AuthContext);

  const [state, setState] = useState<DashboardState>({
    selectedDate: new Date(),
    lastSelectedDate: undefined,
    selectedDateLoadedAt: undefined,
    loading: true,
    csvSettings: {
      day: isoDate(new Date()),
      status: undefined,
      worksite: '',
      vaccinationStatus: undefined,
    },
    triageFilterStatus: undefined,
    vaccinationFilterStatus: undefined,
    selectedMemberId: '',
    worksiteFilter: '',
    showUnconsented: false,
  });

  const {
    selectedDate,
    lastSelectedDate,
    loading,
    selectedMemberId,
    selectedDateLoadedAt,
    csvSettings,
    worksiteFilter,
    showUnconsented,
  } = state;

  useEffect(() => {
    const action = async () => {
      const fetchUserResponse = await dispatch(fetchUser({}));
      if (fetchUser.rejected.match(fetchUserResponse)) {
        throw fetchUserResponse.payload ?? 'Unknown fetchUser error';
      }
    };
    action().catch(captureException);
  }, [dispatch]);

  useEffect(() => {
    const action = async () => {
      if (loading || selectedDate !== lastSelectedDate) {
        const fetchMembersResponse = await dispatch(fetchMembers({ day: isoDate(selectedDate) }));
        if (fetchMembers.rejected.match(fetchMembersResponse)) {
          throw fetchMembersResponse.payload ?? 'Unknown fetchMembers error';
        }
        setState((state) => ({
          ...state,
          loading: false,
          selectedDateLoadedAt: new Date(),
          lastSelectedDate: selectedDate,
        }));
      }
    };
    action().catch(captureException);
  }, [dispatch, lastSelectedDate, selectedDate, loading]);

  useEffect(() => {
    const action = async () => {
      const fetchUnconsentedResponse = await dispatch(fetchUnconsented({}));
      if (fetchUnconsented.rejected.match(fetchUnconsentedResponse))
        throw fetchUnconsentedResponse.payload ?? 'Unknown fetchUnconsented error';
    };
    action().catch((err) => {
      signOut(dispatch);
      captureException(err);
      window.location.href = '/?error=true';
    });
  }, [dispatch, signOut]);

  return (
    <>
      {selectedMemberId && (
        <Flyout
          id={selectedMemberId}
          onClose={() => setState((state) => ({ ...state, selectedMemberId: '' }))}
        />
      )}
      <div className={cx(css.container, selectedMemberId && css.noscroll)}>
        <Sidebar
          loading={loading}
          selectedDateLoadedAt={selectedDateLoadedAt}
          reload={() => setState((state) => ({ ...state, loading: true }))}
        />
        <MemberList
          selectedDate={selectedDate}
          csvSettings={csvSettings}
          loading={loading}
          worksiteFilter={worksiteFilter}
          showUnconsented={showUnconsented}
          setState={setState}
        />
      </div>
    </>
  );
};
