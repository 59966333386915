import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';
import { Store } from '../store';
import { User } from '../types/db/user';
import GrdnApi from 'lib/grdn';
import { ErrorResponse, SuccessResponse, UserPayload } from 'types/grdn';

export const userAdapter = createEntityAdapter<User>({
  selectId: (user) => user.id,
});

export const userInitState = userAdapter.getInitialState();

export const fetchUser = createAsyncThunk<
  User,
  Record<string, any>,
  {
    rejectValue: ErrorResponse;
  }
>('user/fetchUser', async (_params, thunkAPI) => {
  try {
    const response = await GrdnApi.getUser();
    return (response as SuccessResponse<UserPayload>).data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const userSlice = createSlice({
  name: 'user',
  initialState: userInitState,
  reducers: {},
  extraReducers: {
    [fetchUser.fulfilled.toString()]: (state, action) => {
      userAdapter.upsertOne(state, action.payload);
    },
  },
});

export const userSelectors = userAdapter.getSelectors<Store>((state) => state.user);

const selectCurrentUser = (state: Store): User | undefined => userSelectors.selectAll(state)[0];

export const selectRestrictedStatus = createSelector([selectCurrentUser], (user) =>
  user?.restrictedDashboard !== undefined ? user.restrictedDashboard : true,
);

export const selectRestrictedVaxStatus = createSelector([selectCurrentUser], (user) =>
  user?.restrictedVaxStatus !== undefined ? user.restrictedVaxStatus : true,
);

export const selectVisitorCode = createSelector([selectCurrentUser], (user) => user?.visitorCode);

export default userSlice.reducer;
