import React, { ReactNode, useCallback, useState } from 'react';
import GrdnApi from '../../../lib/grdn';
import { captureException } from '../../../lib/sentry';
import { Member } from '../../../types/db/members';
import { DocumentType, isSuccessResponse } from '../../../types/grdn';
import { failure } from '../Toast';
import { Spinner } from '../Spinner';
import css from './DocumentDownloader.module.css';

interface DocumentDownloaderProps {
  children: {
    memberId: Member['id'];
    documentType: DocumentType;
    documentId: number;
    documentName?: string;
    messages?: {
      error?: ReactNode;
    };
    button: ReactNode;
  };
}

export const DocumentDownloader: React.FC<DocumentDownloaderProps> = ({
  children,
}: DocumentDownloaderProps) => {
  const { memberId, documentType, documentId, documentName, messages, button } = children;
  const [isLoading, setIsLoading] = useState(false);

  const fetchDocument = useCallback(async () => {
    setIsLoading(true);

    await GrdnApi.downloadDocument(memberId, documentType, documentId)
      .then((response) => {
        if (isSuccessResponse(response)) {
          const { contentType, payload } = response.data;

          if (!contentType) {
            throw new Error(`Missing content type.`);
          }

          if (!payload) {
            throw new Error(`Missing payload.`);
          }

          const link = document.createElement('a');

          link.download = documentName ? documentName : documentType;
          link.href = `data:${contentType};base64,${payload}`;
          link.click();

          link.remove();
        } else {
          throw new Error();
        }

        return;
      })
      .catch((e) => {
        if (messages?.error) {
          failure(messages.error);
        }

        captureException(e);
      });

    setIsLoading(false);
  }, [memberId, documentType, documentId, documentName, messages?.error]);

  return isLoading ? (
    <Spinner size={16} />
  ) : (
    <div
      className={css.button}
      onClick={async (e) => {
        e.stopPropagation();
        await fetchDocument();
      }}
    >
      {button}
    </div>
  );
};
