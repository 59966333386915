import { RefObject, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useOutsideAlerter = (ref: RefObject<HTMLDivElement>, action: () => void): void => {
  /**
   * Hook that alerts clicks outside of the passed ref
   */
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        action();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, action]);
};

export const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};
