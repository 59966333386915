export enum WorkStatusEnum {
  Cleared = 'cleared',
  Isolate = 'isolate',
  Incomplete = 'incomplete',
}

// We cannot use a real Typescript enum here, so I am using an
// object to just get a few enum-like properties.
// The real type of VaccinationStatusEnum is
// 'Unknown Vaccine',
// 'Not Vaccinated',
// '1st Dose Complete',
// '2nd Dose Complete',
// 'Primary Series Complete',
// 'Primary Series + n', (where n is any integer)
// Because TS cannot express infinite enums in its type system, we're forced to use
// strings to represent VaccinationStatus
export const VaccinationStatusEnum = {
  UnknownVaccine: 'Unknown Vaccine',
  NotVaccinated: 'Not Vaccinated',
  FirstDoseComplete: '1st Dose Complete',
  SecondDoseComplete: '2nd Dose Complete',
  PrimarySeriesComplete: 'Primary Series Complete',
  PrimarySeriesPlus: 'Primary Series',
  // Deprecated
  FullyVaccinated: 'Fully Vaccinated',
};

export type TestResult = 'pending' | 'positive' | 'negative' | 'inconclusive' | 'none';
export type TestType = 'pcr' | 'rapid_antigen' | 'rapid_molecular' | 'unknown';

export interface TestResultRecord {
  result: TestResult;
  type: TestType;
  dateTime: string;
  verified: boolean;
}

export interface CovidRegistry {
  triageStatus: WorkStatusEnum;
  isolateReasons: string;
  createdAt: string;
}

export enum ConsentStatusEnum {
  Granted = 'granted',
}

export interface TestingResult {
  type: TestType;
  result: TestResult;
  testedAt: string;
  verified: boolean;
}

export enum Manufacturer {
  Pfizer = 'PFR',
  Moderna = 'MOD',
  Johnson = 'JSN',
  Novavax = 'NVX',
  Unknown = 'UNK',
}

export interface VaccineDetails {
  athenaDocumentId: number;
  vaccinatedAt: string;
  manufacturer: Manufacturer;
  lotNumber: string;
  vaccineId: string;
  dose: string;
}

export interface Member {
  consentStatus: ConsentStatusEnum;
  covidRegistry?: CovidRegistry[];
  testingResults: TestingResult[];
  createdAt: string;
  data: Record<string, any>;
  dob: string;
  email: string;
  employeeId: string;
  firstName: string;
  id: string;
  ingestedVersion: string;
  isolateReasons: string;
  vaccinationStatus: string;
  lastConsentAt: string;
  lastName: string;
  lastScreenedAt: string;
  lastTriageAt?: string;
  mrn: number;
  newestVaccinatedAt?: string;
  patientCreatedAt: string;
  patientId: string;
  phone: string;
  triageStatus: WorkStatusEnum;
  updatedAt: string;
  worksite: string;
  returnToWorkDate: string | null;
  lastTest: TestResultRecord | null;
  sharedVaccinationCard: boolean;
  vaccineDetails: VaccineDetails[];
  athenaDocumentId?: number; // ID of the last vaccine received by the member.
}

export interface Employee {
  createdAt: string;
  dob: string;
  email: string;
  employeeId: string;
  firstName: string;
  id: string;
  lastName: string;
  phone: string;
  updatedAt: string;
  worksite: string;
}
