import React, { useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import css from './BeginScreenerPage.module.css';
import { LocationState, Page } from './VisitorForm';
import { Calendar } from 'components/design-system/Icons';
import { BodyNormal, HeaderLarge } from 'components/design-system/Text';
import { PrimaryButton } from 'components/design-system/Form';

export const BeginScreenerPage: React.FC<{
  sponsorName: string;
  clearFormState: () => void;
}> = ({ sponsorName, clearFormState }) => {
  const { url } = useRouteMatch();
  const nextLocationState: LocationState = {
    page: Page.OnsiteDetailsPage,
  };
  useEffect(() => {
    clearFormState();
  }, [clearFormState]);
  const isMobile = window.innerWidth <= 500;
  const textAlign = isMobile ? 'left' : 'center';
  const calAlign = isMobile ? 'flex-start' : 'center';
  return (
    <div className={css.container}>
      <Calendar style={{ marginBottom: 24, alignSelf: calAlign }} date={new Date()} color="ocean" />
      <HeaderLarge style={{ marginBottom: 16, textAlign: textAlign }}>
        {sponsorName}&apos;s COVID-19 screener
      </HeaderLarge>
      <BodyNormal style={{ marginBottom: 16, width: 350, textAlign: textAlign }}>
        Before arriving on-site at {sponsorName} today, you must complete the COVID-19 Daily
        Screener.
      </BodyNormal>
      <BodyNormal style={{ marginBottom: 64, width: 350, textAlign: textAlign }}>
        Your on-site status refreshes every calendar day. Please complete the screener on your day
        of visit, each day that you are expected on-site.
      </BodyNormal>
      <Link to={{ pathname: url, state: nextLocationState }}>
        <PrimaryButton style={{ width: 350, marginBottom: 16 }} value="Begin Screener" />
      </Link>
    </div>
  );
};
