import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { BeginScreenerPage } from './BeginScreenerPage';
import { OnsiteDetailsPage } from './OnsiteDetailsPage';
import { ScreenerPage } from './ScreenerPage';
import { VisitorReceiptPage } from './VisitorReceiptPage';
import css from './VisitorForm.module.css';
import { VaccinePage } from './VaccinePage';
import { EdenHorizontalLogo, EdenMobileLogo } from 'components/design-system/Icons';
import { CaptionNormal, HeaderSmall, Link } from 'components/design-system/Text';
import { FormState, SetQuestionFn } from 'types/screener';
import grdn from 'lib/grdn';
import { captureException } from 'lib/sentry';
import { ClientError } from 'lib/errors';
import {
  VisitorsScreenerPayload,
  VisitorsScreenerResponsePayload,
  isSuccessResponse,
} from 'types/grdn';
import { WorkStatusEnum } from 'types/db/members';

export enum Page {
  BeginScreenerPage,
  OnsiteDetailsPage,
  ScreenerPage,
  VisitorReceiptPage,
  VaccinePage,
}

export interface LocationState {
  page: Page;
}

interface VisitorFormState {
  loading: boolean;
  formState: FormState;
  sponsorName?: string;
  covidStatus: WorkStatusEnum;
  errorMessage?: string;
  vaccineRequired?: boolean;
}

const InitialFormState: FormState = {
  firstName: '',
  lastName: '',
  email: '',
  contactName: '',
  contactEmail: '',
  hostUnknown: false,
};

export const VisitorForm: React.FC = () => {
  const { employerCode } = useParams<{ employerCode: string }>();
  const { url } = useRouteMatch();
  const location = useLocation<LocationState>();
  const history = useHistory<LocationState>();
  const [
    { loading, sponsorName, formState, covidStatus, errorMessage, vaccineRequired },
    setState,
  ] = useState<VisitorFormState>({
    loading: false,
    formState: InitialFormState,
    covidStatus: WorkStatusEnum.Incomplete,
    errorMessage: undefined,
  });

  const setFormState = (fn: (state: FormState) => FormState): void =>
    setState((state) => ({
      ...state,
      formState: fn(state.formState),
    }));

  const post = async () => {
    try {
      const response = await grdn.postVisitorScreenerResponse(employerCode, formState);
      if (isSuccessResponse<VisitorsScreenerResponsePayload>(response)) {
        const {
          data: { covidStatus },
        } = response;
        setState((state) => ({ ...state, covidStatus }));
        history.replace(
          {
            pathname: url,
            search: '',
            hash: '',
          },
          { page: Page.VisitorReceiptPage },
        );
      }
    } catch (e) {
      const err = e as ClientError;
      setState((state) => ({ ...state, errorMessage: `Code: ${err.errorContext.status}` }));
    }
  };

  useEffect(() => {
    const getData = async () => {
      setState((state) => ({ ...state, loading: true }));
      const response = await grdn.getVisitorScreener(employerCode);
      if (isSuccessResponse<VisitorsScreenerPayload>(response)) {
        const { data } = response;
        setState((state) => ({
          ...state,
          formState: {
            ...state.formState,
            screener: data.screener,
          },
          sponsorName: data.sponsorName,
          vaccineRequired: data.vaccineRequired,
          loading: false,
        }));
      }
    };
    getData().catch(captureException);
  }, [employerCode]);

  // The following callback & effect cause refreshes to return to the starting page and clear state
  const onBeforeUnload = useCallback(() => {
    history.replace(
      {
        pathname: url,
        search: '',
        hash: '',
      },
      { page: Page.BeginScreenerPage },
    );
  }, [history, url]);

  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => window.removeEventListener('beforeUnload', onBeforeUnload);
  }, [onBeforeUnload]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.state?.page]);

  const questions = formState.screener?.sections[0].questionList;

  const setVaccineStatus = (vaccine_status: boolean | undefined) =>
    setFormState((state) => ({
      ...state,
      isVaccinated: vaccine_status,
    }));

  const setQuestions = (fn: SetQuestionFn) =>
    setFormState((state) => ({
      ...state,
      screener: state.screener
        ? {
            ...state.screener,
            sections:
              state.screener?.sections && state.screener.sections[0]
                ? [
                    {
                      ...state.screener.sections[0],
                      questionList: fn(state.screener?.sections[0].questionList ?? []),
                    },
                  ]
                : [],
          }
        : undefined,
    }));

  const clearFormState = useCallback(() => {
    setFormState((state) => {
      const questions = state.screener?.sections[0].questionList ?? [];
      const questionList = questions.map((q) => ({
        ...q,
        answer: undefined,
      }));
      return {
        ...InitialFormState,
        screener: state.screener
          ? {
              ...state.screener,
              sections:
                state.screener.sections && state.screener.sections[0]
                  ? [{ ...state.screener.sections[0], questionList }]
                  : [],
            }
          : undefined,
      };
    });
  }, []);

  const pageComponent = (errorMessage?: string) => {
    if (loading) return <></>;

    switch (location.state?.page) {
      case Page.OnsiteDetailsPage:
        return <OnsiteDetailsPage setFormState={setFormState} formState={formState} />;
      case Page.VaccinePage:
        return (
          <VaccinePage
            post={post}
            formState={formState}
            vaccineRequired={vaccineRequired}
            setVaccineStatus={setVaccineStatus}
            errorMessage={errorMessage}
          />
        );
      case Page.ScreenerPage:
        return (
          <ScreenerPage
            post={post}
            setQuestions={setQuestions}
            questions={questions ?? []}
            sponsorName={sponsorName ?? ''}
            errorMessage={errorMessage}
          />
        );
      case Page.VisitorReceiptPage:
        return (
          <VisitorReceiptPage
            formState={formState}
            isolate={covidStatus !== WorkStatusEnum.Cleared}
            questions={questions ?? []}
            vaccineRequired={vaccineRequired}
          />
        );
      case Page.BeginScreenerPage:
      default:
        return (
          <BeginScreenerPage clearFormState={clearFormState} sponsorName={sponsorName ?? ''} />
        );
    }
  };
  const isMobile = window.innerWidth <= 500;
  return (
    <div className={isMobile ? css.mobileContainer : css.container}>
      <div>
        <div className={isMobile ? css.headerMobile : css.header}>
          {isMobile ? (
            <div style={{ marginTop: 2, marginRight: 8 }}>
              <EdenMobileLogo />
            </div>
          ) : (
            <EdenHorizontalLogo />
          )}
          {sponsorName && <HeaderSmall>{sponsorName}</HeaderSmall>}
        </div>
        <div className={css.componentContainer}>{pageComponent(errorMessage)}</div>
      </div>
      <div className={isMobile ? css.mobileFooter : css.footer}>
        <div className={css.footerLinks}>
          <Link
            href={'https://www.edenhealth.com/legal/terms-of-use-0820'}
            style={{ paddingRight: 24, paddingBottom: 16, fontSize: 12 }}
          >
            Terms of Use
          </Link>
          <Link
            href={'https://www.edenhealth.com/archive/hipaa'}
            style={{ paddingRight: 24, paddingBottom: 16, fontSize: 12 }}
          >
            HIPAA Policy
          </Link>
          <Link href={'mailto: support@edenhealth.com'} style={{ paddingBottom: 16, fontSize: 12 }}>
            Contact Support
          </Link>
        </div>
        <CaptionNormal>© 2020 Eden Health, Inc. All rights reserved.</CaptionNormal>
      </div>
    </div>
  );
};
