// import { createBrowserHistory } from 'history';
import {
  configureStore,
  createAction,
  getDefaultMiddleware,
  createReducer,
  AnyAction,
} from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import { useDispatch } from 'react-redux';
import { getSentryEnvironment, sentryMiddleware, setupSentry } from 'lib/sentry';
import { getEnvironment } from 'lib/version';
import memberReducer, { memberInitState } from 'redux/member';
import employeeReducer, { employeeInitState } from 'redux/employee';
import userReducer, { userInitState } from 'redux/user';
import visitorReducer, { visitorInitState } from 'redux/visitor';

// export const history = createBrowserHistory();

const middlewares = getDefaultMiddleware();
const sentryEnv = getSentryEnvironment();
if (sentryEnv && ['Staging', 'Production'].includes(sentryEnv)) {
  // ensure we can capture global errors
  const Sentry = setupSentry(sentryEnv);
  middlewares.push(sentryMiddleware);
  Sentry.configureScope((scope) => {
    scope.addEventProcessor((event) => {
      const currentState = store.getState();
      // scrub messages, macros, patients, events & patient image to reduce payload size
      const state = {
        ...currentState,
      };
      event.extra = { ...event.extra, 'Redux State': state };

      return event;
    });
  });
}

if (getEnvironment() === 'Development' && process.env.LOG_REDUX) {
  middlewares.push(createLogger({ level: 'log' }));
}

const appReducer = combineReducers({
  member: memberReducer,
  unconsented: employeeReducer,
  user: userReducer,
  visitor: visitorReducer,
});

export const resetRootAction = createAction('root/RESET_APP');

interface RootResetAction {
  type: typeof resetRootAction.type;
}

const ActionIsRootReset = (action: AnyAction): action is RootResetAction => {
  return action.type === resetRootAction.type;
};

const rootReducerInitState = {
  member: memberInitState,
  unconsented: employeeInitState,
  user: userInitState,
  visitor: visitorInitState,
};

const rootReducer = createReducer(rootReducerInitState, (builder) => {
  builder
    .addMatcher(ActionIsRootReset, () => {
      return rootReducerInitState;
    })
    .addDefaultCase((state, action) => appReducer(state, action));
});

const store = configureStore({
  reducer: rootReducer,
  middleware: middlewares,
});

export type Store = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;

if (getEnvironment() === 'Development') {
  (window as any).store = store;
  // @ts-ignore
  (window as any).select = (selector) => {
    return selector(store.getState());
  };
  (window as any).selectors = {};
}
