import React from 'react';
import cx from 'classnames';
import css from './Text.module.css';

export const DisplayMedium: React.FC<React.HTMLProps<HTMLDivElement>> = ({
  className,
  ...props
}) => <div className={cx(className, css.display, css.medium)} {...props} />;

export const BodyNormal: React.FC<React.HTMLProps<HTMLDivElement>> = ({ className, ...props }) => (
  <div className={cx(className, css.body, css.normal)} {...props} />
);

export const BodySmall: React.FC<React.HTMLProps<HTMLDivElement>> = ({ className, ...props }) => (
  <div className={cx(className, css.body, css.small)} {...props} />
);

export const CaptionNormal: React.FC<React.HTMLProps<HTMLDivElement>> = ({
  className,
  ...props
}) => <div className={cx(className, css.caption, css.normal)} {...props} />;

export const CaptionError: React.FC<React.HTMLProps<HTMLDivElement>> = ({
  className,
  ...props
}) => <div className={cx(className, css.caption, css.error)} {...props} />;

export const HeaderSmall: React.FC<React.HTMLProps<HTMLDivElement>> = ({ className, ...props }) => (
  <div className={cx(className, css.header, css.small)} {...props} />
);

export const HeaderMedium: React.FC<React.HTMLProps<HTMLDivElement>> = ({
  className,
  ...props
}) => <div className={cx(className, css.header, css.medium)} {...props} />;

export const HeaderLarge: React.FC<React.HTMLProps<HTMLDivElement>> = ({ className, ...props }) => (
  <div className={cx(className, css.header, css.large)} {...props} />
);

export const Title: React.FC<React.HTMLProps<HTMLDivElement>> = ({ className, ...props }) => (
  <div className={cx(className, css.title)} {...props} />
);

export const Link: React.FC<React.HTMLProps<HTMLAnchorElement>> = ({ className, ...props }) => (
  <a className={cx(className, css.body, css.normal, css.link)} {...props} />
);

export const LinkSmall: React.FC<React.HTMLProps<HTMLAnchorElement>> = ({
  className,
  ...props
}) => <a className={cx(className, css.body, css.small, css.link)} {...props} />;

export const LinkCaption: React.FC<React.HTMLProps<HTMLAnchorElement>> = ({
  className,
  ...props
}) => <a className={cx(className, css.caption, css.link)} {...props} />;

export const ListBodySmall: React.FC<React.HTMLProps<HTMLDivElement>> = ({
  className,
  ...props
}) => <div className={cx(className, css.listBody, css.small)} {...props} />;

export const KickerHeader: React.FC<React.HTMLProps<HTMLDivElement>> = ({
  className,
  ...props
}) => <div className={cx(className, css.kicker, css.header)} {...props} />;

export const ListKicker: React.FC<React.HTMLProps<HTMLDivElement>> = ({ className, ...props }) => (
  <div className={cx(className, css.kicker, css.list)} {...props} />
);

export const MobileBody: React.FC<React.HTMLProps<HTMLDivElement>> = ({ className, ...props }) => (
  <div className={cx(className, css.mobile, css.body)} {...props} />
);
