import React, { useState, useContext, ChangeEvent } from 'react';
import { Modal, ModalProps } from 'components/design-system/Modal';
import { BodyNormal, BodySmall, HeaderMedium, LinkSmall } from 'components/design-system/Text';
import css from 'components/features/Login/ForgotPasswordModal.module.css';
import { XIcon, Check } from 'components/design-system/Icons';
import { CoreColors } from 'types/colors';
import { Field, PrimaryButton, Label } from 'components/design-system/Form';
import { AuthContext } from 'lib/auth';
import GrdnApi from 'lib/grdn';

export interface ForgotPasswordProps extends ModalProps {
  closeModal: () => void;
}

interface ForgotPasswordState {
  email: string;
  loading: boolean;
  requestSent: boolean;
}

const defaultForgotPasswordState: ForgotPasswordState = {
  email: '',
  loading: false,
  requestSent: false,
};

export const ForgotPasswordModal: React.FC<ForgotPasswordProps> = ({ closeModal, ...props }) => {
  const [{ email, loading, requestSent }, setState] = useState(defaultForgotPasswordState);

  const { forgotPassword } = useContext(AuthContext);

  const newProps = {
    ...props,
    className: css.content,
    overlayClassName: css.overlay,
  };

  const emailValid = () => {
    return email && /.+@.+/.test(email);
  };

  const onSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if (!email) throw Error('Trying to submit forgot password without email');

    setState((state) => ({
      ...state,
      loading: true,
    }));

    try {
      await forgotPassword(email.trim());
    } catch (e) {
      if (e.code === 'NotAuthorizedException') {
        await GrdnApi.resendWelcomeEmail(email);
      } else if (e.code !== 'UserNotFoundException') {
        throw e;
      }
    } finally {
      setState((state) => ({
        ...state,
        requestSent: true,
        loading: false,
      }));
    }
  };

  return (
    <Modal {...newProps}>
      <div
        className={css.closeButton}
        onClick={() => {
          if (!loading) {
            setState(() => defaultForgotPasswordState);
            closeModal();
          }
        }}
      >
        <XIcon color={CoreColors.Slate85} width={24} height={24} />
      </div>
      {!requestSent ? (
        <>
          <form className={css.container} onSubmit={onSubmit}>
            <div className={css.formInput}>
              <HeaderMedium className={css.forgotHeader}>Forgot your password?</HeaderMedium>
              <Label text="Work Email Address">
                <Field
                  value={email}
                  error={false}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const email = e.target.value;
                    setState((state) => ({
                      ...state,
                      email,
                    }));
                  }}
                />
              </Label>
              <PrimaryButton
                type="submit"
                value="Send Instructions"
                disabled={!emailValid()}
                loading={loading}
              />
            </div>
          </form>
        </>
      ) : (
        <div className={css.container}>
          <div className={css.checkIcon}>
            <Check color={CoreColors.Eden} width={36.67} height={36.67} />
          </div>
          <HeaderMedium className={css.sentHeader}>Password Reset Email Sent</HeaderMedium>
          <BodyNormal className={css.sentText}>
            Please finish resetting your password by using instructions from the link provided in
            the email sent to&nbsp;
            <span className={css.sentEmail}>{email}</span>
          </BodyNormal>
          <div
            className={css.resendLink}
            onClick={() => {
              setState((state) => ({
                ...state,
                requestSent: false,
              }));
            }}
          >
            Resend
          </div>
          <div className={css.divider} />
          <BodySmall className={css.noCanDo}>
            Still can&apos;t login? Contact&nbsp;
            <LinkSmall
              href={'mailto://support@edenhealth.com'}
              target="_blank"
              rel="noreferrer noopener"
            >
              support@edenhealth.com
            </LinkSmall>
          </BodySmall>
        </div>
      )}
    </Modal>
  );
};
