import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { Store } from '../store';
import { Visitor } from 'types/db/visitors';
import GrdnApi from 'lib/grdn';
import { ErrorResponse, SuccessResponse, VisitorsPayload } from 'types/grdn';

export const visitorAdapter = createEntityAdapter<Visitor>({
  selectId: (visitor) => visitor.id,
});

export const visitorInitState = visitorAdapter.getInitialState();

export const fetchVisitors = createAsyncThunk<
  Visitor[],
  Record<string, any>,
  {
    rejectValue: ErrorResponse;
  }
>('visitor/fetchVisitors', async (params, thunkAPI) => {
  try {
    const response = await GrdnApi.getVisitors(params);
    return (response as SuccessResponse<VisitorsPayload>).data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const visitorSlice = createSlice({
  name: 'visitor',
  initialState: visitorInitState,
  reducers: {},
  extraReducers: {
    [fetchVisitors.fulfilled.toString()]: (state, action) => {
      visitorAdapter.setAll(state, action.payload);
    },
  },
});

export const visitorSelectors = visitorAdapter.getSelectors<Store>((state) => state.visitor);

export default visitorSlice.reducer;
