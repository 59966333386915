import React from 'react';
import { Modal, ModalProps } from 'components/design-system/Modal';
import css from 'components/features/MemberDashboard/DashboardModal.module.css';
import { XIcon } from 'components/design-system/Icons';
import { CoreColors } from 'types/colors';

export interface DashboardModalProps extends ModalProps {
  onClose: () => void;
  children: React.ReactNode;
}

export const DashboardModal: React.FC<DashboardModalProps> = ({ onClose, children, ...props }) => {
  return (
    <Modal className={css.content} overlayClassName={css.overlay} {...props}>
      <div className={css.closeButton} onClick={onClose}>
        <XIcon color={CoreColors.Slate85} width={24} height={24} />
      </div>
      {children}
    </Modal>
  );
};
