import React, { useContext } from 'react';
import 'react-toastify/dist/ReactToastify.min.css';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AuthContext } from 'lib/auth';
import { MemberDashboard } from 'components/features/MemberDashboard/MemberDashboard';
import { VisitorDashboard } from 'components/features/VisitorDashboard/VisitorDashboard';
import { Login } from 'components/features/Login/Login';
import { ChallengeScreen } from 'components/features/Challenge/ChallengeScreen';
import { ResetForgottenPassword } from 'components/features/ResetPassword/ResetForgottenPassword';
import { VisitorForm } from 'components/features/VisitorForm/VisitorForm';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__: any;
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

interface PublicProps {
  children: React.ReactNode;
}

const Public: React.FC<PublicProps> = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);
  if (isAuthenticated) return <Redirect to={{ pathname: '/employees' }} />;
  return <>{children}</>;
};

const App: React.FC = () => {
  const { isAuthenticated, challenge } = useContext(AuthContext);
  return (
    <Switch>
      <Route exact path="/">
        <Public>{challenge ? <Redirect to={{ pathname: '/challenge' }} push /> : <Login />}</Public>
      </Route>
      <Route exact path="/forgot-my-password">
        <Public>
          {challenge ? (
            <Redirect to={{ pathname: '/challenge' }} push />
          ) : (
            <ResetForgottenPassword />
          )}
        </Public>
      </Route>
      <Route exact path="/challenge">
        <Public>{challenge ? <ChallengeScreen /> : <Redirect to={{ pathname: '/' }} />}</Public>
      </Route>
      <Route exact path="/employees">
        {isAuthenticated ? <MemberDashboard /> : <Redirect to={{ pathname: '/' }} />}
      </Route>
      <Route exact path="/visitors">
        {isAuthenticated ? <VisitorDashboard /> : <Redirect to={{ pathname: '/' }} />}
      </Route>
      <Route path="/:employerCode/screeners">
        <VisitorForm />
      </Route>
      <Route path="*">
        <Redirect to={{ pathname: '/' }} />
      </Route>
    </Switch>
  );
};

export default App;
