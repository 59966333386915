import React, { ChangeEvent, useContext, useState, useEffect } from 'react';
import css from './Login.module.css';
import { EdenFullLogo, Info } from 'components/design-system/Icons';
import {
  BodyNormal,
  BodySmall,
  CaptionError,
  CaptionNormal,
  DisplayMedium,
  LinkCaption,
} from 'components/design-system/Text';
import { PrimaryButton, Field, Label, ErrorText } from 'components/design-system/Form';
import { AccentColors, CoreColors } from 'types/colors';
import { AuthContext } from 'lib/auth';
import { ForgotPasswordModal } from 'components/features/Login/ForgotPasswordModal';
import { captureException } from 'lib/sentry';
import { useQuery } from 'lib/hooks';

interface LoginState {
  invalidCredentials: boolean;
  missingPassword: boolean;
  missingEmail: boolean;
  loginError: boolean;
  loading: boolean;
  email: string;
  password: string;
  showForgotPassword: boolean;
}

const getLoginStateDefault = (): LoginState => ({
  invalidCredentials: false,
  missingEmail: false,
  missingPassword: false,
  loginError: false,
  loading: false,
  email: '',
  password: '',
  showForgotPassword: false,
});

export const Login: React.FC = () => {
  const [
    {
      invalidCredentials,
      missingPassword,
      missingEmail,
      loginError,
      email,
      password,
      loading,
      showForgotPassword,
    },
    setState,
  ] = useState<LoginState>(getLoginStateDefault());
  const query = useQuery();
  const error = query.get('error');
  useEffect(
    () =>
      setState((state) => ({
        ...state,
        loginError: error === 'true',
      })),
    [error],
  );

  const { signIn } = useContext(AuthContext);
  const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!email || !password) {
      setState((state) => ({ ...state, missingEmail: !email, missingPassword: !password }));
    } else {
      setState((state) => ({
        ...state,
        invalidCredentials: false,
        missingEmail: false,
        missingPassword: false,
        loginError: false,
        loading: true,
      }));
      try {
        await signIn(email.trim(), password.trim());
      } catch (e) {
        if (e.code === 'UserNotFoundException' || e.code === 'NotAuthorizedException') {
          setState((state) => ({
            ...state,
            invalidCredentials: true,
            loading: false,
          }));
        } else captureException(e);
      }
    }
  };

  return (
    <>
      <div className={css.bgContainer}>
        <div className={css.bg} />
      </div>
      <div className={css.container}>
        <div className={css.panel}>
          <form onSubmit={submitForm}>
            <EdenFullLogo className={css.logo} />
            <DisplayMedium style={{ paddingBottom: 16 }}>
              Employee Health Reporting Dashboard
            </DisplayMedium>
            <div className={css.form}>
              <BodyNormal style={{ paddingBottom: 21 }}>
                Please log in with the credentials provided by your Eden Health account manager.
              </BodyNormal>
              {invalidCredentials && (
                <div className={css.error}>
                  <div style={{ marginRight: 12.67, marginTop: 4 }}>
                    <Info color={AccentColors.Razz} height={14.67} width={14.67} />
                  </div>
                  <CaptionError data-test-id="invalid-credentials">
                    Invalid log in credentials, please try again.
                  </CaptionError>
                </div>
              )}
              {loginError && (
                <div className={css.error}>
                  <div style={{ marginRight: 12.67, marginTop: 4 }}>
                    <Info color={AccentColors.Razz} height={14.67} width={14.67} />
                  </div>
                  <CaptionError data-test-id="login-error">
                    Login error - please try again or contact{' '}
                    <LinkCaption
                      href="mailto:support@edenhealth.com"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      support@edenhealth.com
                    </LinkCaption>
                  </CaptionError>
                </div>
              )}
              <div style={{ paddingTop: 21 }}>
                <Label text="Work Email Address">
                  <Field
                    error={missingEmail}
                    value={email}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const email = e.target.value;
                      setState((state) => ({ ...state, email }));
                    }}
                    data-test-id="email"
                  />
                  {missingEmail && (
                    <ErrorText data-test-id="invalid-email">
                      Please enter a valid email address
                    </ErrorText>
                  )}
                </Label>
                <Label text="Password">
                  <Field
                    error={missingPassword}
                    value={password}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const password = e.currentTarget.value;
                      setState((state) => ({ ...state, password }));
                    }}
                    type="password"
                    data-test-id="password"
                  />
                  {missingPassword && (
                    <ErrorText data-test-id="missing-password">
                      A password is required for login
                    </ErrorText>
                  )}
                </Label>
                <PrimaryButton
                  style={{ marginBottom: 24 }}
                  loading={loading}
                  type="submit"
                  value="Log In"
                  data-test-id="submit"
                />
                <BodySmall
                  style={{ color: CoreColors.Eden, marginBottom: 24, cursor: 'pointer' }}
                  onClick={() => {
                    setState((state) => ({
                      ...state,
                      showForgotPassword: true,
                    }));
                  }}
                >
                  Forgot Password?
                </BodySmall>
              </div>
            </div>
          </form>
          <div className={css.footer}>
            <CaptionNormal>© 2020 Eden Health, Inc. All rights reserved.</CaptionNormal>
            <CaptionNormal>edenhealth.com</CaptionNormal>
          </div>
        </div>
      </div>
      <ForgotPasswordModal
        isOpen={showForgotPassword}
        closeModal={() => {
          setState((state) => ({
            ...state,
            showForgotPassword: false,
          }));
        }}
      />
    </>
  );
};
