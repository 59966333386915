import React, { useEffect, useState } from 'react';
import { Sidebar } from '../Sidebar/Sidebar';
import css from './VisitorDashboard.module.css';
import { VisitorList } from './VisitorList';
import { useAppDispatch } from 'store';
import { fetchUser } from 'redux/user';
import { isoDate } from 'lib/time';
import { captureException } from 'lib/sentry';
import { fetchVisitors } from 'redux/visitor';

export interface CsvSettings {
  day: Date;
  status: string;
}

export interface VisitorDashboardState {
  lastSelectedDate: Date | undefined;
  selectedDateLoadedAt: Date | undefined;
  loading: boolean;
  filters: CsvSettings;
}

export const VisitorDashboard: React.FC = () => {
  const dispatch = useAppDispatch();

  const [state, setState] = useState<VisitorDashboardState>({
    lastSelectedDate: undefined,
    selectedDateLoadedAt: undefined,
    loading: true,
    filters: { day: new Date(), status: '' },
  });

  const { lastSelectedDate, selectedDateLoadedAt, loading, filters } = state;

  useEffect(() => {
    const action = async () => {
      const fetchUserResponse = await dispatch(fetchUser({}));
      if (fetchUser.rejected.match(fetchUserResponse)) {
        throw fetchUserResponse.payload ?? 'Unknown fetchUser error';
      }
    };
    action().catch(captureException);
  }, [dispatch]);

  useEffect(() => {
    const action = async () => {
      if (loading || filters.day !== lastSelectedDate) {
        const fetchVisitorsResponse = await dispatch(fetchVisitors({ day: isoDate(filters.day) }));
        if (fetchVisitors.rejected.match(fetchVisitorsResponse)) {
          throw fetchVisitorsResponse.payload ?? 'Unknown fetchVisitors error';
        }
        setState((state) => ({
          ...state,
          loading: false,
          selectedDateLoadedAt: new Date(),
          lastSelectedDate: filters.day,
        }));
      }
    };
    action().catch(captureException);
  }, [dispatch, lastSelectedDate, loading, filters]);

  return (
    <>
      <div className={css.container}>
        <Sidebar
          loading={loading}
          selectedDateLoadedAt={selectedDateLoadedAt}
          reload={() => setState((state) => ({ ...state, loading: true }))}
        />
        <VisitorList state={state} setState={setState} />
      </div>
    </>
  );
};
