import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { Store } from '../store';
import { Employee } from 'types/db/members';
import GrdnApi from 'lib/grdn';
import { ErrorResponse, EmployeesPayload, SuccessResponse } from 'types/grdn';

export const employeeAdapter = createEntityAdapter<Employee>({
  selectId: (employee) => employee.id,
});

export const employeeInitState = employeeAdapter.getInitialState();

export const fetchUnconsented = createAsyncThunk<
  Employee[],
  Record<string, any>,
  {
    rejectValue: ErrorResponse;
  }
>('member/fetchUnconsented', async (_params, thunkAPI) => {
  try {
    const response = await GrdnApi.getUnconsented();
    return (response as SuccessResponse<EmployeesPayload>).data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const unconsentedSlice = createSlice({
  name: 'user',
  initialState: employeeInitState,
  reducers: {},
  extraReducers: {
    [fetchUnconsented.fulfilled.toString()]: (state, action) => {
      employeeAdapter.setAll(state, action.payload);
    },
  },
});

export const unconsentedSelectors = employeeAdapter.getSelectors<Store>(
  (state) => state.unconsented,
);

export default unconsentedSlice.reducer;
