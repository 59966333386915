import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import GrdnApi from 'lib/grdn';
import { isSuccessResponse, MemberPayload, MembersPayload, ResponseEnvelope } from 'types/grdn';
import { Member, WorkStatusEnum } from 'types/db/members';
import { DownloadCloud } from 'components/design-system/Icons';
import { BodySmall } from 'components/design-system/Text';
import textStyles from 'components/design-system/Text.module.css';
import { CoreColors } from 'types/colors';
import { captureException } from 'lib/sentry';
import { calendarDate, formatTime } from 'lib/time';
import { rowCompare } from 'lib/sort';
import { transformReasons } from 'lib/isolateReasons';
import { selectRestrictedStatus, selectRestrictedVaxStatus } from 'redux/user';

interface CsvRow {
  'F Name': string;
  'L Name': string;
  'Vaccination Status'?: string;
  'Work Status': WorkStatusEnum;
  'Return to Work Date': string;
  'Isolate Reason': string;
  Exposure?: boolean;
  Symptoms?: boolean;
  'COVID Test'?: boolean;
  Travel?: boolean;
  Other?: boolean;
  'Screener Time': string;
  'Last Test Result': string;
  'Last Test Time': string;
  'Employee ID': string;
  'Work Email': string;
  'Office Location': string;
}

const generateHeaders = (restricted: boolean, excludeVaccinationStatus: boolean) => [
  'F Name',
  'L Name',
  ...(excludeVaccinationStatus ? [] : ['Vaccination Status']),
  'Work Status',
  'Return to Work Date',
  'Isolate Reason',
  ...(restricted ? [] : ['Exposure', 'Symptoms', 'COVID Test', 'Travel', 'Other']),
  'Screener Time',
  'Last Test Result',
  'Last Test Time',
  'Employee ID',
  'Work Email',
  'Office Location',
];

const addReasons = (reasons: string, restricted: boolean) =>
  restricted ? {} : transformReasons(reasons);

interface MembersCsvLinkProps {
  status: WorkStatusEnum | undefined;
  vaccinationStatus: string | undefined;
  day: string;
  worksite: string;
}

interface CSVParams {
  'triage-status'?: WorkStatusEnum;
  'vaccination-status'?: string;
  day: string;
  worksite?: string;
}

export const MembersCsvLink: React.FC<MembersCsvLinkProps> = ({
  day,
  status,
  vaccinationStatus,
  worksite,
}) => {
  const [csvData, setCsvData] = useState<CsvRow[]>([]);
  const csvInstance = useRef<any | null>(null);

  const hasRestrictedDashboard = useSelector(selectRestrictedStatus) || false;
  const hasRestrictedVaxStatus = useSelector(selectRestrictedVaxStatus);

  const toCsvRow = (row: Member): CsvRow => ({
    'F Name': row.firstName,
    'L Name': row.lastName,
    ...(hasRestrictedVaxStatus ? {} : { 'Vaccination Status': row.vaccinationStatus }),
    'Work Status': row.triageStatus,
    'Return to Work Date': row.returnToWorkDate
      ? calendarDate(row.returnToWorkDate, { displayYear: true })
      : '',
    'Isolate Reason': row.isolateReasons,
    'Screener Time':
      row.triageStatus !== WorkStatusEnum.Incomplete ? formatTime(row.lastTriageAt) : '',
    'Last Test Result': row.lastTest
      ? `${row.lastTest.result} ${row.lastTest.type} - ${
          row.lastTest.verified ? 'verified' : 'unverified'
        }`
      : '',
    'Last Test Time': row.lastTest
      ? calendarDate(row.lastTest.dateTime, {
          displayYear: true,
          displayTime: true,
        })
      : '',
    'Employee ID': row.employeeId,
    'Work Email': row.email,
    'Office Location': row.worksite,
    ...addReasons(row.isolateReasons, hasRestrictedDashboard),
    ..._.mapKeys(row.data, (_, key) =>
      key
        .split(/[_-]/)
        .map((word) => `${word.charAt(0).toUpperCase()}${word.substring(1)}`)
        .join(' '),
    ),
  });

  const params: CSVParams = { day };
  if (status) {
    params['triage-status'] = status;
  }
  if (vaccinationStatus) {
    params['vaccination-status'] = vaccinationStatus;
  }
  if (worksite) {
    params.worksite = worksite;
  }

  const fetchData = async () => {
    GrdnApi.getMembers(params)
      .then((response) => {
        parseResponse(response);
        return;
      })
      .catch(captureException);
  };

  useEffect(() => {
    if (csvData && csvInstance?.current?.link) {
      // XXX: this timeout appears redundant, but removing it will break CSV downloads: instead of
      // a csv, the download contains a react html page. ¯\_(ツ)_/¯
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData([]);
      });
    }
  }, [csvData]);

  const parseResponse = async (
    response: ResponseEnvelope<MembersPayload | null>,
  ): Promise<void> => {
    if (isSuccessResponse<MembersPayload>(response)) {
      const members = response.data.map(
        (memberPayload: MemberPayload): Member => ({
          testingResults: [],
          ...memberPayload,
        }),
      );

      setCsvData(members.sort(rowCompare).map(toCsvRow));
    }
  };

  return (
    <>
      <div
        onClick={fetchData}
        style={{
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          whiteSpace: 'nowrap',
        }}
      >
        <DownloadCloud color={CoreColors.Eden} width={20} height={20} />
        <BodySmall
          className={textStyles.semibold}
          style={{
            color: CoreColors.Eden,
            marginLeft: 8,
            float: 'right',
          }}
        >
          Export as CSV
        </BodySmall>
      </div>
      {csvData.length > 0 ? (
        <CSVLink
          data={csvData}
          headers={_.uniq([
            ...generateHeaders(hasRestrictedDashboard, hasRestrictedVaxStatus),
            ..._.flatMap(csvData, (row) => _.keys(row)),
          ])}
          filename={`workstatus-${status || 'all'}-worksite-${
            worksite || 'all'
          }-vaccination-status-${vaccinationStatus || 'all'}-${day}.csv`}
          ref={csvInstance}
        />
      ) : undefined}
    </>
  );
};
