import { Member } from 'types/db/members';
import { Visitor } from 'types/db/visitors';

export const rowCompare = (el1: Member | Visitor, el2: Member | Visitor): number => {
  if (el1.lastTriageAt === undefined || !el1.lastTriageAt) {
    return 1;
  }

  if (el2.lastTriageAt === undefined || !el2.lastTriageAt) {
    return -1;
  }

  if (el1.lastTriageAt > el2.lastTriageAt) {
    return -1;
  } else if (el1.lastTriageAt < el2.lastTriageAt) {
    return 1;
  } else {
    return 0;
  }
};

export const dateAndYearSorter = (dateString1: string, dateString2: string): number => {
  if (!dateString1) {
    return -1;
  } else if (!dateString2) {
    return 1;
  }

  const date1 = new Date(dateString1 || '');
  const date2 = new Date(dateString2 || '');

  if (date1 < date2) {
    return -1;
  } else if (date1 > date2) {
    return 1;
  } else {
    return 0;
  }
};
