import camelize from 'camelize';
import { SerializedError } from '@reduxjs/toolkit';
import { ErrorResponse } from 'types/grdn';
import { HttpMethod } from 'lib/api';

export enum APIErrors {
  ClientError = 'ClientError',
  ServerError = 'ServerError',
}

interface APIError extends SerializedError {
  name: APIErrors;
  fingerprint: string[];
  errorContext: {
    status: number;
    type: string;
    url: string;
  };
  body: string;
}

export interface ClientError extends APIError {
  name: APIErrors.ClientError;
}

export interface ServerError extends APIError {
  name: APIErrors.ServerError;
}

type PotentialAPIError = string | Error | ErrorResponse | APIError;

export const isAPIError = (e: PotentialAPIError): e is APIError => {
  return (e as APIError).fingerprint !== undefined && (e as APIError).errorContext !== undefined;
};

const apiError = async <T extends APIError>(
  name: T['name'],
  response: Response,
  method: HttpMethod,
  endpoint: string,
): Promise<T> => {
  const bodyJson = await response.json();
  const body = camelize(bodyJson);

  return {
    name: name,
    message: `${name} [${method}] ${endpoint}`,
    fingerprint: [method, endpoint],
    errorContext: {
      status: response.status,
      type: response.type,
      url: response.url,
    },
    body,
  } as T;
};

export const clientError = async (
  response: Response,
  method: HttpMethod,
  endpoint: string,
): Promise<ClientError> => {
  return apiError(APIErrors.ClientError, response, method, endpoint);
};

export const serverError = async (
  response: Response,
  method: HttpMethod,
  endpoint: string,
): Promise<ServerError> => {
  return apiError(APIErrors.ServerError, response, method, endpoint);
};
