import React, { ChangeEvent, useContext, useState } from 'react';
import { TimedOut } from './TimedOut';
import css from 'components/features/ResetPassword/NewPassword.module.css';
import { BodyNormal, BodySmall, CaptionError, HeaderMedium } from 'components/design-system/Text';
import { Field, PrimaryButton, Label } from 'components/design-system/Form';
import { AuthContext } from 'lib/auth';
import { AccentColors, CoreColors } from 'types/colors';
import { Check, Info } from 'components/design-system/Icons';

interface NewPasswordState {
  loading: boolean;
  currentPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
  longEnough: boolean;
  includesSpecialChar: boolean;
  includesNumber: boolean;
  errorMessage: string;
  timedOut: boolean;
}

const newPasswordStateDefault = (): NewPasswordState => ({
  loading: false,
  currentPassword: '',
  newPassword: '',
  newPasswordConfirmation: '',
  longEnough: false,
  includesSpecialChar: false,
  includesNumber: false,
  errorMessage: '',
  timedOut: false,
});

export enum NewPasswordReason {
  ResetInitial = 'ResetInitial',
  ResetExisting = 'ResetExisting',
  Forgot = 'Forgot',
}

interface NewPasswordProps {
  reason: NewPasswordReason;
  code?: string;
  email?: string;
  passwordChanged?: () => void;
}

const MIN_PASSWORD_LENGTH = 12;

export const NewPassword: React.FC<NewPasswordProps> = ({
  reason,
  code,
  email,
  passwordChanged,
}) => {
  const [
    {
      loading,
      currentPassword,
      newPassword,
      newPasswordConfirmation,
      longEnough,
      includesSpecialChar,
      includesNumber,
      errorMessage,
      timedOut,
    },
    setState,
  ] = useState<NewPasswordState>(newPasswordStateDefault());
  const { completeNewPassword, changePassword, completeForgotPassword, signIn } =
    useContext(AuthContext);
  if (reason === NewPasswordReason.Forgot && (!code || !email)) {
    throw new Error('Forgot password reset requires email & code');
  }
  const submitPassword = async () => {
    if (newPassword !== newPasswordConfirmation) {
      setState((state) => ({
        ...state,
        loading: false,
        errorMessage: 'The passwords you entered did not match.',
      }));
      return;
    }
    setState((state) => ({
      ...state,
      loading: true,
      passwordMismatch: false,
    }));
    try {
      if (reason === NewPasswordReason.ResetInitial) {
        await completeNewPassword(newPassword);
      } else if (reason === NewPasswordReason.ResetExisting) {
        await changePassword(currentPassword, newPassword);
      } else if (reason === NewPasswordReason.Forgot && email && code) {
        await completeForgotPassword(email, code, newPassword);
        await signIn(email, newPassword);
      }
    } catch (e) {
      let errorMessage = '';
      switch (e.code) {
        case 'NotAuthorizedException':
          if (reason === NewPasswordReason.ResetExisting) {
            errorMessage = 'Your current password was entered incorrectly.';
          } else {
            setState((state) => ({
              ...state,
              timedOut: true,
            }));
          }
          break;
        case 'LimitExceededException':
          errorMessage = 'Number of tries exceeded. Please wait a few minutes and try again.';
          break;
        case 'ExpiredCodeException':
          errorMessage = `Password reset link has expired. Please request a new one from ${window.location.origin}.`;
          break;
        default:
          throw e;
      }
      setState((state) => ({
        ...state,
        loading: false,
        errorMessage,
      }));
    }
    passwordChanged && passwordChanged();
  };

  const validateNewPassword = (newPassword: string) => {
    setState((state) => ({
      ...state,
      newPassword,
      longEnough: newPassword.length >= MIN_PASSWORD_LENGTH,
      includesSpecialChar: /[\^\$\*\.\[\]\{\}\(\)\?"!@#%&\/,><':;|_~`\\]/.test(newPassword),
      includesNumber: /\d/.test(newPassword),
    }));
  };
  return (
    <>
      <div className={css.container}>
        <div style={{ maxWidth: 560 }}>
          <HeaderMedium style={{ paddingBottom: 16, textAlign: 'center' }}>
            Create a new secure password
          </HeaderMedium>
          <BodyNormal style={{ textAlign: 'center' }}>
            For your security, please include...
          </BodyNormal>
          <div className={css.validationBox}>
            <div className={css.validationItem}>
              <Check
                height={16}
                width={16}
                color={longEnough ? CoreColors.Eden : CoreColors.Slate25}
              />
              <BodySmall>{`${MIN_PASSWORD_LENGTH} characters minimum`}</BodySmall>
            </div>
            <div className={css.validationItem}>
              <Check
                height={16}
                width={16}
                color={includesSpecialChar ? CoreColors.Eden : CoreColors.Slate25}
              />
              <BodySmall>1 special character</BodySmall>
            </div>
            <div className={css.validationItem}>
              <Check
                height={16}
                width={16}
                color={includesNumber ? CoreColors.Eden : CoreColors.Slate25}
              />
              <BodySmall>1 number</BodySmall>
            </div>
          </div>
          {errorMessage && (
            <div className={css.passwordMismatch}>
              <div style={{ marginRight: 8, marginTop: 4 }}>
                <Info color={AccentColors.Razz} height={14.67} width={14.67} />
              </div>
              <CaptionError>{errorMessage}</CaptionError>
            </div>
          )}
          {reason === NewPasswordReason.ResetExisting && (
            <Label style={{ marginTop: 32 }} text="Current Password">
              <Field
                error={false}
                value={currentPassword}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const currentPassword = e.currentTarget.value;
                  setState((state) => ({
                    ...state,
                    currentPassword,
                  }));
                }}
                type="password"
              />
            </Label>
          )}
          <Label
            style={{ marginTop: reason === NewPasswordReason.ResetExisting ? 0 : 32 }}
            text="New Password"
          >
            <Field
              error={false}
              value={newPassword}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const newPassword = e.currentTarget.value;
                validateNewPassword(newPassword);
              }}
              type="password"
            />
          </Label>
          <Label text="Confirm New Password">
            <Field
              error={false}
              value={newPasswordConfirmation}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const newPasswordConfirmation = e.currentTarget.value;
                setState((state) => ({ ...state, newPasswordConfirmation }));
              }}
              type="password"
            />
          </Label>
          <PrimaryButton
            style={{ marginBottom: 40, maxWidth: '100%' }}
            loading={loading}
            onClick={submitPassword}
            value="Change Password"
            data-test-id="submitNewPassword"
            disabled={
              !(
                longEnough &&
                includesSpecialChar &&
                includesNumber &&
                newPasswordConfirmation.length === newPassword.length &&
                (reason === NewPasswordReason.ResetExisting
                  ? currentPassword.length >= MIN_PASSWORD_LENGTH
                  : true)
              )
            }
          />
        </div>
      </div>
      <TimedOut isOpen={timedOut} />
    </>
  );
};
