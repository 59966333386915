import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import { TimedOut } from '../ResetPassword/TimedOut';
import css from './Challenge.module.css';
import { BodyNormal, CaptionError, HeaderMedium, Link } from 'components/design-system/Text';
import { Field, Label, PrimaryButton } from 'components/design-system/Form';
import { Check, Info } from 'components/design-system/Icons';
import { AccentColors, CoreColors } from 'types/colors';
import { NewPassword, NewPasswordReason } from 'components/features/ResetPassword/NewPassword';
import { AuthContext, ChallengeKind } from 'lib/auth';

const CHALLENGE_COMPLETE_TIMEOUT = 400;

interface ChallengeState {
  loading: boolean;
  verificationCode: string;
  errorMessage: string;
  timedOut: boolean;
}

const getChallengeStateDefault = (): ChallengeState => ({
  loading: false,
  verificationCode: '',
  errorMessage: '',
  timedOut: false,
});

interface ChallengeProps {
  mfaSetupSuccess?: () => void;
}

export const Challenge: React.FC<ChallengeProps> = ({ mfaSetupSuccess }) => {
  const [{ loading, verificationCode, errorMessage, timedOut }, setState] =
    useState<ChallengeState>(getChallengeStateDefault());
  const { challenge, secret, verifyMFA, generateOTPAuth, verifySoftwareToken, completeChallenge } =
    useContext(AuthContext);

  const submitForm = async () => {
    setState((state) => ({
      ...state,
      loading: true,
    }));
    if (challenge === ChallengeKind.MFA_SETUP) {
      try {
        await verifySoftwareToken(verificationCode);
      } catch (err) {
        if (err.code === 'EnableSoftwareTokenMFAException') {
          setState((state) => ({
            ...state,
            errorMessage: 'The code you entered in incorrect',
            loading: false,
          }));
          return;
        }
        throw err;
      }
    } else {
      try {
        await verifyMFA(verificationCode);
      } catch (err) {
        let errorMessage = '';
        switch (err.code) {
          case 'CodeMismatchException':
            errorMessage = 'The code you entered is incorrect';
            break;
          case 'ExpiredCodeException':
            errorMessage = 'The code you entered has expired.';
            break;
          case 'NotAuthorizedException':
            // The user has taken so long to enter the TOTP that they are no longer considered logged in by Cognito
            setState((state) => ({
              ...state,
              timedOut: true,
            }));
            break;
          default:
            throw err;
        }
        setState((state) => ({
          ...state,
          errorMessage,
          loading: false,
        }));
        return;
      }
    }
    setState((state) => ({
      ...state,
      incorrectCode: false,
      loading: false,
    }));
    setTimeout(() => {
      if (challenge === ChallengeKind.MFA_SETUP && mfaSetupSuccess) mfaSetupSuccess();
      completeChallenge();
    }, CHALLENGE_COMPLETE_TIMEOUT);
  };

  useEffect(() => {
    if (challenge === ChallengeKind.MFA_SETUP) generateOTPAuth();
  }, [challenge, generateOTPAuth]);

  const validCode = () => {
    return verificationCode.length === 6 && /^\d+$/.test(verificationCode);
  };

  if (!challenge) return <></>;

  return (
    <>
      <div className={css.container}>
        {challenge === ChallengeKind.NEW_PASSWORD_REQUIRED ? (
          <NewPassword reason={NewPasswordReason.ResetInitial} />
        ) : (
          <>
            <HeaderMedium className={css.headerText}>
              {challenge === ChallengeKind.MFA_SETUP ||
              challenge === ChallengeKind.MFA_SETUP_COMPLETE
                ? 'Secure your account by setting up 2-factor authentication'
                : 'Complete 2-factor authentication'}
            </HeaderMedium>
            <div className={css.infoContainer}>
              <BodyNormal
                style={{
                  marginBottom: challenge === ChallengeKind.MFA_SETUP ? 32 : 48,
                  width: 560,
                  textAlign: 'center',
                }}
              >
                {challenge === ChallengeKind.MFA_SETUP ||
                challenge === ChallengeKind.MFA_SETUP_COMPLETE ? (
                  <>
                    Open an authenticator app, such as&nbsp;
                    <Link
                      href="https://authy.com/download/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Authy
                    </Link>{' '}
                    or&nbsp;
                    <Link
                      href="https://support.google.com/accounts/answer/1066447?hl=en"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Google Authenticator
                    </Link>
                    , and scan the QR code below to generate your verification code.
                  </>
                ) : (
                  'Enter the verification code shown on your authentication app'
                )}
              </BodyNormal>

              {(challenge === ChallengeKind.MFA_SETUP ||
                challenge === ChallengeKind.MFA_SETUP_COMPLETE) &&
                secret && (
                  <div className={css.qrBox}>
                    <QRCode value={secret} size={180} />
                  </div>
                )}
              {errorMessage && (
                <div className={css.incorrectCode}>
                  <div style={{ marginRight: 12.67, marginTop: 4 }}>
                    <Info color={AccentColors.Razz} height={14.67} width={14.67} />
                  </div>
                  <CaptionError>{errorMessage}</CaptionError>
                </div>
              )}
              {(challenge === ChallengeKind.MFA_SETUP ||
                challenge === ChallengeKind.SOFTWARE_TOKEN_MFA) && (
                <>
                  <Label style={{ marginBottom: 40, width: 398 }} text="Verification Code">
                    <Field
                      error={errorMessage.length > 0}
                      value={verificationCode}
                      placeholder={'Enter 6-digit verification code'}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const verificationCode = e.currentTarget.value;
                        if (verificationCode.length <= 6 && /^\d*$/.test(verificationCode)) {
                          setState((state) => ({ ...state, verificationCode }));
                        }
                      }}
                      onKeyDown={async (e) => {
                        if (e.key === 'Enter' && verificationCode.length === 6) {
                          await submitForm();
                        }
                      }}
                    />
                  </Label>
                  <PrimaryButton
                    style={{ width: 399 }}
                    loading={loading}
                    onClick={submitForm}
                    value="Verify"
                    data-test-id="submitChallenge"
                    disabled={!validCode()}
                  />
                </>
              )}
              {(challenge === ChallengeKind.MFA_SETUP_COMPLETE ||
                challenge === ChallengeKind.SOFTWARE_TOKEN_VALIDATED) && (
                <div className={css.authSuccess}>
                  <Check height={16} width={16} color={CoreColors.Eden} />
                  <div style={{ marginLeft: 13 }}>Authentication success</div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <TimedOut isOpen={timedOut} />
    </>
  );
};
