/* istanbul ignore file */

import { useDebugValue } from 'react';
import { loadEnv } from 'lib/env';

export type BuildEnvironment = 'Production' | 'Staging' | 'Development' | 'Integration';

export const getEnvironment = (): BuildEnvironment =>
  loadEnv('REACT_APP_BUILD_ENVIRONMENT') as BuildEnvironment;
export const getAppName = (): string => loadEnv('REACT_APP_NAME');
export const getReleasePrefix = (): string => loadEnv('REACT_APP_RELEASE_PREFIX');
const getVersion = () => loadEnv('REACT_APP_VERSION');

export const getRelease = (): string => {
  const environment = getEnvironment();
  const prefix = getReleasePrefix();
  const app = getAppName();
  const release = `${getVersion()}`;

  switch (environment) {
    case 'Production':
    case 'Staging':
      return `${prefix}.${app}-${release}`;
    case 'Development':
      return `${prefix}.${app}-${release}-local`;
    case 'Integration':
      return `${prefix}.${app}-${release}-testing`;
  }
};

export const useReleaseName = (): string => {
  const release = getRelease();
  useDebugValue(release);

  return release;
};
