import React, { useState } from 'react';
import { Options } from './Options';
import css from './ScreenerPage.module.css';
import { OptionsModal } from './OptionsModal';
import { splitQuestion } from './utils';
import { BackButton } from './BackButton';
import {
  MultipleChoiceQuestion,
  MultipleSelectQuestion,
  Question,
  SetQuestionFn,
} from 'types/screener';
import {
  BodyNormal,
  BodySmall,
  CaptionError,
  CaptionNormal,
  HeaderLarge,
  LinkCaption,
} from 'components/design-system/Text';
import { Calendar, Info } from 'components/design-system/Icons';
import { PrimaryButton } from 'components/design-system/Form';
import { AccentColors } from 'types/colors';

type QuestionList = Question[];

interface QuestionProps {
  index: number;
  question: Question;
}

interface MultipleChoiceQuestionProps extends QuestionProps {
  index: number;
  onClick: (answer: number | undefined) => void;
  question: MultipleChoiceQuestion;
}

interface MultipleSelectQuestionProps extends QuestionProps {
  index: number;
  onSelect: (answer: number[] | undefined) => void;
  question: MultipleSelectQuestion;
}

const PreQuestionComponent = ({ question, index, onClick }: MultipleChoiceQuestionProps) => {
  const [mainQuestion, subquestion] = splitQuestion(question);

  return (
    <div className={css.questionContainer} key={question.key}>
      <BodyNormal style={{ paddingBottom: 16 }} className={css.question}>
        <span style={{ fontWeight: 'bold' }}>{index + 1}. </span> {mainQuestion}
      </BodyNormal>
      {subquestion && (
        <BodySmall style={{ paddingBottom: 16 }} className={css.subtext}>
          {subquestion}
        </BodySmall>
      )}
      <Options
        selected={question.answer}
        possibleAnswers={question.possibleAnswers}
        onClick={onClick}
      />
    </div>
  );
};

const MultipleChoiceQuestionComponent = ({
  question,
  index,
  onClick,
}: MultipleChoiceQuestionProps) => {
  const [preQuestionAnswer, setPreQuestionAnswer] = useState<number | undefined>(() => {
    if (question.answer) {
      return question.answer && question.answer === 0 ? 0 : 1;
    } else {
      return undefined;
    }
  });
  const preQuestion: MultipleChoiceQuestion = {
    ...question,
    questionType: 'MultipleChoice',
    possibleAnswers: ['No', 'Yes'],
    answer: preQuestionAnswer,
  };

  if (question.preamble === undefined) {
    const [mainQuestion, subquestion] = splitQuestion(question);
    return (
      <div className={css.questionContainer} key={question.key}>
        <BodyNormal style={{ paddingBottom: 16 }} className={css.question}>
          <span style={{ fontWeight: 'bold' }}>{index + 1}. </span> {mainQuestion}
        </BodyNormal>
        {subquestion && (
          <BodySmall style={{ paddingBottom: 16 }} className={css.subtext}>
            {subquestion}
          </BodySmall>
        )}
        <Options
          selected={question.answer}
          possibleAnswers={question.possibleAnswers}
          onClick={onClick}
        />
      </div>
    );
  } else {
    return (
      <>
        <PreQuestionComponent
          index={index}
          question={preQuestion}
          onClick={(answer) => {
            setPreQuestionAnswer(answer);
            onClick(
              answer !== undefined && preQuestion.possibleAnswers[answer] === 'No' ? 0 : undefined,
            );
          }}
        />
        {preQuestionAnswer !== undefined &&
        preQuestion.possibleAnswers[preQuestionAnswer] === 'Yes' ? (
          <div className={css.questionContainer} key={question.key}>
            <BodyNormal style={{ paddingBottom: 16 }} className={css.question}>
              {question.preamble}
            </BodyNormal>
            <Options
              selected={question.answer}
              possibleAnswers={question.possibleAnswers}
              onClick={onClick}
            />
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }
};

const MultipleSelectQuestionComponent = ({
  question,
  index,
  onSelect,
}: MultipleSelectQuestionProps) => {
  const [mockQuestionAnswer, setMockQuestionAnswer] = useState<number | undefined>(() => {
    if (question.answer) {
      return question.answer && question.answer.length === 0 ? 0 : 1;
    } else {
      return undefined;
    }
  });

  const mockQuestion: MultipleChoiceQuestion = {
    ...question,
    questionType: 'MultipleChoice',
    possibleAnswers: ['No', 'Yes'],
    answer: mockQuestionAnswer,
  };

  return (
    <>
      <PreQuestionComponent
        index={index}
        question={mockQuestion}
        onClick={(answer) => {
          setMockQuestionAnswer(answer);
          onSelect(
            answer !== undefined && mockQuestion.possibleAnswers[answer] === 'No' ? [] : undefined,
          );
        }}
      />
      {mockQuestionAnswer !== undefined &&
      mockQuestion.possibleAnswers[mockQuestionAnswer] === 'Yes' ? (
        <div className={css.questionContainer} key={question.key}>
          <BodyNormal style={{ paddingBottom: 16, marginTop: -16 }} className={css.question}>
            {question.preamble}
          </BodyNormal>
          <OptionsModal
            possibleAnswers={question.possibleAnswers}
            value={question.answer}
            onChange={onSelect}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const QuestionComponent = ({
  index,
  question,
  setQuestions,
}: {
  index: number;
  question: Question;
  setQuestions: (fn: SetQuestionFn) => void;
}) => {
  switch (question.questionType) {
    case 'MultipleChoice':
      return (
        <MultipleChoiceQuestionComponent
          question={question}
          onClick={(newAnswer) => {
            setQuestions((questions) => {
              const newArray = [...questions];
              newArray[index].answer = newAnswer;
              return newArray;
            });
          }}
          index={index}
        />
      );
    case 'MultipleSelect':
      return (
        <MultipleSelectQuestionComponent
          question={question}
          onSelect={(newAnswer) => {
            setQuestions((questions) => {
              const newArray = [...questions];
              newArray[index].answer = newAnswer;
              return newArray;
            });
          }}
          key={`multiple-select-question-${index}`}
          index={index}
        />
      );
  }
};

export const ScreenerPage: React.FC<{
  post: () => void;
  setQuestions: (fn: SetQuestionFn) => void;
  questions: QuestionList;
  errorMessage?: string;
  sponsorName: string;
}> = ({ post, setQuestions, questions, errorMessage, sponsorName }) => {
  const notAllQuestionsAnswered = !!questions.find((i) => i.answer === undefined);
  const isMobile = window.innerWidth <= 500;
  const textAlign = isMobile ? 'left' : 'center';
  const calAlign = isMobile ? 'flex-start' : 'center';

  return (
    <div className={css.container}>
      <BackButton isMobile={isMobile} />
      <Calendar style={{ marginBottom: 24, alignSelf: calAlign }} date={new Date()} color="ocean" />
      <HeaderLarge style={{ marginBottom: 48, width: '100%', textAlign: textAlign }}>
        COVID-19 Daily Screener
      </HeaderLarge>
      <div className={css.innerContainer}>
        <BodyNormal style={{ paddingBottom: 8, textAlign: textAlign }}>
          Please complete before arriving on-site.
        </BodyNormal>
        <BodyNormal style={{ fontWeight: 'bold', textAlign: textAlign, marginBottom: 32 }}>
          In the past 14 days...
        </BodyNormal>
        <div className={css.questionsContainer}>
          {questions.map((question, index) => {
            return (
              <QuestionComponent
                question={question}
                setQuestions={setQuestions}
                key={`screener-question-${question.questionId}`}
                index={index}
              />
            );
          })}
        </div>
        {errorMessage && (
          <div className={css.error}>
            <div style={{ marginRight: 12.67, marginTop: 4 }}>
              <Info color={AccentColors.Razz} height={14.67} width={14.67} />
            </div>
            <CaptionError data-test-id="login-error">
              There was an error with your submission, please try again or contact{' '}
              <LinkCaption
                href="mailto:support@edenhealth.com"
                target="_blank"
                rel="noreferrer noopener"
              >
                support@edenhealth.com
              </LinkCaption>
              {'. '}
              {errorMessage}
            </CaptionError>
          </div>
        )}

        <PrimaryButton
          style={{ marginTop: 12, marginBottom: 16 }}
          onClick={post}
          value="Submit Screener"
          disabled={notAllQuestionsAnswered}
        />
        <CaptionNormal style={{ paddingBottom: 16, textAlign: 'center' }}>
          By submitting the following form, I authorize Eden Health to disclose my responses above
          to {sponsorName}.
        </CaptionNormal>
      </div>
    </div>
  );
};
