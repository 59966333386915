import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import SentryBoundary from './Sentry';
import App from './scenes/App/App';

import store from './store';
import { AuthProvider } from './lib/auth';
import toastCss from 'components/design-system/Toast.module.css';

const RootNode: React.FC = () => {
  return (
    <Provider store={store}>
      <ToastContainer
        className={toastCss.container}
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        closeButton={false}
      />
      <BrowserRouter>
        <SentryBoundary>
          <AuthProvider>
            <App />
          </AuthProvider>
        </SentryBoundary>
      </BrowserRouter>
    </Provider>
  );
};

export default RootNode;
