import React, { HTMLProps, RefObject, useRef, useState } from 'react';
import cx from 'classnames';
import _ from 'lodash';
import css from './Dropdown.module.css';
import { BodyNormal, BodySmall, KickerHeader } from './Text';
import { ChevronDown } from 'components/design-system/Icons';
import { CoreColors } from 'types/colors';
import { useOutsideAlerter } from 'lib/hooks';

export const DropdownDivider: React.FC = () => <div className={css.divider} />;

export const DropdownItem: React.FC<HTMLProps<HTMLDivElement>> = ({ className, ...props }) => {
  return (
    <div className={cx(css.item, className)}>
      <BodySmall {...props} />
    </div>
  );
};

export interface DropdownModalProps {
  selectRef: RefObject<HTMLDivElement>;
  isOpen: boolean;
  kicker?: string;
  openRight?: boolean;
  openUp?: boolean;
  onClickModal?: () => void;
  onClickOutside?: () => void;
}

export const DropdownModal: React.FC<HTMLProps<HTMLDivElement> & DropdownModalProps> = ({
  selectRef,
  openRight,
  openUp,
  kicker,
  children,
  isOpen,
  onClickModal,
  onClickOutside,
}) => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  useOutsideAlerter(modalRef, onClickOutside || _.noop);
  const selectRect = selectRef.current?.getBoundingClientRect();
  const left = openRight
    ? selectRect?.left ?? 0
    : (selectRect?.right ?? 0) - (modalRef.current?.clientWidth ?? 0);
  const top =
    openUp && selectRect && modalRef.current
      ? selectRect.top - modalRef.current.clientHeight
      : 'unset';
  return (
    <div
      style={{
        visibility: isOpen ? 'visible' : 'hidden',
        left,
        top,
      }}
      className={css.modalOuter}
      onClick={onClickModal}
    >
      <div className={css.modal} ref={modalRef}>
        {kicker && <KickerHeader className={css.kicker}>{kicker}</KickerHeader>}
        {children}
      </div>
    </div>
  );
};

export interface DropdownProps {
  label: string;
  kicker?: string;
  openRight?: boolean;
  openUp?: boolean;
}

export const ButtonDropdown: React.FC<HTMLProps<HTMLDivElement> & DropdownProps> = ({
  label,
  kicker,
  openRight = false,
  openUp = false,
  children,
}) => {
  const [isOpen, setOpen] = useState(false);
  const dropdownSelectRef = useRef<HTMLDivElement | null>(null);
  return (
    <div>
      <div
        className={cx(css.dropdownButton, {
          [css.dropdownButtonActive]: isOpen,
        })}
        onClick={() => setOpen(!isOpen)}
        ref={dropdownSelectRef}
      >
        <BodyNormal className={css.dropdownButtonText} style={{ color: CoreColors.White }}>
          {label}
        </BodyNormal>
        <ChevronDown color={CoreColors.White} width={24} height={24} />
      </div>
      <DropdownModal
        selectRef={dropdownSelectRef}
        kicker={kicker}
        openRight={openRight}
        openUp={openUp}
        isOpen={isOpen}
        onClickOutside={() => setOpen(false)}
      >
        {children}
      </DropdownModal>
    </div>
  );
};

export const Dropdown: React.FC<HTMLProps<HTMLDivElement> & DropdownProps> = ({
  label,
  kicker,
  openRight = false,
  openUp = false,
  children,
}) => {
  const [isOpen, setOpen] = useState(false);
  const arrowRef = useRef<HTMLDivElement | null>(null);

  return (
    <div>
      <div className={css.dropdown} onClick={() => setOpen(!isOpen)}>
        <BodyNormal className={css.dropdownButtonText}>{label}</BodyNormal>
        <div className={cx(css.arrow, { [css.open]: isOpen })} ref={arrowRef}>
          <ChevronDown color={CoreColors.Slate85} width={24} height={24} />
        </div>
      </div>
      <DropdownModal
        selectRef={arrowRef}
        kicker={kicker}
        openRight={openRight}
        openUp={openUp}
        isOpen={isOpen}
        onClickModal={() => setOpen(false)}
        onClickOutside={() => setOpen(false)}
      >
        {children}
      </DropdownModal>
    </div>
  );
};
