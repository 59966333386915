import React, { ReactNode } from 'react';
import { CellProps } from 'react-table';
import { Zone } from 'luxon';
import {
  Orb,
  OrbContainer,
  testResultColors,
  vaccinationStatusColors,
  workStatusColors,
} from './Orb';
import css from './Orb.module.css';
import { Download } from './Icons';
import { VaccineDownloader } from './DocumentDownloader/VaccineDownloader';
import { CoreColors } from 'types/colors';
import { Manufacturer, TestingResult, TestType, WorkStatusEnum } from 'types/db/members';
import { calendarDate, formatTime } from 'lib/time';

export function TimeCell<T extends Record<string, any>>({
  cell: { value },
}: CellProps<T, string | undefined>): ReactNode {
  const displayValue: string = formatTime(value);
  return <span> {displayValue} </span>;
}

export function DateCell<T extends Record<string, any>>({
  cell: { value },
}: CellProps<T>): ReactNode {
  return <span> {calendarDate(value)} </span>;
}

export interface DateAndYearCellProps {
  date: string;
  timeZone?: string | Zone;
  className?: string;
}

export function DateAndYearCell<T extends Record<string, any>>({
  cell: { value },
}: CellProps<T, DateAndYearCellProps>): ReactNode {
  return (
    <span className={value.className}>
      {calendarDate(value.date, { displayYear: true, timeZone: value.timeZone })}
    </span>
  );
}

export function DateTimeCell<T extends Record<string, any>>({
  cell: { value },
}: CellProps<T, string | undefined>): ReactNode {
  return !!value && <span>{calendarDate(value, { displayYear: true, displayTime: true })}</span>;
}

export const StatusIndicator: React.FC<{ value: string }> = ({ value }) => {
  return (
    <div className={css.orbContainer}>
      <Orb value={value} />
      <div>{`${value[0].toUpperCase()}${value.slice(1)}`}</div>
    </div>
  );
};

export const VaccinationStatusIndicator: React.FC<{ value: string }> = ({ value }) => {
  return <OrbContainer color={vaccinationStatusColors[value]} text={value} />;
};

export function StatusCell<T extends Record<string, any>>({
  cell: { value },
}: CellProps<T, WorkStatusEnum>): ReturnType<React.FC<CellProps<T>>> {
  return <OrbContainer color={workStatusColors[value]} text={value} />;
}

const formatReturnToWorkDate = (date: string | null) => (
  <span>RTW {date ? `on ${calendarDate(date)}` : 'N/A'}</span>
);

/**
 * Displays the details related to the member's isolate status,
 * including their formatted RTW date if any.
 */
export function IsolateDetailsCell<T extends Record<string, any>>({
  cell: { value },
}: CellProps<
  T,
  { triageStatus: WorkStatusEnum; reasons: string; returnToWorkDate: string | null }
>): ReturnType<React.FC<CellProps<T>>> {
  const { triageStatus, reasons, returnToWorkDate } = value;

  return (
    <>
      {triageStatus === WorkStatusEnum.Isolate && (
        <span style={{ wordBreak: 'keep-all' }}>
          {formatReturnToWorkDate(returnToWorkDate)}
          <br />
          {reasons}
        </span>
      )}
    </>
  );
}

/**
 * Displays the reasons related to the member's isolate status.
 * This cell does _not_ include the RTW date.
 */
export function IsolateReasonsCell<T extends Record<string, any>>({
  cell: { value },
}: CellProps<T, { value: string }>): ReturnType<React.FC<CellProps<T>>> {
  return <span style={{ wordBreak: 'keep-all' }}>{value}</span>;
}

interface VaccinationStatusCellProps {
  vaccinationStatus: string;
  sharedVaccinationCard: boolean;
  memberId: string;
  documentId?: number;
}

export function VaccinationStatusCell<T extends Record<string, any>>({
  cell: { value },
}: CellProps<T, VaccinationStatusCellProps>): ReturnType<React.FC<CellProps<T>>> {
  const { vaccinationStatus, sharedVaccinationCard, memberId, documentId } = value;
  const processedStatus = vaccinationStatus.split(' + ')[0];
  return (
    <div className={css.withDownloadIcon}>
      <OrbContainer color={vaccinationStatusColors[processedStatus]} text={vaccinationStatus} />
      {sharedVaccinationCard && documentId && (
        <VaccineDownloader>
          {{
            memberId,
            documentId,
            button: <Download color={CoreColors.Slate} />,
          }}
        </VaccineDownloader>
      )}
    </div>
  );
}

const formattedTestTypes: Record<TestType, string> = {
  pcr: `PCR`,
  rapid_antigen: 'Rapid Antigen',
  rapid_molecular: 'Rapid Molecular',
  unknown: '',
};

export function TestResultCell<T extends Record<string, any>>({
  cell: { value },
}: CellProps<T, Pick<TestingResult, 'result' | 'type' | 'verified'>>): ReturnType<
  React.FC<CellProps<T>>
> {
  return value ? (
    <OrbContainer
      color={testResultColors[value.result]}
      text={`${value.result} ${formattedTestTypes[value.type]} - ${
        value.verified ? 'Verified' : 'Unverified'
      }`}
    />
  ) : null;
}

interface VaccineDownloadCellProps {
  memberId: string;
  documentId: number;
}

export function VaccineDownloadCell<T extends Record<string, any>>({
  cell: { value },
}: CellProps<T, VaccineDownloadCellProps>): ReturnType<React.FC<CellProps<T>>> {
  const { memberId, documentId } = value;

  return (
    <VaccineDownloader>
      {{
        memberId,
        documentId,
        button: <Download />,
      }}
    </VaccineDownloader>
  );
}

const manufacturers: Record<Manufacturer, string> = {
  PFR: 'Pfizer',
  MOD: 'Moderna',
  JSN: 'Johnson & Johnson',
  NVX: 'Novavax',
  UNK: 'Unknown',
};

export function ManufacturerCell<T extends Record<string, any>>({
  cell: { value },
}: CellProps<T, Manufacturer>): ReturnType<React.FC<CellProps<T>>> {
  return <span>{manufacturers[value]}</span>;
}
