import decamelizeKeysDeep from 'decamelize-keys-deep';
import { session } from 'lib/auth';
import { loadEnv } from 'lib/env';

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export default class Api {
  baseURI: any;

  constructor(uri: string) {
    this.baseURI = uri;
  }

  async get(url: string, signal?: AbortSignal | null | undefined): Promise<Response> {
    const headers = await this.requestHeaders();
    return await fetch(this.fullURL(url), { headers, signal });
  }

  async post(url: string, requestBody: Record<string, any>): Promise<Response> {
    const headers = await this.requestHeaders();
    return await fetch(this.fullURL(url), {
      method: HttpMethod.POST,
      headers,
      body: this.formatRequestBody(requestBody),
    });
  }

  async put(url: string, requestBody: Record<string, any>): Promise<Response> {
    const headers = await this.requestHeaders();
    return await fetch(this.fullURL(url), {
      method: HttpMethod.PUT,
      headers,
      body: this.formatRequestBody(requestBody),
    });
  }

  async delete(url: string): Promise<Response> {
    const headers = await this.requestHeaders();
    return await fetch(this.fullURL(url), {
      method: HttpMethod.DELETE,
      headers,
    });
  }

  formatRequestBody(body: Record<string, any>): string {
    const decamelized = decamelizeKeysDeep(body, '-');
    return JSON.stringify(decamelized);
  }

  fullURL(url: string): string {
    return `${this.baseURI}/${url}`;
  }

  async requestHeaders(): Promise<Headers> {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-Client-Bearer-Token': loadEnv('REACT_APP_GRDN_BEARER_TOKEN'),
    });
    try {
      const authToken = (await session()).getAccessToken().getJwtToken();
      headers.append('X-Sponsor-User-Cognito-Token', authToken);
    } catch (e) {
      // TODO: Split up requests for non logged in users and guests
    }
    return headers;
  }

  objectToQuery(obj: Record<string, any>): string {
    return Object.keys(obj)
      .map((k) => {
        const value = obj[k] instanceof Object ? JSON.stringify(obj[k]) : obj[k];
        return `${k}=${value}`;
      })
      .join('&');
  }
}
