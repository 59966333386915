import React from 'react';
import Tooltip from 'components/design-system/Tooltip';
import { Info } from 'components/design-system/Icons';
import { CoreColors } from 'types/colors';

function HeaderWithTooltip(text: string, tooltipText: string): ReturnType<React.FC> {
  return (
    <div style={{ display: 'flex' }}>
      <span style={{ marginRight: 4 }}>{text}</span>
      <Tooltip content={tooltipText}>
        <Info color={CoreColors.SlateDarken20} height="14" width="14" />
      </Tooltip>
    </div>
  );
}

export function IsolateReasonsHeader(): ReturnType<React.FC> {
  return HeaderWithTooltip('Isolate Details', 'Based on employee self-reported assessment.');
}

export function VaccinationStatusHeader(): ReturnType<React.FC> {
  return HeaderWithTooltip(
    'Vaccination Status',
    'Vaccination statuses are updated on a daily basis. This list was last refreshed yesterday at 11:59 pm.',
  );
}

export function LastTestResultHeader(): ReturnType<React.FC> {
  return HeaderWithTooltip(
    'Last Test Result',
    'Test results are updated on a daily basis. This list was last refreshed yesterday at 11:59 pm.',
  );
}
