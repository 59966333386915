import React, { useState } from 'react';
import cx from 'classnames';
import { DateTime } from 'luxon';
import css from './VisitorReceiptPage.module.css';
import { splitQuestion } from './utils';
import {
  BodyNormal,
  BodySmall,
  DisplayMedium,
  HeaderLarge,
  HeaderSmall,
  KickerHeader,
} from 'components/design-system/Text';
import { AlertCircle, Bookmark, Calendar, HealthCare, Mail } from 'components/design-system/Icons';
import { FormState, Question } from 'types/screener';
import { AccentColors, CoreColors } from 'types/colors';

const VaccineAnswer: React.FC<{ formState: FormState; isMobile: boolean }> = ({
  formState,
  isMobile,
}) => (
  <div
    key={'VACCINE'}
    className={isMobile ? css.questionAnswerContainerMobile : css.questionAnswerContainer}
  >
    <div>
      <BodyNormal className={css.question}>
        <span style={{ fontWeight: 600 }}>{1}. </span> {'Have you been fully vaccinated?'}
      </BodyNormal>
      {
        <BodySmall className={css.subQuestion}>
          {'Vaccines accepted by the US must be ' +
            'approved for emergency use by the World Health Organization or the US Food and ' +
            'Drug Administration. At the present time, these include Pfizer/BioNtech, ' +
            'AstraZeneca/Covishield, Janssen (Johnson & Johnson), Moderna, Sinopharm and ' +
            'Sinovac/Coronavac.'}
        </BodySmall>
      }
    </div>
    <BodyNormal className={css.answer}>{formState.isVaccinated ? 'Yes' : 'No'}</BodyNormal>
  </div>
);

const ScreenerAnswers: React.FC<{ questions: Question[]; isMobile: boolean }> = ({
  questions,
  isMobile,
}) => (
  <div className={css.page} style={{ paddingBottom: 56 }}>
    {questions.map((question, index) => {
      const [mainQuestion, subquestion] = splitQuestion(question);
      return question.questionType === 'MultipleChoice' ? (
        <div
          key={question.questionId}
          className={isMobile ? css.questionAnswerContainerMobile : css.questionAnswerContainer}
        >
          <div>
            <BodyNormal className={css.question}>
              <span style={{ fontWeight: 600 }}>{index + 2}. </span> {mainQuestion}
            </BodyNormal>
            {subquestion && <BodySmall className={css.subQuestion}>{subquestion}</BodySmall>}
          </div>
          <BodyNormal className={css.answer}>
            {question.answer !== undefined ? question.possibleAnswers[question.answer] : <></>}
          </BodyNormal>
        </div>
      ) : (
        <div key={question.questionId}>
          <div
            className={isMobile ? css.questionAnswerContainerMobile : css.questionAnswerContainer}
          >
            <div>
              <BodyNormal className={css.question}>
                <span style={{ fontWeight: 600 }}>{index + 1}. </span> {mainQuestion}
              </BodyNormal>
              {subquestion && <BodySmall className={css.subQuestion}>{subquestion}</BodySmall>}
            </div>
            <BodyNormal className={css.answer}>
              {(question.answer?.length ?? 0) > 0 ? 'Yes' : 'No'}
            </BodyNormal>
          </div>
          <div>
            <div>
              <BodySmall className={css.subQuestion}>{question.preamble}</BodySmall>
            </div>
            <div>
              <BodySmall className={css.subQuestion}>
                {(question.answer?.length ?? 0) > 0
                  ? question.answer
                      ?.map((i) => {
                        return question.possibleAnswers[i].name;
                      })
                      .join(', ')
                  : ''}
              </BodySmall>
            </div>
          </div>
        </div>
      );
    })}
  </div>
);

const IsolateInstructions: React.FC<{
  formState: FormState;
  isMobile: boolean;
  vaccineFailure: boolean | undefined;
}> = ({ formState, isMobile, vaccineFailure }) => (
  <div className={css.adviceSection}>
    <div className={css.page}>
      <div className={isMobile ? css.adviceMobile : css.advice}>
        <div className={isMobile ? css.isolateBubbleMobile : css.isolateBubble}>
          <Mail color={AccentColors.Lemon} height={22} width={22} />
        </div>
        <BodyNormal>
          <div className={css.adviceHeader}>On-site status sent to you and your host</div>
          <div>
            A copy of your on-site status was sent to {formState.email} and {formState.contactEmail}
          </div>
        </BodyNormal>
      </div>
      <div className={isMobile ? css.adviceMobile : css.advice}>
        <div className={isMobile ? css.isolateBubbleMobile : css.isolateBubble}>
          <AlertCircle color={AccentColors.Lemon} height={22} width={22} />
        </div>
        <BodyNormal>
          <div className={css.adviceHeader}>Do not arrive on-site</div>
          <div>Please coordinate with your host to reschedule your on-site visit.</div>
        </BodyNormal>
      </div>
      <div className={isMobile ? css.adviceMobile : css.advice}>
        <div className={isMobile ? css.isolateBubbleMobile : css.isolateBubble}>
          <HealthCare color={AccentColors.Lemon} height={22} width={22} />
        </div>
        {vaccineFailure ? (
          <BodyNormal>
            <div className={css.adviceHeader}>Full vaccination required</div>
            <div style={{ paddingBottom: 4 }}>
              The onsite protocols at your worksite or location require you to be fully vaccinated.
            </div>
            <div>If you have questions please reach out to the HR department of your host.</div>
          </BodyNormal>
        ) : (
          <BodyNormal>
            <div className={css.adviceHeader}>Self-isolate and monitor your symptoms</div>
            <div style={{ paddingBottom: 4 }}>
              Please stay home for 14 days and minimize your exposure to others.
            </div>
            <div>If you are experiencing an emergency, please call 911.</div>
          </BodyNormal>
        )}
      </div>
    </div>
  </div>
);

const ClearedInstructions: React.FC<{ formState: FormState; isMobile: boolean }> = ({
  formState,
  isMobile,
}) => (
  <div className={css.adviceSection}>
    <div className={css.page}>
      <div className={isMobile ? css.adviceMobile : css.advice}>
        <div className={isMobile ? css.approvedBubbleMobile : css.approvedBubble}>
          <Mail color={CoreColors.Eden} height={22} width={22} />
        </div>
        <BodyNormal>
          <div className={css.adviceHeader}>On-site status sent to you and your host</div>
          <div>
            A copy of your on-site status was sent to {formState.email} and {formState.contactEmail}
          </div>
        </BodyNormal>
      </div>
      <div className={isMobile ? css.adviceMobile : css.advice}>
        <div className={isMobile ? css.approvedBubbleMobile : css.approvedBubble}>
          <Bookmark color={CoreColors.Eden} height={22} width={22} />
        </div>
        <BodyNormal>
          <div className={css.adviceHeader}>Show on-site status upon arrival</div>
          <div>
            You may be asked to show your on-site status when you arrive. Please have your green
            “Cleared” status from your email confirmation email ready.
          </div>
        </BodyNormal>
      </div>
      <div className={isMobile ? css.adviceMobile : css.advice}>
        <div className={isMobile ? css.approvedBubbleMobile : css.approvedBubble}>
          <HealthCare color={CoreColors.Eden} height={22} width={22} />
        </div>
        <BodyNormal>
          <div className={css.adviceHeader}>Help keep the work-site safe</div>
          <div>
            Please continue social distancing and enhanced hygiene measures, including frequent hand
            washing and wearing a face covering when appropriate.
          </div>
        </BodyNormal>
      </div>
    </div>
  </div>
);

export const VisitorReceiptPage: React.FC<{
  formState: FormState;
  isolate: boolean;
  questions: Question[];
  vaccineRequired: boolean | undefined;
}> = ({ isolate, formState, questions, vaccineRequired }) => {
  const [todayDate] = useState(new Date());
  const isMobile = window.innerWidth <= 500;
  const textAlign = isMobile ? 'left' : 'center';
  const vaccineFailure = vaccineRequired && !formState.isVaccinated;
  return (
    <div className={css.pageOuter}>
      <div className={css.page}>
        <HeaderLarge className={css.header}>Your On-site Status</HeaderLarge>
        <div className={cx(isolate ? css.isolate : css.approved, css.card)}>
          <div style={{ paddingLeft: 8, paddingRight: 8 }}>
            <div className={css.cardHeader}>
              <div className={css.calendar}>
                <Calendar color={isolate ? 'lemon' : 'eden'} date={todayDate} />
              </div>
              <KickerHeader className={css.text}>On-site Status</KickerHeader>
            </div>
            <DisplayMedium className={css.text}>
              {!isolate ? 'Cleared' : 'Access Denied'}
            </DisplayMedium>
          </div>
          <div className={cx(isolate ? css.isolate : css.approved, css.innerCard)}>
            <div className={css.section}>
              <div className={css.sectionTopRow}>
                <div style={{ fontWeight: 600 }} className={css.text}>
                  {formState.firstName} {formState.lastName}
                </div>
                <div className={css.visitorTag}>Visitor</div>
              </div>
              <div className={css.text}>{formState.email}</div>
            </div>
            {formState.hostUnknown ? (
              <></>
            ) : (
              <div className={css.section}>
                <div className={css.sectionTopRow}>
                  <div style={{ fontWeight: 600 }} className={css.text}>
                    {formState.contactName}
                  </div>
                  <div className={css.hostTag}>Host</div>
                </div>
                <div className={css.text}>{formState.contactEmail}</div>
              </div>
            )}
            <div className={cx(css.screenTime, css.text)}>
              Screened {DateTime.fromJSDate(todayDate).toFormat('MMM dd')} at{' '}
              {DateTime.fromJSDate(todayDate).toFormat('h:mm a')} EST
            </div>
          </div>
          <div style={{ paddingLeft: 8, paddingRight: 8 }}>
            <BodySmall className={css.text}>
              On-site status only valid for{' '}
              {DateTime.fromJSDate(todayDate).toFormat('MMM dd, yyyy')}
            </BodySmall>
          </div>
        </div>
        <HeaderLarge className={css.header} style={{ textAlign: textAlign }}>
          What happens next?
        </HeaderLarge>
      </div>
      {isolate ? (
        <IsolateInstructions
          formState={formState}
          isMobile={isMobile}
          vaccineFailure={vaccineFailure}
        />
      ) : (
        <ClearedInstructions formState={formState} isMobile={isMobile} />
      )}
      <HeaderSmall style={{ paddingBottom: 16 }}> Your COVID-19 Screener Responses </HeaderSmall>
      <VaccineAnswer formState={formState} isMobile={isMobile} />
      {vaccineFailure ? <></> : <ScreenerAnswers questions={questions} isMobile={isMobile} />}
    </div>
  );
};
