import React from 'react';
import css from './Spinner.module.css';

export const Spinner: React.FC<{ size?: number }> = ({ size = 56 }) => (
  // The complex gradient and behavior of this spinner makes me hesitant to reuse it. If you need it,
  // and it behaves exactly the same, feel free to pull it up into icons, but when created, this behavior
  // was a bit of a one off.
  <div className={css.spinner} style={{ width: size, height: size }}>
    <svg
      width={size}
      height={size}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.59998 28C5.59998 40.3712 15.6288 50.4 28 50.4C40.3712 50.4 50.4 40.3712 50.4 28C50.4 15.6288 40.3712 5.59998 28 5.59998"
        stroke="url(#paint0_linear)"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="5.59998"
          y1="43.8666"
          x2="38.0486"
          y2="56.3112"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#14B87C" />
          <stop offset="1" stopColor="#8BDEAE" />
        </linearGradient>
      </defs>
    </svg>
  </div>
);
