import React, { HTMLProps, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Column, TableInstance, TableOptions } from 'react-table';
import css from './Flyout.module.css';
import { Tabs, Tab } from 'components/design-system/Tabs';
import { Spinner } from 'components/design-system/Spinner';
import { calendarDate } from 'lib/time';
import {
  fetchMember,
  fetchTestingResults,
  getCustomFields,
  getMemberRegistry,
  getTestingResults,
  getVaccineDetails,
  memberSelectors,
} from 'redux/member';
import { HeaderMedium, HeaderSmall, ListBodySmall } from 'components/design-system/Text';
import { Store, useAppDispatch } from 'store';
import textStyles from 'components/design-system/Text.module.css';
import { EdenTable, useEdenTable } from 'components/design-system/Table';
import { AlertCircle, Info, XIcon } from 'components/design-system/Icons';
import { AccentColors, CoreColors } from 'types/colors';
import {
  DateTimeCell,
  IsolateReasonsCell,
  TestResultCell,
  StatusCell,
  DateAndYearCell,
  VaccineDownloadCell,
  ManufacturerCell,
  DateAndYearCellProps,
} from 'components/design-system/Cells';
import { captureException } from 'lib/sentry';
import { TestingResult, VaccineDetails, WorkStatusEnum } from 'types/db/members';
import { selectRestrictedStatus } from 'redux/user';

export interface FlyoutProps {
  id: string;
  onClose: () => void;
}

interface TransformedCovidRegistry {
  dateTime: string;
  status: WorkStatusEnum;
  isolateReasons: string;
}

const testingResultsColumns: Column<TestingResult>[] = [
  {
    Header: 'Result Date',
    accessor: (testingResult): DateAndYearCellProps => ({ date: testingResult.testedAt }),
    Cell: DateAndYearCell,
  },
  {
    id: 'labResultStatus',
    Header: 'Status',
    accessor: (result: TestingResult) => result,
    Cell: TestResultCell,
  },
];

const Cell = ({ children, ...props }: HTMLProps<HTMLTableDataCellElement>) => (
  <td {...props}>
    <ListBodySmall>{children}</ListBodySmall>
  </td>
);

const TableTab = <T extends Record<string, any>>({
  instance,
  isLoaded,
  loadingText,
  isEmpty,
  emptyText,
  loadFailed = false,
  failureText,
}: {
  instance: TableInstance<T>;
  isLoaded: boolean;
  isEmpty: boolean;
  loadingText: string;
  emptyText: string;
  loadFailed?: boolean;
  failureText: string;
}) => {
  if (loadFailed) {
    return (
      <div className={css.iconParagraph}>
        <AlertCircle color={AccentColors.Lemon} width={14} height={14} />
        {failureText}
      </div>
    );
  }

  if (!isLoaded) {
    return (
      <div className={css.iconParagraph}>
        <Spinner size={14} />
        {loadingText}
      </div>
    );
  }

  if (isEmpty) {
    return (
      <div className={css.iconParagraph}>
        <Info color={CoreColors.SlateDarken20} width={14} height={14} />
        {emptyText}
      </div>
    );
  }

  return <EdenTable className={css.table} tableInstance={instance} />;
};

export const Flyout: React.FC<FlyoutProps> = ({ id, onClose }) => {
  const dispatch = useAppDispatch();

  const [memberLoadingState, setMemberLoadingState] = useState({
    isLoaded: false,
    loadFailed: false,
  });
  const [testingResultsLoadingState, setTestingResultsLoadingState] = useState({
    isLoaded: false,
    loadFailed: false,
  });

  useEffect(() => {
    const action = async () => {
      // We have to await fetching member *before* fetching testing results
      // because this retrieves screener results but also resets all testing results.
      const fetchMemberResponse = await dispatch(fetchMember({ id }));

      if (fetchMember.rejected.match(fetchMemberResponse)) {
        setMemberLoadingState({ isLoaded: false, loadFailed: true });
        throw fetchMemberResponse.payload ?? 'Unknown error when fetching member in flyout.';
      }

      if (fetchMember.fulfilled.match(fetchMemberResponse)) {
        setMemberLoadingState({ isLoaded: true, loadFailed: false });
      }

      const fetchTestingResultsResponse = await dispatch(fetchTestingResults({ id }));

      if (fetchTestingResults.rejected.match(fetchTestingResultsResponse)) {
        setTestingResultsLoadingState({ isLoaded: false, loadFailed: true });
        throw (
          fetchTestingResultsResponse.payload ??
          'Unknown error when fetching member COVID-19 testing results in flyout.'
        );
      }

      if (fetchTestingResults.fulfilled.match(fetchTestingResultsResponse)) {
        setTestingResultsLoadingState({ isLoaded: true, loadFailed: false });
      }
    };
    action().catch(captureException);
  }, [dispatch, id]);

  const member = useSelector((state: Store) => id && memberSelectors.selectById(state, id));
  const registry = useSelector((state: Store) => getMemberRegistry(state, { id }));
  const testingResultsSelector = useMemo(
    () => (state: Store) => getTestingResults(state, { id }),
    [id],
  );
  const testingResults = useSelector(testingResultsSelector);
  const getVaccineDetailsSelector = useMemo(
    () => (state: Store) => getVaccineDetails(state, { id }),
    [id],
  );
  const vaccineDetails = useSelector(getVaccineDetailsSelector);

  const hasRestrictedDashboard = useSelector(selectRestrictedStatus);
  const registryColumns = useMemo<Column<TransformedCovidRegistry>[]>(() => {
    const isolateReasonsColumn: Column<TransformedCovidRegistry>[] = hasRestrictedDashboard
      ? []
      : [
          {
            Header: 'isolate reasons',
            accessor: 'isolateReasons',
            Cell: IsolateReasonsCell,
            width: 170,
          },
        ];
    return [
      { Header: 'date and time', accessor: 'dateTime', Cell: DateTimeCell },
      { id: 'workStatus', Header: 'status', accessor: 'status', Cell: StatusCell, width: 120 },
      ...isolateReasonsColumn,
    ];
  }, [hasRestrictedDashboard]);

  const registryOptions: TableOptions<TransformedCovidRegistry> = {
    data: registry,
    columns: registryColumns,
    initialState: {
      pageIndex: 0,
      pageSize: 6,
      sortBy: [
        {
          id: 'dateTime',
          desc: true,
        },
      ],
    },
  };
  const registryTableInstance = useEdenTable<TransformedCovidRegistry>(registryOptions);

  const testingResultsOptions: TableOptions<TestingResult> = {
    data: testingResults,
    columns: testingResultsColumns,
    initialState: {
      pageIndex: 0,
      pageSize: 6,
      sortBy: [
        {
          id: 'lastModifiedDateTime',
          desc: true,
        },
      ],
    },
  };

  const testingResultsTableInstance = useEdenTable<TestingResult>(testingResultsOptions);
  const customFields = useSelector(getCustomFields);

  const vaccineDetailsColumns: Column<VaccineDetails>[] = useMemo<Column<VaccineDetails>[]>(
    () => [
      { Header: 'Dose Number', accessor: 'dose', width: 127 },
      {
        Header: 'Date Administered',
        accessor: (vaccineDetails): DateAndYearCellProps => ({
          date: vaccineDetails.vaccinatedAt,
          timeZone: 'UTC',
        }),
        Cell: DateAndYearCell,
        width: 170,
      },
      {
        Header: 'Manufacturer',
        accessor: 'manufacturer',
        Cell: ManufacturerCell,
        width: 140,
      },
      {
        id: 'download',
        Header: '',
        accessor: (vaccineDetails) => ({
          memberId: id,
          documentId: vaccineDetails.athenaDocumentId,
        }),
        Cell: VaccineDownloadCell,
        disableSortBy: true,
        width: 40,
      },
    ],
    [id],
  );

  const vaccineDetailsOptions: TableOptions<VaccineDetails> = {
    data: vaccineDetails,
    columns: vaccineDetailsColumns,
    initialState: {
      pageIndex: 0,
      pageSize: 6,
      sortBy: [
        {
          id: 'vaccinatedAt',
          desc: true,
        },
      ],
    },
  };

  const vaccineDetailsTableInstance = useEdenTable<VaccineDetails>(vaccineDetailsOptions);

  return member ? (
    <>
      <div className={css.cover} />
      <div className={css.container} onClick={onClose}>
        <div className={css.flyout} onClick={(e) => e.stopPropagation()}>
          <div style={{ marginBottom: 42 }} className={css.x} onClick={onClose}>
            <XIcon height={24} width={24} color={CoreColors.Slate85} />
          </div>
          <HeaderMedium style={{ marginBottom: 24, marginLeft: 8 }}>
            {member.firstName} {member.lastName}
          </HeaderMedium>
          <HeaderSmall style={{ marginBottom: 16, marginLeft: 8 }} className={textStyles.semibold}>
            Employee Info
          </HeaderSmall>
          <table className={css.strippedTable}>
            <tbody>
              <tr>
                <Cell>Employee ID</Cell>
                <Cell>{member.employeeId}</Cell>
              </tr>
              <tr>
                <Cell>Office Location</Cell>
                <Cell>{member.worksite}</Cell>
              </tr>
              <tr>
                <Cell>Work Email</Cell>
                <Cell>{member.email}</Cell>
              </tr>
              <tr>
                <Cell>Work Phone</Cell>
                <Cell>{member.phone}</Cell>
              </tr>
              <tr>
                <Cell>Registration Date</Cell>
                <Cell>
                  {member.patientCreatedAt
                    ? calendarDate(member.patientCreatedAt, {
                        displayTime: true,
                        displayYear: true,
                      })
                    : ''}
                </Cell>
              </tr>
              {customFields.map((k) => {
                return (
                  <tr key={k}>
                    <Cell>{k}</Cell>
                    <Cell>{(member && member.data[k]) || 'N/A'}</Cell>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <HeaderSmall style={{ marginBottom: 16, marginLeft: 8 }} className={textStyles.semibold}>
            COVID-19 Active Monitoring
          </HeaderSmall>

          <Tabs>
            <Tab label="Daily Screening">
              <TableTab
                instance={registryTableInstance}
                isLoaded={memberLoadingState.isLoaded}
                loadingText="Loading screener results..."
                isEmpty={!registry.length}
                emptyText="No COVID-19 screeners recorded yet..."
                loadFailed={memberLoadingState.loadFailed}
                failureText="Could not retrieve COVID-19 screener results."
              />
            </Tab>
            <Tab label="Testing">
              <TableTab
                instance={testingResultsTableInstance}
                isLoaded={testingResultsLoadingState.isLoaded}
                loadingText="Loading test results..."
                isEmpty={!testingResults.length}
                emptyText="No COVID-19 test results recorded yet..."
                loadFailed={testingResultsLoadingState.loadFailed}
                failureText="Could not retrieve COVID-19 test results."
              />
            </Tab>
            <Tab label="Vaccination">
              {member.sharedVaccinationCard ? (
                <TableTab
                  instance={vaccineDetailsTableInstance}
                  isLoaded
                  loadingText="Loading vaccine details..."
                  isEmpty={!vaccineDetails.length}
                  emptyText="No vaccine details recorded yet..."
                  failureText="Could not retrieve vaccine details."
                />
              ) : (
                <div className={css.iconParagraph}>
                  <Info color={CoreColors.SlateDarken20} width={14} height={14} />
                  Employee has not consented to share the date, manufacturer, or photo record of
                  vaccination. This will become available once they accept the new COVID-19 Consent
                  terms in the Eden Health app.
                </div>
              )}
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};
