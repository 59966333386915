interface IsolateReasons {
  Exposure: boolean;
  Symptoms: boolean;
  'COVID Test': boolean;
  Travel: boolean;
  Other: boolean;
}

export const transformReasons = (reasons: string): IsolateReasons => {
  const result: IsolateReasons = {
    Exposure: false,
    Symptoms: false,
    'COVID Test': false,
    Travel: false,
    Other: false,
  };
  reasons.split(', ').forEach((reason) => {
    switch (reason) {
      case 'Exposure':
        result.Exposure = true;
        break;
      case 'Symptoms':
        result.Symptoms = true;
        break;
      case 'COVID test':
        result['COVID Test'] = true;
        break;
      case 'Travel':
        result.Travel = true;
        break;
      case '':
        break;
      default:
        result['Other'] = true;
    }
  });
  return result;
};
