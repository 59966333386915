import React, { Dispatch, HTMLProps, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { CellProps, Column, IdType, Row } from 'react-table';
import _ from 'lodash';
import css from './MemberList.module.css';
import { MembersCsvLink } from './MembersCsvLink';
import { UnconsentedModal } from './UnconsentedModal';
import { CsvSettings, DashboardState } from './MemberDashboard';
import { useAppDispatch } from 'store';
import { fetchMembers, getCustomFields, memberSelectors } from 'redux/member';
import { EdenTable, Filter, NameFilter, useEdenTable } from 'components/design-system/Table';
import { unconsentedSelectors } from 'redux/employee';
import { Member, VaccinationStatusEnum, WorkStatusEnum } from 'types/db/members';
import { BodySmall, HeaderMedium, HeaderSmall } from 'components/design-system/Text';
import textStyles from 'components/design-system/Text.module.css';
import { EdenDayPicker, Option } from 'components/design-system/Form';
import {
  DateAndYearCell,
  DateAndYearCellProps,
  IsolateDetailsCell,
  StatusCell,
  StatusIndicator,
  TestResultCell,
  TimeCell,
  VaccinationStatusCell,
  VaccinationStatusIndicator,
} from 'components/design-system/Cells';
import { Calendar } from 'components/design-system/Icons';
import { calendarDate, isDateToday, isoDate } from 'lib/time';
import { Spinner } from 'components/design-system/Spinner';
import { captureException } from 'lib/sentry';
import {
  IsolateReasonsHeader,
  LastTestResultHeader,
  VaccinationStatusHeader,
} from 'components/design-system/Headers';
import { selectRestrictedStatus, selectRestrictedVaxStatus } from 'redux/user';
import { dateAndYearSorter } from 'lib/sort';

export const ViewDetailsCell: (onClick: (id: string) => void) => React.FC<CellProps<Member>> = (
  onClick: (id: string) => void,
): React.FC<CellProps<Member>> =>
  function ViewDetailsCell({ cell: { value } }) {
    return (
      <div className={css.viewDetails} onClick={() => onClick(value)}>
        View Details
      </div>
    );
  };

interface MemberListProps {
  selectedDate: Date;
  csvSettings: CsvSettings;
  loading: boolean;
  worksiteFilter: string;
  showUnconsented: boolean;
  setState: Dispatch<SetStateAction<DashboardState>>;
}

const generateColumns = (
  customFields: (keyof Member['data'])[] | undefined,
  currentColumns: Column<Member>[],
): Column<Member>[] => {
  if (!customFields) return currentColumns;
  const columns = currentColumns.slice(0, currentColumns.length - 1);
  const lastCol = currentColumns[columns.length];
  const existingHeaders = currentColumns.map((val) => {
    return val.Header;
  });
  const modifiedCols = customFields.reduce((cols, k) => {
    return !existingHeaders.includes(k)
      ? [
          ...cols,
          {
            Header: k,
            accessor: (i: Member) => i.data[k],
            minWidth: 100,
            width: 200,
            maxWidth: 400,
          },
        ]
      : cols;
  }, columns);
  return [...modifiedCols, lastCol];
};

export const MemberList: React.FC<MemberListProps> = ({
  selectedDate,
  csvSettings,
  loading,
  worksiteFilter,
  showUnconsented,
  setState,
}) => {
  const data = useSelector(memberSelectors.selectAll);
  const unconsented = useSelector(unconsentedSelectors.selectAll);
  const customFields = useSelector(getCustomFields);
  const onClick = useMemo(
    () => (id: string) => setState((state) => ({ ...state, selectedMemberId: id })),
    [setState],
  );

  const hasRestrictedDashboard = useSelector(selectRestrictedStatus);
  const hasRestrictedVaxStatus = useSelector(selectRestrictedVaxStatus);

  // a custom filter is required for vaccination status because we pass an object into it as the value,
  // but we only want to filter against the string under the `vaccinationStatus` key
  const vaccinationStatusFilter = (
    rows: Row<Member>[],
    _columnIds: string[],
    filterValue: string,
  ) => {
    return rows.filter((row: Row<Member>) => {
      return String(row.values['vaccinationStatus']['vaccinationStatus'])
        .toLowerCase()
        .includes(String(filterValue).toLowerCase());
    });
  };

  const dateOfLastDoseAccessor = (member: Member): DateAndYearCellProps => {
    const newestVaccinatedAt =
      member.sharedVaccinationCard && member.newestVaccinatedAt ? member.newestVaccinatedAt : '';

    return {
      date: newestVaccinatedAt,
      timeZone: 'UTC',
      className: css.dateOfLastDose,
    };
  };

  const dateOfLastDoseSorter = (row1: Row<Member>, row2: Row<Member>): number => {
    const newestVaccinatedAt1 = row1.original.newestVaccinatedAt || '';
    const newestVaccinatedAt2 = row2.original.newestVaccinatedAt || '';

    return dateAndYearSorter(newestVaccinatedAt1, newestVaccinatedAt2);
  };

  const testDateSorter = (row1: Row<Member>, row2: Row<Member>): number => {
    const testDate1 = row1.original.lastTest?.dateTime || '';
    const testDate2 = row2.original.lastTest?.dateTime || '';

    return dateAndYearSorter(testDate1, testDate2);
  };

  const defaultColumns = useMemo<Column<Member>[]>(() => {
    const isolateReasonsColumn: Column<Member>[] = hasRestrictedDashboard
      ? []
      : [
          {
            id: 'isolateReasons',
            Header: IsolateReasonsHeader,
            accessor: (member) => ({
              triageStatus: member.triageStatus,
              reasons: member.isolateReasons,
              returnToWorkDate: member.returnToWorkDate,
            }),
            Cell: IsolateDetailsCell,
            minWidth: 160,
            width: 160,
            maxWidth: 1000,
          },
          {
            id: 'lastScreenedAt',
            Header: 'Screener Taken (ET)',
            accessor: (member) =>
              member.triageStatus !== WorkStatusEnum.Incomplete ? member.lastScreenedAt : undefined,
            Cell: TimeCell,
            minWidth: 140,
            width: 140,
            maxWidth: 1000,
          },
        ];
    const vaxStatusColumns: Column<Member>[] = hasRestrictedVaxStatus
      ? []
      : [
          {
            id: 'vaccinationStatus',
            Header: VaccinationStatusHeader,
            accessor: (member) => ({
              vaccinationStatus: member.vaccinationStatus,
              sharedVaccinationCard: member.sharedVaccinationCard,
              memberId: member.id,
              documentId: member.athenaDocumentId,
            }),
            Cell: VaccinationStatusCell,
            filter: vaccinationStatusFilter,
            minWidth: 210,
            width: 210,
            maxWidth: 210,
          },
          {
            id: 'dateOfLastDose',
            Header: 'date of last dose',
            accessor: dateOfLastDoseAccessor,
            Cell: DateAndYearCell,
            minWidth: 150,
            width: 150,
            maxWidth: 170,
            sortType: dateOfLastDoseSorter,
          },
        ];
    const testingColumns: Column<Member>[] = [
      {
        id: 'lastTest',
        Header: LastTestResultHeader,
        accessor: 'lastTest',
        Cell: TestResultCell,
        minWidth: 170,
        width: 170,
        maxWidth: 170,
      },
      {
        id: 'testTime',
        Header: 'test date',
        accessor: (member) => ({
          date: member.lastTest?.dateTime,
          timeZone: 'UTC',
        }),
        Cell: DateAndYearCell,
        minWidth: 140,
        width: 140,
        maxWidth: 140,
        sortType: testDateSorter,
      },
    ];
    return [
      {
        Header: 'f name',
        accessor: 'firstName',
        minWidth: 90,
        width: 90,
        maxWidth: 1000,
      },
      { Header: 'l name', accessor: 'lastName', minWidth: 90, width: 90, maxWidth: 1000 },
      ...vaxStatusColumns,
      {
        id: 'workStatus',
        Header: 'Work Status',
        accessor: 'triageStatus',
        Cell: StatusCell,
        minWidth: 140,
        width: 140,
        maxWidth: 1000,
      },
      ...isolateReasonsColumn,
      ...testingColumns,
      {
        Header: 'office location',
        accessor: 'worksite',
        minWidth: 150,
        width: 150,
        maxWidth: 1000,
      },
    ];
  }, [hasRestrictedDashboard, hasRestrictedVaxStatus]);

  const [columns, setColumns] = useState(defaultColumns);
  useEffect(() => {
    setColumns(() => generateColumns(customFields, defaultColumns));
  }, [customFields, defaultColumns]);

  const nameGlobalFilter = useMemo(
    () => (rows: Row<Member>[], _ids: IdType<Member>[], query: string) => {
      const lowerCaseQuery = query.trim().toLowerCase();
      return rows.filter(
        (row) =>
          row.values['firstName'].toLowerCase().startsWith(lowerCaseQuery) ||
          row.values['lastName'].toLowerCase().startsWith(lowerCaseQuery),
      );
    },
    [],
  );

  const options = useMemo(
    () => ({
      data,
      columns,
      defaultColumn: {},
      initialState: {
        manualFilters: {
          triageStatus: '',
          worksite: '',
        },
        pageIndex: 0,
        pageSize: 25,
        sortBy: [{ id: 'lastTriageAt', desc: true }],
      },
      globalFilter: nameGlobalFilter,
      // Do not reset filter when data changes.
      autoResetGlobalFilter: false,
    }),
    [data, columns, nameGlobalFilter],
  );
  const tableInstance = useEdenTable<Member>(options);

  const dispatch = useAppDispatch();
  const handleDayChange = (day: Date | undefined) => {
    const action = async () => {
      const newDay: Date = day ?? new Date();
      const formattedDay: string = isoDate(newDay);
      setState((state) => {
        return {
          ...state,
          selectedDate: newDay,
        };
      });
      const fetchMembersResponse = await dispatch(fetchMembers({ day: formattedDay }));
      if (fetchMembers.rejected.match(fetchMembersResponse))
        throw fetchMembersResponse.payload ?? 'Unknown fetchMembers error';
      setState((state) => {
        return {
          ...state,
          csvSettings: { ...csvSettings, day: formattedDay },
        };
      });
    };
    action().catch(captureException);
  };

  const openUnconsentedModal = () => {
    setState((state) => {
      return { ...state, showUnconsented: true };
    });
  };

  const closeUnconsentedModal = () => {
    setState((state) => {
      return { ...state, showUnconsented: false };
    });
  };

  const TriageFilter = ({ ...props }: HTMLProps<HTMLDivElement>) => (
    <Filter
      {...props}
      tableInstance={tableInstance}
      filterName="workStatus"
      value={csvSettings.status}
      values={[
        <Option key="" value={undefined}>
          Any Work Status
        </Option>,
        <Option value={WorkStatusEnum.Isolate} key={WorkStatusEnum.Isolate}>
          <StatusIndicator value={WorkStatusEnum.Isolate} />
        </Option>,
        <Option value={WorkStatusEnum.Cleared} key={WorkStatusEnum.Cleared}>
          <StatusIndicator value={WorkStatusEnum.Cleared} />
        </Option>,
        <Option key={WorkStatusEnum.Incomplete} value={WorkStatusEnum.Incomplete}>
          <StatusIndicator value={WorkStatusEnum.Incomplete} />
        </Option>,
      ]}
      styles={{ modalOuter: css.modalOuter }}
      change={(value: string) => {
        setState((state) => {
          return {
            ...state,
            triageFilterStatus: value as WorkStatusEnum,
            csvSettings: { ...csvSettings, status: value as WorkStatusEnum },
          };
        });
      }}
    />
  );

  const VaccinationStatusFilter = ({ ...props }: HTMLProps<HTMLDivElement>) => (
    <Filter
      {...props}
      tableInstance={tableInstance}
      filterName="vaccinationStatus"
      value={csvSettings.vaccinationStatus}
      values={[
        <Option key="" value={undefined}>
          Any Vaccination Status
        </Option>,
        <Option
          value={VaccinationStatusEnum.NotVaccinated}
          key={VaccinationStatusEnum.NotVaccinated}
        >
          <VaccinationStatusIndicator value={VaccinationStatusEnum.NotVaccinated} />
        </Option>,
        <Option
          value={VaccinationStatusEnum.FirstDoseComplete}
          key={VaccinationStatusEnum.FirstDoseComplete}
        >
          <VaccinationStatusIndicator value={VaccinationStatusEnum.FirstDoseComplete} />
        </Option>,
        <Option
          key={VaccinationStatusEnum.SecondDoseComplete}
          value={VaccinationStatusEnum.SecondDoseComplete}
        >
          <VaccinationStatusIndicator value={VaccinationStatusEnum.SecondDoseComplete} />
        </Option>,
        <Option key={VaccinationStatusEnum.PrimarySeriesComplete} value="Primary Series">
          <VaccinationStatusIndicator value={VaccinationStatusEnum.PrimarySeriesComplete} />
        </Option>,
      ]}
      styles={{ modalOuter: css.modalOuter }}
      change={(value: string) => {
        setState((state) => {
          return {
            ...state,
            vaccinationFilterStatus: value,
            csvSettings: { ...csvSettings, vaccinationStatus: value },
          };
        });
      }}
    />
  );

  const WorksiteFilter = ({ ...props }: HTMLProps<HTMLDivElement>) => (
    <Filter
      {...props}
      value={worksiteFilter}
      tableInstance={tableInstance}
      filterName="worksite"
      values={[
        <Option key="" value="">
          Any Office Location
        </Option>,
        ..._.uniq(data.map((member) => member.worksite))
          .sort()
          .map((worksite) => (
            <Option key={worksite} value={worksite}>
              {worksite}
            </Option>
          )),
      ]}
      styles={{ modalOuter: css.modalOuter }}
      change={(value: string) => {
        setState((state) => {
          return {
            ...state,
            worksiteFilter: value,
            csvSettings: { ...csvSettings, worksite: value },
          };
        });
      }}
    />
  );

  return loading ? (
    <div className={css.loader} data-test-id="member-list-loading">
      <HeaderMedium style={{ paddingBottom: 32 }}>Loading Dashboard</HeaderMedium>
      <Spinner />
    </div>
  ) : (
    <div className={css.container}>
      <div
        className={css.header}
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <HeaderSmall className={textStyles.semibold} style={{ flex: 1 }}>
          Employees
        </HeaderSmall>
        <BodySmall>
          <strong>{data.length}</strong> of <strong>{data.length + unconsented.length}</strong>{' '}
          employees consented
        </BodySmall>
        <BodySmall
          onClick={openUnconsentedModal}
          className={css.unconsentedButton}
          style={{ marginLeft: 16 }}
        >
          Unconsented: <strong>{unconsented.length}</strong>
        </BodySmall>
      </div>
      <div className={css.subheader}>
        <div className={css.row}>
          <div className={css.row}>
            <Calendar date={selectedDate} style={{ marginRight: 24 }} />
            <div
              className={css.row}
              style={{ marginRight: 12 }}
              data-test-id="active-monitoring-header"
            >
              <HeaderMedium>Active Monitoring</HeaderMedium>
            </div>
          </div>
          <MembersCsvLink
            worksite={csvSettings.worksite}
            status={csvSettings.status}
            vaccinationStatus={csvSettings.vaccinationStatus}
            day={csvSettings.day}
          />
        </div>
        <div className={css.pickers}>
          <div className={css.nameSearchField}>
            <NameFilter tableInstance={tableInstance} />
          </div>
          <EdenDayPicker
            style={{
              width: isDateToday(selectedDate) ? 142 : 90,
            }}
            hideOnDayClick={false}
            onDayChange={handleDayChange}
            formatDate={(date) => {
              const formatted = calendarDate(date.toISOString());
              return isDateToday(date) ? `Today • ${formatted}` : formatted;
            }}
            value={selectedDate}
          />
          <WorksiteFilter style={{ width: 170 }} />
          <TriageFilter style={{ width: 152 }} />
          {!hasRestrictedVaxStatus && <VaccinationStatusFilter style={{ width: 250 }} />}
        </div>
      </div>
      <div className={css.tableWrapper}>
        <EdenTable<Member>
          tableInstance={tableInstance}
          className={css.table}
          paginate={{
            pageSizes: [25, 50, 100],
          }}
          dataName="employees"
          scrollable
          onRowClick={(row) => onClick(row.original.id)}
        />
      </div>
      <UnconsentedModal
        isOpen={showUnconsented}
        closeModal={closeUnconsentedModal}
        data={unconsented}
      />
    </div>
  );
};
