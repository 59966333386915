import { Employee, Member, TestingResult, WorkStatusEnum } from './db/members';
import { User } from './db/user';
import { Visitor } from './db/visitors';
import { CovidScreener } from './screener';

interface GRDNResponse {
  status: number;
}

export interface SuccessResponse<T> extends GRDNResponse {
  data: T;
}

export interface ErrorResponse extends GRDNResponse {
  error: string | Record<string, any>;
}

export type ResponseEnvelope<T> = SuccessResponse<T> | ErrorResponse;

export type MembersPayload = Omit<Member, 'testingResults'>[];

export type MemberPayload = Omit<Member, 'testingResults'>;

export type VisitorsPayload = Visitor[];

export type EmployeesPayload = Employee[];

export type UserPayload = User;

export type TestingResultsPayload = TestingResult[];

export interface VisitorsScreenerPayload {
  screener: CovidScreener;
  screenerId: string;
  sponsorName: string;
  vaccineRequired: boolean;
}

export interface VisitorsScreenerResponsePayload {
  covidStatus: WorkStatusEnum;
}

export interface ValidateEmailResult {
  email: string;
  valid: boolean;
}

export type ValidateEmailsPayload = ValidateEmailResult[];

export const isSuccessResponse = <T>(
  payload: ResponseEnvelope<any>,
): payload is SuccessResponse<T> => {
  return 'data' in payload && payload.status == 200;
};

export const isErrorResponse = (payload: ResponseEnvelope<any>): payload is ErrorResponse => {
  // forgive me padre, for I have sinned
  return payload.status >= 400 || 'error' in payload;
};

export type DocumentType = 'vaccine';

export interface DocumentResponsePayload {
  contentType: string;
  payload: string;
}
