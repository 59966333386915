import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Router from 'react-router-dom';
import cx from 'classnames';
import { DateTime } from 'luxon';
import { NewPassword, NewPasswordReason } from '../ResetPassword/NewPassword';
import { Challenge } from '../Challenge/Challenge';
import { captureException } from '../../../lib/sentry';
import css from './Sidebar.module.css';
import { Briefcase, EdenLogo, RotateCW, Users } from 'components/design-system/Icons';
import { CaptionNormal, HeaderSmall, Link, Title } from 'components/design-system/Text';
import { CoreColors } from 'types/colors';
import { Store } from 'store';
import { userSelectors } from 'redux/user';
import { Dropdown, DropdownDivider, DropdownItem } from 'components/design-system/Dropdown';
import { AuthContext, MFA_KIND } from 'lib/auth';
import * as toast from 'components/design-system/Toast';
import { DashboardModal } from 'components/features/MemberDashboard/DashboardModal';
import { useAppDispatch } from 'store';

export interface SidebarProps {
  loading: boolean;
  reload: () => void;
  selectedDateLoadedAt: Date | undefined;
}

const Buttons = () => {
  const location = Router.useLocation();
  return (
    <>
      <Router.Link
        className={cx(css.label, location.pathname === '/employees' && css.chosen)}
        to="/employees"
      >
        <Users width={16} height={16} />
        <Title className={css.title} style={{ paddingLeft: 16, lineHeight: '16px' }}>
          Employees
        </Title>
      </Router.Link>
      <Router.Link
        className={cx(css.label, location.pathname === '/visitors' && css.chosen)}
        to="/visitors"
      >
        <Briefcase width={16} height={16} />
        <Title className={css.title} style={{ paddingLeft: 16, lineHeight: '16px' }}>
          Visitors
        </Title>
      </Router.Link>
    </>
  );
};

export const Sidebar: React.FC<SidebarProps> = ({ loading, reload, selectedDateLoadedAt }) => {
  const userId = useSelector((state: Store) => userSelectors.selectIds(state))[0];
  const user = useSelector((state: Store) => userId && userSelectors.selectById(state, userId));
  const { signOut, startMFASetup, cancelMFASetup, preferredMFA } = useContext(AuthContext);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showSetupMFA, setShowSetupMFA] = useState(false);
  const [setupMFAEnabled, setSetupMFAEnabled] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    preferredMFA()
      .then((mfaType) => {
        setSetupMFAEnabled(mfaType === MFA_KIND.NOMFA);
        return;
      })
      .catch(captureException);
  }, [preferredMFA, setSetupMFAEnabled]);

  const closeChangePassword = (passwordChanged: boolean) => {
    if (passwordChanged) {
      toast.success('Password updated successfully');
    }
    setShowResetPassword(false);
  };
  const closeSetupMFA = (mfaChanged: boolean) => {
    if (mfaChanged) {
      toast.success('2-factor authentication updated successfully');
    } else cancelMFASetup();
    setShowSetupMFA(false);
  };
  return (
    <>
      <div className={css.spacer} />
      <div className={css.container} data-test-id="rfe-sidebar">
        <div style={{ paddingBottom: 24 }}>
          <EdenLogo />
        </div>
        {user && !loading && (
          <>
            <HeaderSmall style={{ paddingLeft: 16, paddingBottom: 8, paddingRight: 32 }}>
              {user?.sponsorName}
            </HeaderSmall>
            <div style={{ paddingLeft: 16, paddingBottom: 58, paddingRight: 32 }}>
              <Dropdown
                label={`${user?.firstName} ${user?.lastName}`}
                kicker="Account Settings"
                openRight
              >
                <DropdownItem
                  onClick={() => {
                    setShowResetPassword(true);
                  }}
                >
                  Change Password
                </DropdownItem>
                {setupMFAEnabled && (
                  <DropdownItem
                    onClick={() => {
                      startMFASetup();
                      setShowSetupMFA(true);
                    }}
                  >
                    2-Factor Authentication
                  </DropdownItem>
                )}
                <DropdownDivider />
                <DropdownItem onClick={async () => await signOut(dispatch)}>Sign Out</DropdownItem>
              </Dropdown>
            </div>
            <Buttons />
            <div style={{ paddingLeft: 16, paddingRight: 32, paddingTop: 32 }}>
              {selectedDateLoadedAt && (
                <CaptionNormal style={{ paddingBottom: 8, fontSize: 12 }}>
                  Last updated at {DateTime.fromJSDate(selectedDateLoadedAt).toFormat('h:mm a')}
                </CaptionNormal>
              )}
              <div className={css.refreshButton} onClick={reload}>
                <div style={{ paddingRight: 5 }}>Refresh</div>
                <RotateCW height={10} width={10} color={CoreColors.Slate85} />
              </div>
            </div>

            <div
              style={{
                position: 'absolute',
                bottom: 0,
                paddingBottom: 36,
                paddingLeft: 16,
                paddingRight: 16,
              }}
            >
              <Link href={'mailto: support@edenhealth.com'} style={{ fontSize: 12 }}>
                Contact Support
              </Link>
              <CaptionNormal style={{ marginTop: 16 }}>
                © 2020 Eden Health, Inc. All rights reserved.
              </CaptionNormal>
            </div>
          </>
        )}
      </div>
      <DashboardModal isOpen={showResetPassword} onClose={() => closeChangePassword(false)}>
        <NewPassword
          reason={NewPasswordReason.ResetExisting}
          passwordChanged={() => closeChangePassword(true)}
        />
      </DashboardModal>
      <DashboardModal isOpen={showSetupMFA} onClose={() => closeSetupMFA(false)}>
        <Challenge mfaSetupSuccess={() => closeSetupMFA(true)} />
      </DashboardModal>
    </>
  );
};
